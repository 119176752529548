.TimeTrackingGenericWarningModal {
	display: flex;
	padding: 4px 1px 0;
	width: 480px;

	&__alertIcon {
		fill: #f1a200;
		flex-shrink: 0;
		margin: 4px 11px 0 0;
	}

	&__header {
		color: #5;
		font-size: 24px;
		line-height 29px;
		margin-bottom: 15px;
	}

	&__header--emphasized {
		color: #2;
	}

	&__description {
		color: #5;
		font-size: 15px;
		max-width: 420px;
	}
}
