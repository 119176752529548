.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1C1C1C;
  z-index: 1000;
  opacity: 0.8;

	&__text {
	  font-family: BhrHeaderFontStack;
	  font-size: 28px;
	  font-weight: normal;
	  line-height: 28px;
	  margin-bottom: 6px;
	  margin-top: 17px;
	  white-space: nowrap;
	  color: white;
	}
	&__content {
	  margin-top: 140px;
	}

}

{featureToggleOn("jade")} {
	.Overlay {

		&__text {
			font-family: unset;
		}
	}
}
