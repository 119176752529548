.CookieModal {
	align-items: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 0 50px 48px;
	width: 800px;

	// .CookieModal__icon
	&__icon {
		margin-bottom: 11px;
	}
	// .CookieModal__message
	&__message {
		fab-color: gray9;
		fab-text: medium;
		padding: 3px 0 38px;
		text-align: center;
		width: 576px;
	}
}
