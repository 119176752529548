#currencyPickerWidget {
	max-height: 175px;
	width: 225px;
	display: none;
	position: absolute;
	border: 1px solid #d6;
	background-color: white;
	overflow-y: auto;
	padding: 3px;
	z-index: 100;
	margin: 1px 0 0 0;

	&,
	/.currencyPicker {
		font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
		font-size: 12px;
	}

	li {
		text-align: left;
		padding: 5px;
		line-height: 1;

		&:hover {
			cursor: pointer;
		}

		&.divider {
			padding: 0 5px;
			margin: 0;

			hr {
				padding: 0;
				margin: 0;
			}
		}

		.code {
			width: 22px;
			display: inline-block;
		}
	}
}

.currencyPicker {
	a& {
		padding: 11px 0 0 7px;
		display: block;
		float: right;
		color: #777;
	}
}
