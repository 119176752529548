/**
 * App header styles:
 *
 * logo bar
 * navbar
 * branded bar
 *
 */

/** headerSite styles **/
#headerSite {
	display: block;
	background: #ebebeb;
	padding: 16px 0 17px;

	h1 {
		float: left;
		font-size: 35px;
		line-height: 1em; /* override of global_styles: header h1 */

		&.logoImg {
			line-height: 0;
		}

		a {
			display: inline-block;
		}
	}
}

/** navMain styles **/
#navMain {
	display: block;
	position: relative;
	padding: 0;
	width: 100%;
	box-sizing: border-box;
	background: #3a;

	&.navMainFixed {
		position: fixed;
		top: 0;
		z-index: 2;
		width: 100%;
	}

	.page {
		margin: 0 auto;
		max-width: 1140px;
		width: 95%;
		padding: 0;
	}

	/** global search **/
	#search-box {
		float: right;
		box-sizing: border-box;
		width: 20%;
		height: 26px;
		border-radius: 0; /* override only */
		padding: 0; /* override only */
		margin: 7px 0;
		background: none; /* override only */

		#search-inside {
			/* all override of global_styles: #search-inside */
			padding: 0;
			position: static;
			width: auto;
		}

		#quicksearchquery {
			margin: 0;
			width: 100%;
			border-radius: 2px;
			height: 28px;
			padding: 2px 5px 3px 26px;
			color: #99;
			font-size: 15px;
			border: solid #787878 1px;
			box-shadow: inset 0 1px 2px 0 rgba(0,0,0,.3);;
			background: #44;
			transition: all 0.10s;

			&.dark {
				color: #22;
			}

			&:focus {
				color: #22;
				background: #ff;
				border: solid 1px;
				box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, .2);
			}
			&:not(:focus) {
				+ button #search-icon {
					fill: #b9b9b9;
					transition: all 0.10s;
				}
			}
		}

		button.search-icon-wrapper {
			background: transparent;
			border: none;
			cursor: pointer;
			text-indent: 0; /* override of global_styles & styles: #search-box button.search-icon */
			height: 16px;
			width: 16px;
			padding: 0;
			position: absolute;
			left: 6px;
			top: 5px;
			right: auto; /* override of global_styles: #search-box button.search-icon */

			&::-moz-focus-inner {
				padding: 0;
				border: 0;
			}
		}
	}
}


/** nav tab styles **/
#nav-tabs {
	float: left;
	padding: 0;

	& > li {
		float: left;
		margin: 0;

		&  > a {
			border-radius: 0; /* override only */
			float: none; /* override only */
			display: block;
			padding: 0 15px;
			height: 42px;
			line-height: 44px;
			text-decoration: none;
			font-family: BhrHeaderFontStack;
			font-weight: 400;
			font-size: 15px;
			background: none;
			color: #d7;

			&:hover,
			&.hover {
				color: #ff;
				background: #55;
			}
		}

		&.onTab > a {
			position: relative;
			background: none;
			font-weight: 600;
			color: #ff;
		}
	}
}

.branded-icon {
	div& {
		#navMain > &,
		#headerSite + & {
			height: 4px;
		}
	}
}
