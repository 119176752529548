.globalResults {
	position: absolute;
	top: 27px;
	right: 0;
	width: 310px;
	border: solid #64 3px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
	background: #fff;
	z-index: 101;
	border-radius: 3px;

	.gEmpty {
		font-size: 14px;
		text-align: left;
		padding: 8px 0 8px 11px;
		color: #777;
	}

	.gLoader {
		display: block;
		padding: 5px 0 5px;
		margin: 0 auto;
	}

	.gResults {
		$sel = selector();

		position: relative;
		text-align: right;
		font-size: 13px;
		font-weight: 600;
		line-height: 1em;
		color: #777;
		background: #eb;

		&:only-of-type {
			> span {
				&:first-child {
					top: 9px;
				}
			}
		}

		&:after {
			content: "";
			display: block;
			clear: both;
		}

		&:first-child {
			> ul {
				padding-top: 2px;
				border-top: none;
			}
		}

		&:last-child {
			> ul {
				padding-bottom: 2px;
			}
		}

		> span:first-child {
			position: relative;
			top: 11px;
		}

		ul {
			> li {
				position: relative;
				padding: 8px 5px 8px 34px;
				height: 14px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				line-height: 1em;
				color: #000;
				font-weight: normal;
				font-size: 14px;
				background: #fff;
				cursor: pointer;

				&.inactive {
					color: #999;

					img {
						opacity: 0.4;
					}
				}

				&.selected {
					font-weight: 600;
					color: #fff;

					span {
						&.branded-icon {
							background-position: 0 -15px;
						}
					}

					> span {
						color: #eb;
						font-weight: normal;
					}
				}

				span {
					color: #888;
					font-size: 12px;

					&.branded-icon {
						display: inline-block;
						height: 15px;
						width: 12px;

						&,
						/{$sel} img {
							position: absolute;
							top: 3px;
							left: 5px;
						}
					}
				}
			}
		}

		> ul {
			float: right;
			width: 206px;
			margin-left: 7px;
			text-align: left;
			background: #fff;
			border-top: solid #ebebeb 1px;
			padding: 4px 0 4px;
		}
		// New somewhat generic classes for results
		ul > li {
			// .globalResults ul > li .gResultInfo
			.gResultInfo {
				color: #7;
				flex: 1;
				font-size: 13px;
				line-height: 17px;
				position: relative;
				top: -2px;
			}
			// .globalResults ul > li .gResultTitle
			.gResultTitle {
				color: #2;
				font-size: 14px;
				line-height: 18px;
			}
			// .globalResults ul > li.selected .gResultInfo
			&.selected .gResultInfo {
				color: #f;
				font-weight: normal;
			}
			// .globalResults ul > li.selected .gResultTitle
			&.selected .gResultTitle {
				color: #f;
				font-weight: 600;
			}
		}
	}

	.gDeletedToggle {
		color: #686868;
		display: flex;
		height: 44px;
		align-items: center;
		justify-content: end;
		background-color: #F4F4F4;
		padding: 0 20px;
		font-size: 13px;
		line-height: 17px;
	}

	// Used for show deleted companies toggle
	.fab-roundedToggle {
		box-sizing: border-box;
		cursor: pointer;
		display: inline-flex;
		position: relative;
		margin-left: 12px;
	}

	.fab-roundedToggle,
	.fab-roundedToggle:before,
	.fab-roundedToggle:after {
		box-sizing: border-box;
		cursor: pointer;
	}
	.fab-roundedToggle__label {
		background-color: #adadad;
		border: none;
		border-radius: 25px;
		height: 18px;
		width: 32px;
	}
	.fab-roundedToggle__label:before {
		background-color: white;
		border-radius: 25px;
		content: '';
		height: 16px;
		left: 1px;
		opacity: 1;
		position: absolute;
		top: 1px;
		transition: left 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
		width: 15px;
	}

	.fab-roundedToggle input {
		height: 1px;
		opacity: 0;
		position: absolute;
		width: 1px;
	}
	.fab-roundedToggle input:checked + .fab-roundedToggle__label {
		background-color: var(--fabric-theme-base, #527a00);
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
	}
	.fab-roundedToggle input:checked + .fab-roundedToggle__label:before {
		left: 16px;
	}

	.gReports,
	.gFiles,
	.gGreenhouse {
		ul {
			> li {
				padding: 5px 5px 5px 10px;
			}
		}
	}

	// Job Applicant specific style
	.gJobApplicants {
		// .gJobApplicants .gApplicantListItem
		.gApplicantListItem {
			display: flex;
			height: auto;
			padding: 6px 5px;
		}
		// .gJobApplicants .gResultInfo
		.gResultInfo {
			max-width: 175px;
		}
		// .gJobApplicants .gApplicantAvatar
		.gApplicantAvatar {
			height: 15px;
			margin: 0 6px 0 0;
			width: 15px;
		}
		.gApplicantListItem.selected {
			// .gJobApplicants .gApplicantListItem.selected .gApplicantAvatar .gApplicantAvatarImage
			.gApplicantAvatar .gApplicantAvatarImage {
				fill: #f;
			}
		}
	}
	// Job Opening specific style
	.gJobOpenings {
		// .gJobOpenings .gOpeningsList .gOpeningsListItem
		.gOpeningsList .gOpeningsListItem {
			height: auto;
			padding: 6px 5px;
		}
	}
}

.gSectionName {
	cursor: pointer;
}

#search-box {
	position: relative;

	input {
		&.search {
			&.dark {
				color: #222;
			}
		}
	}
}

{featureToggleOn("jade")} {

	.globalResults {
		fab-input-focus();
		border-width: 1px;
		width: 394px;

		.dotLoader {
			padding: 42px 0;
		}

		.gEmpty {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 21px 0;
		}

		.gResults {
			fab-background-color: gray1;
			border-top: 1px solid fab-color('gray3');

			&:first-child {
				border-top: none;
			}

			.gContentList {
				border-top: none;
				margin-left: 12px;
				padding: 7px 0;
				width: 256px;

				> li {
					box-sizing: border-box;
					display: flex;
					height: auto;
					padding: 8px 8px 8px 12px;

					&.selected {
						fab-background-color: theme;

						.gContentTitle {
							fab-color: white;
						}

						.gResultTitle {
							fab-color: white;
						}

						.gResultMeta {
							fab-color: white;
						}

						.gCandidateAvatar {
							fab-fill: white;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}

					.gResultTitle {
						fab-color: gray10;
						fab-font-size: medium;
					}

					.gResultMeta {
						fab-color: gray7;
					}
				}

				.gContentTitle {
					fab-color: gray10;
					fab-font-size: medium;
					fab-line-height: teenie;
					align-self: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.gResultInfo {
					align-self: center;
				}
			}

			.gResultsSectionTitle {
				top: 15px;
			}

			.Avatar {
				left: 0;
				margin-right: 8px;
				min-width: 32px;
				position: initial;
				top: 0;
			}

		}

		.gJobApplicants .gApplicantAvatar {
			width: auto;
			height: auto;
			margin: 0 8px 0 0;
		}

	}

	.gEmptyIcon {
		fab-fill: gray6;
		margin-bottom: 12px;
	}

	.gEmptyText {
		fab-color: gray7;
		fab-font-size: medium;
		fab-line-height: small;
	}

	.gResultsSectionTitle {
		fab-color: gray8;
		fab-font-size: medium;
		fab-font-weight: semibold;
		fab-line-height: teenie;
	}

	.gCandidateAvatar {
		fab-fill: gray5;
		min-width: 32px;
	}

}
