/*------------------------------------*\
$BhrIcon

Global class - BhrIcon
Size classes - (vary)

Due to the varying sizes of our icons we
must override some style.
\*------------------------------------*/

.BhrIcon {
	display: inline-block;
	position: relative;
}
.BhrIcon,
.btnHIcon:before {
	background-image: url('https://staticfe.bamboohr.com/resources/images/bhrSprite.png?cb=5');
}

@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 192dpi) {
 	.BhrIcon,
 	.btnHIcon:before {
 		background-image: url('https://staticfe.bamboohr.com/resources/images/bhrSpriteX2.png?cb=5');
 		background-size: 190px; /* Needs to match 1x background width */
 	}
}

/* Sizes */
.BhrIcon--11x11 {
	width: 11px;
	height: 11px;
}
.BhrIcon--12x12 {
	width: 12px;
	height: 12px;
}
.BhrIcon--16x16 {
	width: 16px;
	height: 16px;
}
.BhrIcon--16x20 {
	width: 16px;
	height: 20px;
}
.BhrIcon--28x28 {
	width: 28px;
	height: 28px;
}
.BhrIcon--32x32 {
	width: 32px;
	height: 32px;
}

/* Icons */
.BhrIcon--individual-circle-selected {
	background-position: 91px 92px;
}
.BhrIcon--individual-circle-deselected {
    background-position: 91px 63px;
}
.BhrIcon--group-circle-selected {
    background-position: 30px 70px;
}
.BhrIcon--group-circle-deselected {
    background-position: 59px 70px;
}
.BhrIcon--add-employee-info {
	background-position: -56px 0;
}
.BhrIcon--add-esignature {
	background-position: -86px -62px;
}
.BhrIcon--arrow-sm-white {
	background-position: -69px -166px;
}
.BhrIcon--bed {
	background-position: -96px -217px;
}
.BhrIcon--bell-brand {
	background-position: -148px -153px;
}
.BhrIcon--bell-white {
	background-position: -130px -153px;
}
.BhrIcon--checkbox {
	background-position: -86px 0;
}
.BhrIcon--check-sm-white {
	background-position: -28px -317px;
}
.BhrIcon--coinsheart {
	background-position: -129px -241px;
}
.BhrIcon--clip {
	background-position: -93px -176px;
}
.BhrIcon--compass {
	background-position: -121px -32px;
}
.BhrIcon--compass-lt-gray {
	background-position: -154px -32px;
}
.BhrIcon--complete {
	background-position: -32px -24px;
}
.BhrIcon--complete-disabled {
	background-position: -44px -24px;
}
.BhrIcon--complete-lrg {
	background-position: -0px -20px;
}
.BhrIcon--complete-lrg-disabled {
	background-position: -16px -20px;
}
.BhrIcon--date-field {
	background-position: -57px -62px;
}
.BhrIcon--disabled {
	background-position: -174px -222px;
}
.BhrIcon--drop {
	background-position: -121px -64px;
}
.BhrIcon--edit {
	background-position: 0 -52px;
}
.BhrIcon--esignature {
	background-position: 0 0;
}
.BhrIcon--esignature-white {
	background-position: -16px 0;
}
.BhrIcon--eye-sm {
	background-position: -152px -180px;
}
.BhrIcon--eye-sm-white {
	background-position: -51px -167px;
}
.BhrIcon--folder-white {
	background-position: -88px -147px;
}
.BhrIcon--fullwallet {
	background-position: -153px -241px;
}
.BhrIcon--gear-sm-white {
	background-position: -35px -169px;
}
.BhrIcon--16x16.BhrIcon--gear-sm-white {
	background-position: -35px -167px;
}
.BhrIcon--gears-white {
	background-position: -87px -91px;
}
.BhrIcon--group {
	background-position: -130px -259px;
}
.BhrIcon--handcoins {
	background-position: -152px -222px;
}
.BhrIcon--heartbeat {
	background-position: -130px -180px;
}
.BhrIcon--variableHealth {
	background-position: -175px -162px;
}
.BhrIcon--heartplus {
	background-position: -151px -201px;
}
.BhrIcon--hourglass {
	background-position: -66px -220px;
}
.BhrIcon--info {
	background-position: -87px -285px;
}
.BhrIcon--initials {
	background-position: -57px -30px;
}
.BhrIcon--lock {
	background-position: -129px -127px;
}
.BhrIcon--lock-lrg {
	background-position: -129px -104px;
}
.BhrIcon--mail-white {
	background-position: -88px -122px;
}
.BhrIcon--mirror {
	background-position: -121px 0px;
}
.BhrIcon--mirror-lt-gray {
	background-position: -154px 0px;
}
.BhrIcon--paragraph {
	background-position: 0px -313px;
}
.BhrIcon--parthenon {
	background-position: -173px -201px;
}
.BhrIcon--peers {
	background-position: -156px -64px;
}
.BhrIcon--parthenon {
	background-position: -173px -201px;
}
.BhrIcon--pending {
	background-position: -32px -40px;
}
.BhrIcon--pending-disabled {
	background-position: -44px -40px;
}
.BhrIcon--person-white {
	background-position: -56px -120px;
}
.BhrIcon--piechart {
	background-position: -174px -258px;
}
.BhrIcon--piechart-white {
	background-position: -56px -91px;
}
.BhrIcon--piggybank {
	background-position: -129px -222px;
}
.BhrIcon--plus-white {
	background-position: -13px -151px;
}
.BhrIcon--plus-sm-white {
	background-position: -35px -152px;
}
.BhrIcon--16x16.BhrIcon--plus-sm-white {
	background-position: -35px -150px;
}
.BhrIcon--reset-sm-white {
	background-position: -70px -149px;
}
.BhrIcon--spy {
	background-position: -152px -97px;
}
.BhrIcon--shieldplus {
	background-position: -173px -180px;
}
.BhrIcon--star {
	background-position: -174px -242px;
}
.BhrIcon--text-field {
	background-position: -86px -30px;
}
.BhrIcon--tooth {
	background-position: -130px -201px;
}
.BhrIcon--trash-gray {
	background-position: -155px -260px;
}
.BhrIcon--trash-sm-white {
	background-position: -54px -152px;
}
.BhrIcon--16x16.BhrIcon--trash-sm-white {
	background-position: -54px -150px;
}

.BhrIcon--x {
	background-position: 0 -138px;
}

/* Icons in elements */
.btn > .BhrIcon--16x20 {
	margin: 0 8px 0 0;
	top: 4px;
}
.btnLarge > .BhrIcon--16x20 {
	margin: 0 8px 0 -5px;
}
.btn > .BhrIcon--16x16 {
  margin: 0 4px 0 0;
  top: 1px;
}
.btn > .BhrIcon--12x12 {
	margin: 0 4px 0 0;
	top: 1px;
}
.btn > .BhrIcon--11x11 {
	margin: 0 4px 0 0;
	top: 1px;
}
.btn > .BhrIcon--28x28 {
	margin: 0 4px 0 0;
	top: 8px;
}
.btnSquare > .BhrIcon--28x28 {
	margin: 0 auto;
	top: 1px;
}
.btn3XLarge > .BhrIcon--28x28 {
	margin: 0 4px 0 0;
	top: 10px;
}
.btnSquare > .BhrIcon {
	display: block;
}
li > .BhrIcon {
	margin-right: 5px;
	top: 1px;
}

/** buttons with branded color on hover **/
.btn.btnHIcon {
	position: relative;
	padding: 0 10px 1px 33px;
	text-align: left;
	color: #444;
}
.btnHIcon:before {
	content: "";
	display: inline-block;
	height: 24px;
	width: 24px;
	position: absolute;
	top: 1px;
	left: 9px;
	vertical-align: middle;
}
.btnHDownload:before {
	top: 3px;
	height: 20px;
	background-position: 0 -64px;
}
.btnHDownload:hover:before {
	background-position: -25px -64px;
}
.btnHPrint:before {
	background-position: 0 -88px;
}
.btnHPrint:hover:before {
	background-position: -24px -88px;
}
.btnHShare:before {
	background-position: 0 -112px;
}
.btnHShare:hover:before {
	background-position: -24px -112px;
}

.brandedSectionIcon:before {
	content: '';
	display: block;
	float: left;
	height: 30px;
	width: 30px;
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/section-icons.png?cb=3");
	background-repeat: no-repeat;
	top: 2px;
	vertical-align: baseline;
	margin-right: 5px;
	margin-top: -10px;
	position: relative;
}
.dashboardSection .brandedSectionIcon:before {
	background-position: 0 0;
	width: 35px;
}
.employeesSection .brandedSectionIcon:before {
	background-position: -35px 0;
	margin-top: -9px;
	width: 31px;
}
.jobSection .brandedSectionIcon:before {
	background-position: -70px 0;
	width: 24px;
}
.reportSection .brandedSectionIcon:before {
	background-position: -105px 0;
	width: 24px;
	top: 4px;
}
.filesSection .brandedSectionIcon:before {
	background-position: -139px 0px;
	width: 25px;
}
.brandedSectionIcon--history:before {
	background-position: -175px 0;
	width: 26px;
}
.brandedSectionIcon--inbox:before {
	background-position: -210px 0;
	width: 30px;
}
