.AcaStatusSelect {

  &__description {
    fab-color: gray7;
    fab-font-size: small;
    line-height: 18px;
    margin-top: 8px;

    &Url {
      margin-left: 4px;
    }
  }
}
