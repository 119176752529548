
/*---------------------------------*\
$BhrShape

Global class - BhrShape

Create shape and border combinations
for use with icons specifically
/*---------------------------------*/

.BhrShape {
	position: relative;
	display: inline-block;
}

/* backgrounds */
.BhrShape--bck1 {
	background-color: #909090;
}
.BhrShape--bck2 {
	background-color: #d6d6d6;
}
.BhrShape--bck3 {
	background-color: #a7a7a7;
}

/* borders */
.BhrShape--border1 {
	border: solid #dfdfdf 2px;
}
.BhrShape--border2 {
	border: solid #afafaf 1px;
}
.BhrShape--border3 {
	border: solid #ffffff 2px;
}

/* radius' */
.BhrShape--circle {
	border-radius: 100%;
}
.BhrShape--rounded {
	border-radius: 3px;
}

/* sizes */
.BhrShape--sm {
	width: 24px;
	height: 24px;
}
.BhrShape--lrg {
	width: 36px;
	height: 36px;
}
.BhrShape--xlrg {
	width: 45px;
	height: 45px;
}


/*---------------------------------*\
Icons in shapes

need to be specifically positioned
/*---------------------------------*/

/* small circle icons */
.BhrShape--sm {
	& > {
		.BhrIcon--arrow-sm-white {
			top: 2px;
			left: 4px;
		}

		.BhrIcon--check-sm-white {
			top: 3px;
			left: 4px;
		}

		.BhrIcon--eye-sm-white {
			top: 3px;
			left: 4px;
		}

		.BhrIcon--gear-sm-white {
			top: 3px;
			left: 6px;
		}

		.BhrIcon--plus-sm-white {
			top: 3px;
			left: 6px;
		}

		.BhrIcon--reset-sm-white {
			top: 3px;
			left: 4px;
		}

		.BhrIcon--trash-sm-white {
			top: 3px;
			left: 7px;
		}
	}
}

/* large square/rounded icons */
.BhrShape--lrg {
 	& > {
		.BhrIcon--folder-white {
			top: 4px;
			left: 4px;
		}

		.BhrIcon--gears-white {
			top: 4px;
			left: 4px;
		}

		.BhrIcon--mail-white {
			top: 4px;
			left: 5px;
		}

		.BhrIcon--person-white {
			top: 4px;
			left: 4px;
		}

		.BhrIcon--piechart-white {
			top: 4px;
			left: 5px;
		}
	}
}

/* extra-large square/rounded icons */
.BhrShape--xlrg {
	& > {
		.BhrIcon--hourglass {
			top: 8px;
			left: 8px;
		}
		.BhrIcon--bed {
			top: 5px;
			left: 6px;
		}
	}
}
