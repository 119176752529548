/*------------------------------------*\
$MODAL MODULE

BEM styles used for bhr Modals
\*------------------------------------*/

div.ui-dialog .benefitTabModalIcon:before,
#modal-dialog .singleEntryDialog label,
.tornEdgeContainer:after,
#modal-dialog .shareListInputLabel,
#stacked-modal-dialog .shareListInputLabel {
	display: block;
}

.BhrModal {
	&__heading {
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
	}

	&__message {
		font-size: 14px;
		color: #777;
		line-height: 18px;
	}
}

div.ui-dialog {
	.modalSubHeader {
		&,
		/.SimpleModal .modalSubHeader {
			padding: 0 10px;
			height: 26px;
			border-bottom: 1px solid #bbb;
			border-radius: 0;
			line-height: 28px;
			margin: 0;
			font-size: 13px;
			font-weight: 600;
			color: #555;
			background-image: linear-gradient(to bottom,  #ec 0, #f8 100%);
		}

		&WithIcon {
			height: 43px;
			font-size: 20px;
			color: #222;
			line-height: 45px;
			border-bottom: 1px solid #c9c9c9;
			background-image: linear-gradient(to bottom,  #DADADA 0, #F5F5F5 100%);
		}
	}

	.benefitTabModalIcon {
		&:before {
			margin: 14px 7px 0 0;
			width: 22px;
			height: 18px;
			background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/benefit-modal-icon.png");
			background-repeat: no-repeat;
			content: "";
			display: block;
			float: left;
		}
	}
}

#modal-dialog {
	.singleEntryDialog {
		width: 330px;
		color: #555;
		margin-bottom: 7px;

		label,
		/.SimpleModal .singleEntryDialog label {
			font-weight: 700;
			margin-bottom: 6px;
			display: block;
			font-size: 15px;
		}
	}
}

.tornEdgeContainer {
	border: 1px solid $bhrColors.gray6;
	background: $bhrColors.gray1;
	border-radius: 3px 3px 0 0;
	border-bottom: none;
	padding: 16px 10px 10px;
	line-height: normal;
	width: 333px;
	margin: 15px auto 0;
	text-align: left;
	position: relative;

	&:after {
		content: "";
		background: url("https://staticfe.bamboohr.com/resources/images/applicants/torn-edge.png") bottom left;
		height: 10px;
		display: block;
		position: absolute;
		bottom: -9px;
		left: -5px;
		width: 360px;
	}

	.BhrForms {
		position: relative;
	}

	#modal-dialog & {
		&__icon {
			margin-bottom: 3px;
			border: solid #8c8c8c 1px;
		}

		&__heading {
			margin: 0 9px;
			padding: 0;
			font-size: 14px;
			font-weight: 600;
			color: #333;
			line-height: 1em;
		}

		&__tagline {
			margin: 0;
			padding: 0 0 0 7px;
			color: #999;
			line-height: 1.2em;
			font-weight: 400;
		}

		&__content {
			margin: 7px 9px 0;
			padding: 0;
			color: #777;
		}
	}
}

/** Employee modal header **/
.EmpSummaryHeader {
	border-bottom: 1px dashed #DFDFDF;
	clear: both;
	font-size: 14px;
	line-height: 1.3;
	margin: -4px 0 20px;
	padding-bottom: 14px;
	min-width: 330px;

	// .EmpSummaryHeaderAvatarContainer
	&AvatarContainer {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
	}

	// .EmpSummaryHeaderTextContainer
	&TextContainer {
		display: inline-block;
	}

	/** .EmpSummaryHeader__avatar **/
	&__avatar {
		height: 55px;
		margin-right: 14px;
		width: 55px;
	}
}

/** modal Custom Approvals **/
.CustomApprovalForm {
	min-width: 375px;

	// .CustomApprovalForm .BhrForms fieldset
	.BhrForms fieldset {
		display: block;
	}

	#currencyPickerWidget {
		left: 0 !important;
	}

	#newCurrencyPickerWidget {
		left: 0 !important;
	}
}

/** Modal comment **/
.modalComment {
	height: 45px;

	&Divider {
		border-top-style: dashed;
		margin: 15px 0 20px 0;
	}
}

// Delete Modals
.delete {
	&Modal {
		text-align: center;
		width: 410px;

		&.small {
			max-width: 400px;
		}

		&.medium {
			max-width: 550px;
		}

		&__header {
			&--noMargin {
				margin: 0;
			}
		}

		.title {
			font-size: 18px;
			font-weight: 700;
		}

		.subText {
			font-size: 14px;
			color: #777;
		}

		.subHeader {
			font-size: 24px;
			line-height: 27px;
			margin-bottom: 5px;
		}

		.deleteSubMessage {
			font-size: 14px;
		}
		// .deleteModal .deleteMessage
		.deleteMessage {
			margin: 6px auto 15px;
			width: 85%;
		}
		// .deleteModal .deleteMessage__details
		.deleteMessage__details {
			color: #2;
			display: inline-block;
			font-size: 15px;
			font-weight: normal;
			line-height: 19px;
			margin-top: 8px;
		}

		.positionDescription {
			text-align: center;
		}

		.alert {
			color: #be3105;
			font-size: 13px;
			margin-top: 11px;
		}

		&Trash {
			background: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/modal-trash-can.png");
		}

		&__box {
			padding: 16px 0 20px;
			background: $bhrColors.gray1;
			border: solid $bhrColors.gray6 1px;
			border-radius: 3px;

			&-heading {
				font-weight: 600;
				font-size: 18px;
			}
			&-subtext {
				font-size: 14px;
				color: #999;
			}
			&-confirm {
				margin-bottom: 8px;
			}
		}
	}

	&EmployeeModal {
		text-align: center;
		width: 385px;
		margin: 0 auto;
	}

	&Modal,
	&EmployeeModal {
		.deleteMessage {
			span {
				&.emphasis {
					color: #222;
				}
			}
		}

		.confirm {
			color: #be3105;
			font-size: 15px;
			font-weight: 600;
			margin-top: 14px;
			margin-bottom: 2px;
		}

		.trashIcon {
			margin-right: 32px;
			margin-top: 5px;
			height: 78px;
			width: 109px;
		}
	}

	&Modal &Message,
	&EmployeeModal &Message,
	&Modal &SubMessage {
		font-size: 18px;
		font-weight: 600;
		margin-top: 6px;
		margin-bottom: 15px;
		line-height: 1.2;
		color: #777;
	}
}

.hiredModal {
	text-align: center;
	width: 420px;
	padding-top: 18px;

	.hiredMessage {
		font-size: 20px;
		font-weight: 600;
		line-height: 1.2;
		color: #222;
	}

	.addMessage {
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 18px;
	}

	.starsIcon {
		margin-top: -10px;
	}

	.closeMessage {
		font-size: 14px;
		text-align: left;
		margin-left: 20px;
		padding-bottom: 10px;
		color: #777;

		span {
			&.emphasis {
				color: #222222;
				font-weight: 600;
			}
		}

		input {
			&[type="checkbox"] {
				display:none;

				&:checked {
					+ label {
						span {
							background-position: center -15px;
						}
					}
				}

				+ label {
					span.checkbox {
						background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/checks-radios.png");
						width: 15px;
						height: 15px;
						display:inline-block;
						background-color: #000;
						margin: 4px 5px -3px 0;
					}
				}
			}
		}
	}
}

.cautionModal {
	box-sizing: border-box;
	padding: 20px 25px 10px;
	width: 435px;

	&__subHeader {
		font-size: 18px;
		line-height: 20px;
		margin-bottom: 10px;
	}
	&__icon {
		margin-top: 5px;
	}
}

#editForm {
	.closeMessage {
		label {
			display: inline-block;
		}
	}
}

#filter-modal-container {
	overflow-y:visible;
	height:auto !important;
	padding-top:10px !important;

	.fieldRow {
		margin-bottom: 10px;
	}
}

.filter-error {
	color:#b42f06 !important;
}

div.drop-filter {
	span {
		&.filter-error {
			&:after {
				background: url("https://staticfe.bamboohr.com/resources/images/icons/field-error.png") no-repeat;
				content: "";
				display: inline-block;
				width: 11px;
				height: 11px;
				margin: -2px 0 0 2px;
				vertical-align: middle;
			}
		}
	}
}

.ui-dialog-content {
	max-height: 450px;

	form {
		.fieldRow {
			&:last-of-type:not(.fieldRow--forceBottomSpace) {
				margin-bottom: 0;
			}
		}
	}
}

.shareListInputLabel {
	#modal-dialog &,
	#stacked-modal-dialog &,
	.SimpleModal & {
		margin: -2px 0 2px 1px;
		font-size: 13px;
		font-weight: 400;
		display: block;
		color: #555;
	}
}

.shareForm {
	.input-list {
		box-sizing: border-box;
		min-height: 26px;
		height: auto;
		display: inline-block;
		padding-bottom: 2px;
		margin: 0 0 10px;

		&.shown {
			min-height: 45px;
			position: relative;
		}

		.description {
			color: #a0;
			display: block;
			margin: 3px 0 0;
			padding: 0 5px;
			font-size: 15px;
			font-weight: 400;
		}

		.inputs {
			display: none;
		}

		.free {
			margin: 0;
			padding: 0;
			height: 17px;
			position: relative;
			top: -1px;
			max-width: 385px;
			vertical-align: bottom;
			min-width: 15px;
			height: 18px;
		}

		.item {
			float: none;
			box-sizing: border-box;
			height: 20px;
			padding: 0 6px 0 6px;
			right: -1px;
		}

		.input {
			display: inline-block;
			min-width: 10px;
			padding-left: 5px;
			margin-bottom: 0;
			height: 20px;
			float: none;
			// box-shadow: 0 0 1px red;
			vertical-align: bottom;
		}
	}

	.forInputSize {
		border: 1px solid red;
		height: 0;
		visibility: hidden;
		white-space: nowrap;
		position: absolute;
		display: inline;
	}
}

.inputListWrapper {
	background: linear-gradient(to bottom, rgba(230, 230, 230, 1) 0, rgba(235, 235, 235, 1) 100%);
	box-shadow: 0 1px 0 #9b9b9b inset;
	font-size: 13px;
	color: #777777;
	// float: left;
	padding: 7px 20px 0;
	#shareInput {
		max-height: 30vh;
		overflow-y: auto;
	}
}

// Permissions Modal
#multiple-permissions {
	.modalSubHeader {
		height: auto;
		line-height: 1em;
		padding: 14px 20px 16px;

		.employeeName {
			color: #000;
			font-size: 22px;
			margin-top: 5px;

			i {
				position: relative;
				top: 1px;
				margin-right: 7px;
				vertical-align: baseline;
			}
		}
	}

	.permsHeader {
		> i {
			vertical-align: bottom;
			margin-right: 7px;
		}
	}

	.permsBody {
		overflow-y: auto;
		max-height: 350px;
	}

	.fieldRow {
		padding: 0;
		margin-bottom: 0;
	}

	.multi-list-field {
		label {
			max-width: 310px;
		}
	}

	.note {
		margin-top: 12px;
		width: auto;
		fab-font-size: teenie;
	}
}

// Termination modal
#terminateEmployeeModal {
	// IE 11 shows the dumb horizontal scrollbar
	overflow-x: hidden;

	#terminateEmployeeForm {
		margin-top: 0;
	}

	.fieldRow {
		padding: 9px 21px 4px 0;
		margin-bottom: 0;

		&:last-of-type {
			padding-bottom: 27px;
		}

		.employmentStatusComment {
			min-height: 58px;
		}
	}

	.zenpayrollFields {
		> label {
			&:last-of-type {
				margin-top: 16px;
			}
		}
	}
}

// Mutliple to single perms modal
#multipleToSingleModal {
	width: 400px;

	.bicon-permissions-lock-white {
		margin-right: 4px;

		li {
			padding: 0 0 3px 21px;
			position: relative;

			> i {
				position: absolute;
				top: -2px;
				left: 1px;
			}
		}
	}

	.note {
		color: #a4;
		font-size: 13px;
		line-height: 15px;
	}

	ul {
		margin-bottom: 11px;
	}
}

.fieldBox {
	.employee-note {
		font-size: 14px;
	}
}

.chickenSwitch {
	min-width: 355px;

	&__content {
		text-align: center;
		min-height: 64px;

		&--customHeight {
			min-height: initial;
		}
	}

	&__deleteMessage {
		font-size: 18px;
		font-weight: 400;
		margin-top: 6px;
		margin-bottom: 15px;
		line-height: 1.2;
		color: #555;

		span {
			font-weight:600;
		}

		.emphasis {
			color: #222;
		}
	}

	//.chickenSwitch__notDeletedMessage
	&__notDeletedMessage {
		color: #555;
		font-size: 13px;
	}

	&__featureText {
		font-size: 18px;
		font-weight: 600;
		color: #333;
		margin: 5px 0 10px;
	}

	&__footerText {
		display: block;
		max-width: 355px;
		margin: 25px auto 0;
		padding: 0;
		text-align: left;
	}
	&__hintText {
		color: $bhrColors.gray9;
		margin-top: 25px;
	}
}

// Resend New Hire Packet Modal
#resend_packet_modal {
	.packet {
		&__label {
			font-weight: 700;
			font-size: 15px;
			color: #555;
			margin-bottom: 5px;
		}

		&__message {
			font-weight: 400;
			font-size: 13px;
			color: #909090;
			margin-top: 5px;
		}

		&__email {
			width: 300px;
		}
	}
}

// Performance Review Date Change Modal
.changeDateModal {
	text-align: center;

	&__mainMessage {
		color: #222;
		font-size: 18px;
		font-weight: normal;
		line-height: 24px;
		padding: 0 45px;
	}

	&__dateChangeIcon {
		display: block;
		margin: 18px auto 18px;
	}

	&__moreInfo {
		color: #555;
		font-size: 14px;
		font-weight: normal;
		line-height: 18px;
		padding: 0 20px;
	}
}
