.DataProcessingAgreementConditionalAgreement {
	padding-top: 20px;
	fab-color: gray8;

	&__agreement {
		fab-background-color: gray1;
		margin-left: 24px;
		margin-top: 12px;
		padding: 20px 25px;
	}

	&__answers {
		margin-top: 14px;
	}

	&__requiredMessage {
		fab-color: gray10;
		fab-font-weight: semibold;
		margin-bottom: 10px;
	}
}
