.InboxDropdown {

	&__counts {
		&-container {
			opacity: 0;
			transition: all 0.5s;
			margin-top: -10px;

			^[0]--has-count & {
				opacity: 1;
				margin-top: 0;
			}
		}
	}
}
