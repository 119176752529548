.mlDrop {
	&Container {
		position: relative;

		.mlDrop {
			display: none;
			margin: 0;
			position: absolute;
			width: 150px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
			border: solid #aaa 1px;
			border-top: none;
			border-bottom-left-radius: 3px;
			border-bottom-right-radius: 3px;
			background: #fff;
			z-index: 999;

			li {
				position: relative;
				padding: 0;

				&:hover {
					> a {
						fab-background-color: theme;					
						color: #fff;				
						font-weight: 600;

						&:after {
							border-left-color: #fff;
						}
					}
				}

				a {
					display: block;
					padding: 5px 0 5px 6px;
					text-decoration: none;
					color: #222;
				}
			}

			> li {

				&:hover {
					> ul {
						display: block;
					}
				}
				> ul {
					display: none;
					position: absolute;
					min-width: 150px;
					top: -1px;
					left: 100%;
					background: #fff;
					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
					border: solid #aaa 1px;
					border-radius: 3px;
					max-height: 182px;
					overflow-y: auto;
				}

				> a {
					&:after {
						content: "";
						display: block;
						position: absolute;
						top: 8px;
						right: 4px;
						border: solid transparent 4px;
						border-left: solid #78 4px;
					}
				}
			}
		}

		.topLevel {
			margin: 0;
		}

		> .topLevel {
			&.show {
				> a {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}
	}
}
