@require "jquery.calendar-picker.lib";

[calendar-picker] {
	display: inline-block;
}

.BhrForms .error {
	& input.calendar-field:not([type='hidden']) + .ui-datepicker-trigger,
	& input.date-field:not([type='hidden']) + .ui-datepicker-trigger,
	& input.CalendarPicker__input + .CalendarPicker__toggleButton  {
		border-color: $bhrColors.errorBorder;
	}

	& input.CalendarPicker__input + .btn.CalendarPicker__toggleButton {
		border-right-color: $bhrColors.errorBorder;
	}
}

input.calendar-field:not([type='hidden']),
input.date-field:not([type='hidden']),
input.CalendarPicker__input {

	& + .ui-datepicker-trigger
	& + .CalendarPicker__toggleButton {
		+legacy() {
			border-bottom-width: 0;
			border-left: solid #ba 1px;
			border-radius: 0 2px 2px 0;
			border-right-color: #ba;
			border-right-style: solid;
			border-right-width: 1px;
			border-top-width: 0;
			box-shadow: none;
			height: 26px;
			min-width: 0;
			margin-top: 1px;
			margin-right: 1px;
			line-height: normal;
			transform: none;
			padding: 2px 3px 2px 6px;
			right: -1px;
			top: 4px;

			img {
				width: 15px;
				margin-bottom: -2px !important;
			}
		}
	}

	&:disabled + .ui-datepicker-trigger
	&:disabled + .CalendarPicker__toggleButton {
		background: #fff;
		border-color: #d7d7d7;
		opacity: 1;

		img {
			opacity: 0.5;
		}
	}

	// hide the button for "view" state
	&.fab-TextInput--view ~ .CalendarPicker__toggleButton {
		visibility: hidden;
	}
}

.CalendarGridManager .CalendarGridManager__gridHeaderDropdown > a {
	background-color: #F2;
	box-shadow: none;

	&:hover, &.chzn-single-with-drop {
		background-color: #FF;
	}
}
