/*!
 * Bootstrap v2.0.1
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
[class^="glyphicon-"], [class*=" glyphicon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: middle;
  background-image: url("https://staticfe.bamboohr.com/resources/images/glyphicons-halflings.png");
  background-position: 14px 14px;
  background-repeat: no-repeat;
  *margin-right: .3em;
}
.btn [class^="glyphicon-"], .btn [class*=" glyphicon-"]{
  vertical-align:middle;
  margin-top: 3px;
  padding-bottom: 5px;
}
.btn.btnInline [class^="glyphicon-"],
.btn.btnInline [class*=" glyphicon-"],
.btn.btnMini [class^="glyphicon-"],
.btn.btnMini [class*=" glyphicon-"] {
  margin-top:1px;
}

[class^="glyphicon-"]:last-child, [class*=" glyphicon-"]:last-child {
  *margin-left: 0;
}
.glyphicon-white {
  background-image: url("https://staticfe.bamboohr.com/resources/images/glyphicons-halflings-white.png");
}
.glyphicon-glass {
  background-position: 0      0;
}
.glyphicon-music {
  background-position: -24px 0;
}
.glyphicon-search {
  background-position: -48px 0;
}
.glyphicon-envelope {
  background-position: -72px 0;
}
.glyphicon-heart {
  background-position: -96px 0;
}
.glyphicon-star {
  background-position: -120px 0;
}
.glyphicon-star-empty {
  background-position: -144px 0;
}
.glyphicon-user {
  background-position: -168px 0;
}
.glyphicon-film {
  background-position: -192px 0;
}
.glyphicon-th-large {
  background-position: -216px 0;
}
.glyphicon-th {
  background-position: -240px 0;
}
.glyphicon-th-list {
  background-position: -264px 0;
}
.glyphicon-ok {
  background-position: -288px 0;
}
.glyphicon-remove {
  background-position: -312px 0;
}
.glyphicon-zoom-in {
  background-position: -336px 0;
}
.glyphicon-zoom-out {
  background-position: -360px 0;
}
.glyphicon-off {
  background-position: -384px 0;
}
.glyphicon-signal {
  background-position: -408px 0;
}
.glyphicon-cog {
  background-position: -432px 0;
}
.glyphicon-trash {
  background-position: -456px 0;
}
.glyphicon-home {
  background-position: 0 -24px;
}
.glyphicon-file {
  background-position: -24px -24px;
}
.glyphicon-time {
  background-position: -48px -24px;
}
.glyphicon-road {
  background-position: -72px -24px;
}
.glyphicon-download-alt {
  background-position: -96px -24px;
}
.glyphicon-download {
  background-position: -120px -24px;
}
.glyphicon-upload {
  background-position: -144px -24px;
}
.glyphicon-inbox {
  background-position: -168px -24px;
}
.glyphicon-play-circle {
  background-position: -192px -24px;
}
.glyphicon-repeat {
  background-position: -216px -24px;
}
.glyphicon-refresh {
  background-position: -240px -24px;
}
.glyphicon-list-alt {
  background-position: -264px -24px;
}
.glyphicon-lock {
  background-position: -287px -24px;
}
.glyphicon-flag {
  background-position: -312px -24px;
}
.glyphicon-headphones {
  background-position: -336px -24px;
}
.glyphicon-volume-off {
  background-position: -360px -24px;
}
.glyphicon-volume-down {
  background-position: -384px -24px;
}
.glyphicon-volume-up {
  background-position: -408px -24px;
}
.glyphicon-qrcode {
  background-position: -432px -24px;
}
.glyphicon-barcode {
  background-position: -456px -24px;
}
.glyphicon-tag {
  background-position: 0 -48px;
}
.glyphicon-tags {
  background-position: -25px -48px;
}
.glyphicon-book {
  background-position: -48px -48px;
}
.glyphicon-bookmark {
  background-position: -72px -48px;
}
.glyphicon-print {
  background-position: -96px -48px;
}
.glyphicon-camera {
  background-position: -120px -48px;
}
.glyphicon-font {
  background-position: -144px -48px;
}
.glyphicon-bold {
  background-position: -167px -48px;
}
.glyphicon-italic {
  background-position: -192px -48px;
}
.glyphicon-text-height {
  background-position: -216px -48px;
}
.glyphicon-text-width {
  background-position: -240px -48px;
}
.glyphicon-align-left {
  background-position: -264px -48px;
}
.glyphicon-align-center {
  background-position: -288px -48px;
}
.glyphicon-align-right {
  background-position: -312px -48px;
}
.glyphicon-align-justify {
  background-position: -336px -48px;
}
.glyphicon-list {
  background-position: -360px -48px;
}
.glyphicon-indent-left {
  background-position: -384px -48px;
}
.glyphicon-indent-right {
  background-position: -408px -48px;
}
.glyphicon-facetime-video {
  background-position: -432px -48px;
}
.glyphicon-picture {
  background-position: -456px -48px;
}
.glyphicon-pencil {
  background-position: 0 -72px;
}
.glyphicon-map-marker {
  background-position: -24px -72px;
}
.glyphicon-adjust {
  background-position: -48px -72px;
}
.glyphicon-tint {
  background-position: -72px -72px;
}
.glyphicon-edit {
  background-position: -96px -72px;
}
.glyphicon-share {
  background-position: -120px -72px;
}
.glyphicon-check {
  background-position: -144px -72px;
}
.glyphicon-move {
  background-position: -168px -72px;
}
.glyphicon-step-backward {
  background-position: -192px -72px;
}
.glyphicon-fast-backward {
  background-position: -216px -72px;
}
.glyphicon-backward {
  background-position: -240px -72px;
}
.glyphicon-play {
  background-position: -264px -72px;
}
.glyphicon-pause {
  background-position: -288px -72px;
}
.glyphicon-stop {
  background-position: -312px -72px;
}
.glyphicon-forward {
  background-position: -336px -72px;
}
.glyphicon-fast-forward {
  background-position: -360px -72px;
}
.glyphicon-step-forward {
  background-position: -384px -72px;
}
.glyphicon-eject {
  background-position: -408px -72px;
}
.glyphicon-chevron-left {
  background-position: -432px -72px;
}
.glyphicon-chevron-right {
  background-position: -456px -72px;
}
.glyphicon-plus-sign {
  background-position: 0 -96px;
}
.glyphicon-minus-sign {
  background-position: -24px -96px;
}
.glyphicon-remove-sign {
  background-position: -48px -96px;
}
.glyphicon-ok-sign {
  background-position: -72px -96px;
}
.glyphicon-question-sign {
  background-position: -96px -96px;
}
.glyphicon-info-sign {
  background-position: -120px -96px;
}
.glyphicon-screenshot {
  background-position: -144px -96px;
}
.glyphicon-remove-circle {
  background-position: -168px -96px;
}
.glyphicon-ok-circle {
  background-position: -192px -96px;
}
.glyphicon-ban-circle {
  background-position: -216px -96px;
}
.glyphicon-arrow-left {
  background-position: -240px -96px;
}
.glyphicon-arrow-right {
  background-position: -264px -96px;
}
.glyphicon-arrow-up {
  background-position: -289px -96px;
}
.glyphicon-arrow-down {
  background-position: -312px -96px;
}
.glyphicon-share-alt {
  background-position: -336px -96px;
}
.glyphicon-resize-full {
  background-position: -360px -96px;
}
.glyphicon-resize-small {
  background-position: -384px -96px;
}
.glyphicon-plus {
  background-position: -408px -96px;
}
.glyphicon-minus {
  background-position: -433px -96px;
}
.glyphicon-asterisk {
  background-position: -456px -96px;
}
.glyphicon-exclamation-sign {
  background-position: 0 -120px;
}
.glyphicon-gift {
  background-position: -24px -120px;
}
.glyphicon-leaf {
  background-position: -48px -120px;
}
.glyphicon-fire {
  background-position: -72px -120px;
}
.glyphicon-eye-open {
  background-position: -96px -120px;
}
.glyphicon-eye-close {
  background-position: -120px -120px;
}
.glyphicon-warning-sign {
  background-position: -144px -120px;
}
.glyphicon-plane {
  background-position: -168px -120px;
}
.glyphicon-calendar {
  background-position: -192px -120px;
}
.glyphicon-random {
  background-position: -216px -120px;
}
.glyphicon-comment {
  background-position: -240px -120px;
}
.glyphicon-magnet {
  background-position: -264px -120px;
}
.glyphicon-chevron-up {
  background-position: -288px -120px;
}
.glyphicon-chevron-down {
  background-position: -313px -119px;
}
.glyphicon-retweet {
  background-position: -336px -120px;
}
.glyphicon-shopping-cart {
  background-position: -360px -120px;
}
.glyphicon-folder-close {
  background-position: -384px -120px;
}
.glyphicon-folder-open {
  background-position: -408px -120px;
}
.glyphicon-resize-vertical {
  background-position: -432px -119px;
}
.glyphicon-resize-horizontal {
  background-position: -456px -118px;
}
