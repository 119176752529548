.ScrollBox,
.ScrollBox__scrollContent,
.ScrollBox__footer {
	box-sizing: border-box;
}

.ScrollBox {
  padding: 0;
	border: solid #afafaf 1px;
	border-radius: 3px;
	background-color: #f8f8f8;
	box-shadow: inset 0px 1px 4px -2px rgba(0, 0, 0, .5);
	width: 380px;
}

.ScrollBox--input {
	background: #f8f8f8; /* Old browsers */
	background: -moz-linear-gradient(top, #f8f8f8 0%, #ffffff 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f8f8f8), color-stop(100%,#ffffff)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #f8f8f8 0%,#ffffff 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #f8f8f8 0%,#ffffff 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #f8f8f8 0%,#ffffff 100%); /* IE10+ */
	background: linear-gradient(to bottom, #f8f8f8 0%,#ffffff 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	box-shadow:inset 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.ScrollBox--plain {
	background: #fff;
	border-color: #787878;
	width: auto;
	box-shadow: none;
}

.ScrollBox--shadow {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .18);
}

.ScrollBox .ScrollBox__scrollContent {
  width: 100%;
	overflow: auto;
	padding: 5px 0;
	font-size: 15px;
}

.ScrollBox .ScrollBox__footer {
	margin: 0;
	padding: 7px 0 6px 15px;
	width: 100%;
	font-weight: 600;
	font-size: 15px;
	color: #222222;
	border-top: solid #afafaf 1px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	background-color: #ffffff;
}


/*
* List options
*/
.ui-dialog .ScrollBox .ScrollBox__scrollContent { padding: 5px 0; }
.ScrollBox .ScrollBox__scrollContent input[type='text'] { vertical-align: baseline; }

.ScrollBox .ScrollBox__listItem { padding: 0px 0px 3px 16px !important; }
.ScrollBox__listItem--disabled { color: #909090; }

.ScrollBox__listItem-label {
	display: inline-block;
	width: 85px;
}
.ScrollBox__listItem-description {
	margin-left: 5px;
	font-size: 13px;
	color: #b4b4b4;
}
.ScrollBox__listItem-note {
	position: relative;
	top: 2px;
	display: inline-block;
	padding-left: 7px;
	width: 165px;
	font-size: 13px;
	color: #777;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.ScrollBox__listItem .bicon-holiday {
	position: relative;
	top: 1px;
	margin-right: 3px;
	vertical-align: baseline;
	background-position: bottom;
}