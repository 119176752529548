.CalendarGrid { 
	box-sizing: border-box;
	display: inline-block;
}

.CalendarGrid__body {
	box-sizing: border-box;
	padding: 0 15px 8px;
}

.CalendarGrid__row {}

.CalendarGrid__cell {
	display: inline-block;
	font-size: 16px;
	line-height: 28px;
	height: 30px;
	margin: 0px;
	padding: 0px;
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 38px;
}

.CalendarGrid__cell:before {
	border: 1px solid transparent;
	bottom: 0;
	box-sizing: border-box;
	content: '';
	display: inline-block;
	left: -1px;
	position: absolute;
	right: 0;
	top: -1px;
}

.CalendarGrid__cell--inRange {
	background-color: rgba(251, 127, 73, 0.15);
}

.CalendarGrid__cell--inRange:before {
	border-color: rgb(254, 194, 170);
	border-left-color: rgb(239, 214, 203);
}

.CalendarGrid__cell--inRangeLeading {
	border-left-color: rgb(254, 194, 170);
}

.CalendarGrid__cell--now {
	background-color: #fff;
}

.CalendarGrid__cell--now:before {
	border: solid #000000 1px;
	z-index: 1;
}

.CalendarGrid__cell--withValue {
	cursor: pointer;
}

.CalendarGrid__cell--withValue:not(.CalendarGrid__cell--disabled):hover {
	color: #222;
	background-color: rgb(255, 255, 255);
	z-index: 2;
}

.CalendarGrid__cell--withValue:not(.CalendarGrid__cell--disabled):hover:before {
	border-color: rgb(252, 113, 56);
}

.CalendarGrid__cell--selected {
	background-color: rgb(251, 127, 73);
	color: rgb(255, 255, 255);
}

.CalendarGrid__cell--selected:before {
	border-color: rgb(233, 112, 59);
	z-index: 2;
}

.CalendarGrid__cell--disabled {
	color: #aaa;
	cursor: default;
}

.CalendarGrid__daysOfWeek {
	background: #f2f2f2;
	margin-bottom: 3px;
	padding-bottom: 10px;
	white-space: nowrap;
}

.CalendarGrid__dayOfWeek {
	color: #888888;
	display: inline-block;
	font-size: 12px;
	font-weight: 600;
	text-align: center;
	width: 38px;
}

.CalendarGridManager {
	display: inline-block;
}

.CalendarGridManager__grids {
	white-space: nowrap;
	display: flex;
}

.CalendarGridManager__grid {
	display: inline-block;
	flex-grow: 1;
	vertical-align: top;
}

.CalendarGridManager__grid:not(:first-child) {
	border-left: 1px solid rgba(0, 0, 0, 0.10);
}

.CalendarGridManager__gridHeader {
	align-items: center;
	box-sizing: border-box;
	background: #f2f2f2;
	display: flex;
	padding: 0 10px;
	height: 40px;
	width: 100%;
}

.CalendarGridManager__gridHeaderContent {
	flex-grow: 1;
	text-align: center;
}

.CalendarGridManager__gridHeaderDropdown ~ .CalendarGridManager__gridHeaderDropdown {
	margin-left: 5px;
	margin-right: 5px;
}

.CalendarGridManager__gridHeader--paddedLeft {
	padding-left: 32px;
}

.CalendarGridManager__gridHeader--paddedRight {
	padding-right: 32px;
}

.CalendarGridManager__gridTitle {
	color: #222;
	font-size: 16px;
	font-family: BhrHeaderFont, 'Trebuchet MS';
	font-weight: 600;
	line-height: normal;
}

.CalendarGridManager__previousButton,
.CalendarGridManager__nextButton {
	background: #f2f2f2;
	border: solid #bebebe 1px;
	border-radius: 2px;
	box-sizing: border-box;
	display: inline-block;
	height: 28px;
}

.CalendarGridManager__previousButton {
	padding: 7px 13px 5px 5px;
}

.CalendarGridManager__nextButton {
	padding: 7px 5px 5px 13px;  
}

.CalendarGridManager__previousButton--disabled,
.CalendarGridManager__nextButton--disabled{
	opacity: 0.3;
}
	
.CalendarGridManager__previousButton:before,
.CalendarGridManager__nextButton:before {
	content: '';
	border: solid transparent 6px;
	display: inline-block;
}

.CalendarGridManager__previousButton:before {
	border-right-color: #777;
}

.CalendarGridManager__nextButton:before {
	border-left-color: #777;
}

.CalendarGridManager__previousButton:hover:not(.CalendarGridManager__previousButton--disabled),
.CalendarGridManager__nextButton:hover:not(.CalendarGridManager__nextButton--disabled) {
	background-color: #fff;
	border-color: #949494;
	color: white;
	cursor: pointer;
}

.CalendarPicker__inputWrapper {
	position: relative;
	display: inline-block;
}

.CalendarPicker__input:disabled + .CalendarPicker__toggleButton {
	cursor: default;
	opacity: 0.5;
}

.CalendarPicker__toggleButton {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.CalendarPicker__popover {
	background: #ffffff;
	border: 1px solid #FC7138;
	border-radius: 2px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
	margin-top: 12px;
	+jade() {
		margin-top: 16px;
	}
}

.CalendarPicker__popover--topAnchored {
	margin-top: 0;
	margin-bottom: 12px;

	+jade() {
		margin-bottom: 16px;
	}
}

+jade() {
	.CalendarPicker__caret {
		position: absolute;
		display: inline-block;
		fill: fab-color(gray1);
		stroke: fab-color(theme);
		color: fab-color('theme-lightest35');
		top: -17px;

		&--topAnchored {
			bottom: -17px;
			fill: fab-color(white);
			transform: rotate(180deg);
			top: auto;
		}
	}
}


+legacy() {
	.CalendarPicker__caret,
	.CalendarPicker__caret:before {
		content: '';
		position: absolute;
		display: inline-block;
		border: solid transparent 9px;
	}
	.CalendarPicker__caret {
		top: -19px;
		left: 21px;
		z-index: 1;
		border-bottom-color: #bfbfbf;

		+jade() {
			border-bottom-color: fab-color(theme);
		}
	}

	.CalendarPicker__caret:before {
		top: -8px;
		left: -9px;
		border-bottom-color: #f2f2f2;
	}

	.CalendarPicker__caret--topAnchored {
		top: auto;
		bottom: -19px;
		left: 21px;
		border-bottom-color: transparent;
		border-top-color: #bfbfbf;
	}

	.CalendarPicker__caret--topAnchored:before {
		border-bottom-color: transparent;
		border-top-color: #fff;
		bottom: -8px;
		left: -9px;
		top: auto;
	}
}

