.TT_EmpMenu {
	&__status {
		display: none;

		&--selected {
			display: inline-block;
		}
	}

	&__subList {
		min-width: 166px;

		& li a {
			padding: 5px 0 3px;  // deliberate override
		}
	}

	&__li.disabled {
		font-weight: 600;
	}
	&__check {
		margin-right: 5px;
		position: relative;
		top: 2px;
	}
}

.TT_EmpMenu__li:not(.TT_EmpMenu__li--selected) {
	.TT_EmpMenu__check {
		opacity: 0;
	}
}


.TT_NoPayScheduleModal {
	max-width: 368px;
	padding: 5px 6px 0;
	text-align: center;

	&__icon {
		margin-bottom: 5px;
		color: $bhrColors.error1;
		fill: $bhrColors.gray6;
	}

	&__header {
		margin-bottom: 18px;
		line-height: 1.3;
		fab-color: danger;
	}

	&__instructions {
		color: $bhrColors.gray9;
	}
}
