/*------------------------------------*\
$BOX MODULE

styles used for generic box elements
\*------------------------------------*/

.Box {
	padding: 9px 0 2px;
}

.Box--brandedBar { padding: 0; }
.Box--brandedBar:before {
	content: '';
	display: block;
	height: 3px;
	width: 100%;
}
.Box--brandedBar--Thick:before { height: 5px; }
.Box--border { border: solid #cfcfcf 1px; }
.Box--border-LRB {
	border: solid #cdcdcd 1px;
	border-top: none;
}
.Box--border-LTR {
	border: solid #969696 1px;
	border-bottom: none;
}
.Box--border-w2 { border-width: 2px; }
.Box--bottom-divider { border-bottom: solid #d4d4d4 1px; }

.Box--callout {
	padding: 8px 9px 10px;
	background: #f3f3f3;
}

.Box--callout-med {	background: #e8e8e8; }

.Box--callout-info {
	border-radius: 3px;
	border: 1px solid transparent;
	padding: 18px;
	padding-left: 45px;
	position: relative;
}

.Box--callout-info:before,
.Box--callout-warn:before,
.Box--callout-error:before {
	content: "";
	display: block;
	position: absolute;
	width: 22px;
	height: 22px;
	top: 18px;
	left: 13px;
}

.Box--callout-info h3,
.Box--callout-warn h3,
.Box--callout-error h3 {
	color: #2e90d9;
	line-height: 1em;
	margin-bottom: 8px;
}

.Box--callout-info {
	background: #eff8ff;
	border-color: #57aae9;
}

.Box--callout-info:before {
	background: url("https://staticfe.bamboohr.com/resources/images/info.png");
}

.BhrForms .Box--callout-info,
.BhrForms .Box--callout-warn,
.BhrForms .Box--callout-error {
	margin: 24px 30px;
}


.Box--pad {
	padding: 30px 40px;
}

.Box--pad .fieldRow:first-of-type {
	margin-top: 0;
}

.Box--pad .fieldRow:first-of-type .inputIcons.flat {
	margin-top: 0;
}
.Box--pad .fieldRow:last-of-type {
	margin-bottom: 0;
}

.Box--filled { background: #fff; }
.Box--filled-light { background: #f3f3f3; }
.Box--filled-medium { background: #e8e8e8; }
.Box--filled-dark { background: #464646; }

.Box--inner-top-shadow { box-shadow: inset 0px 3px 3px -3px rgba(0,0,0,.25); }

.Box--noPad { padding: 0; }
.Box--tight {
	padding: 6px 12px 4px;
}
.Box--rounded { border-radius: 3px; }
.Box--rounded-6 { border-radius: 6px; }

.Box__header {
	line-height: 24px;
	margin-bottom: 8px;
}

.Box__header-actions > .btn {
	vertical-align: top;
}

.Box__icon {
	float: left;
}
.Box__icon + .Box__content {
	margin-left: 19px;
}

.Box__note {
	color: #afafaf;
	font-size: 15px;
}

.Box__note--small { font-size: 13px; }

.Box__title {
	color: #222;
	line-height: inherit;
	margin-top: 2px;
}
.Box--callout .Box__title { font-size: 16px; }
.Box__title--small { font-size: 13px; }

.Box__text--dark { color: #555; }
.Box__text--med { color: #777; }
