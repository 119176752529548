{featureToggleOn('jade')} {
	.bhrSearchInput {
		&__clearSearch {
			fab-fill: gray8;
			opacity: 0;
			position: absolute;
			visibility: hidden;
			right: 10px;
			top: 0;
			bottom: 0;
			display: flex;
			align-items: center;
		}
	}
}
