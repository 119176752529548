#floatyDiv {
	border-style: solid;
	border-color: rgba(0,0,0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100001;
	pointer-events: none;
}

.isViewAs {
	padding-top: 150px;
}

.ViewAs {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100001;
	box-shadow: 0 1px 8px rgba(0,0,0, 0.5);

	+legacy() {
		background: #3;
	}

	&,
	&__page {
		height: 150px;
	}

	&__page {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
