.BackupCodes {
	// .BackupCodes__wrapper
	&__wrapper {
		// @startCleanup encore
		margin-bottom: 33px;
		// @endCleanup encore
		position: relative;

		+encore() {
			margin-bottom: spacing(3);
		}
	}

	// .BackupCodes__list
	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		// @startCleanup encore
		border: 1px solid fab-color(gray4);
		padding: 35px;
		row-gap: 10px;
		// @endCleanup encore

		+encore() {
			border: 1px solid var(--gray2);
			row-gap: spacing(2);
			padding: spacing(4);
			padding-bottom: spacing(9.5);
			border-radius: 8px;
		}
	}

	// .BackupCodes__code
	&__code {
		fab-font-weight: semibold;
		text-align: center;
		// @startCleanup encore
		fab-text: h3;
		color: fab-color(gray9);
		// @endCleanup encore

		+encore() {
			fab-text: h5;
			color: var(--gray8);
		}

		&--used {
			text-decoration: line-through;
		}
	}

	// .BackupCodes__date
	&__date {
		display: block;
		fab-background-color: white;
		left: 50%;
		padding: 0 10px;
		position: absolute;
		transform: translateX(-50%);
		// @startCleanup encore
		fab-font-size: medium;
		fab-line-height: teenie;
		color: fab-color(gray9);
		top: calc(100% - 11px);
		// @endCleanup encore

		+encore() {
			top: calc(100% - 40px);
			fab-text: small;
			color: var(--gray7);
		}
	}
}
