.DataProcessingAgreeementAgreement {
	&__summary {
		fab-line-height: teenie;
		fab-font-size: teenie;
		fab-color: gray7;
		margin-top: 6px;
		margin-left: 24px;
		max-width: 441px;
	}
}
