/* all table styles */

/** from styles.css **/
/* TABLES AND FORMS OH MY
**NOTE - Whatever came from styles.css are all probably old styles
and will need to be deleted after the redesign - KB
--------------------------*/

#one-full table.edit-table, #one-col form table.tablewrap, #one-col form table.paylist, table.fuller, #one-full table.tablewrap {
	width: 100%;
}
table th.tops {
	vertical-align: top;
}
#one-col form table.paylist td {
	vertical-align: top;
	font-size: 11px;
	font-family: 'Source Sans Pro',Arial, Helvetica, sans-serif;
}
#one-col form table.paylist {
	margin: 0 0 5px;
}
#one-col form table.paylist td, #one-col form table.paylist th {
	margin: 0 0 5px;
	padding: 10px 5px 6px;
}
#one-full table.edit-table.paylist tr td {
	padding: 1px 0px;
}
#one-col form table.tablewrap th {
	font-size: 14px;
	vertical-align: middle;
}
#one-col form table.tablewrap td.tdwrap {
	margin: 0;
	padding: 0;
}
#one-col form table.tablewrap td p.add {
	width: auto;
	margin: 10px 0;
	float: left;
}
table th.thnormal {
	text-align: left;
	padding: 10px 0 0;
	font-weight: 600;
}
#one-col form table.tablewrap td.tdinput {
	width: 470px;
}
#one-col form table.tablewrap td ul:not(.chzn-results), #one-col form table.tablewrap td.tdinput ul:not(.chzn-results) li {
	margin: 0;
	padding: 0;
}
.tdinput {
	position: relative;
}
table.edit-table td.type-name {
	text-align: right;
	font-size: 12px;
}
table.edit-table td.check-align {
	text-align: center;
}
table.edit-table td, table.edit-table th, table.list td, table.list th {
	border-bottom: 1px solid #E8E8E1;
	padding: 6px 5px 6px 5px;
}
table.edit-table.noline td, table.edit-table.noline th {
	border: none;
}
form table.paylist tr th, form table.paylist tr td {
	padding: 0;
}
table.paylist th div {
	padding: 6px 5px 6px 10px;
	white-space:nowrap;
	position: relative;
}
table.paylist td div, table.paylist td p {
	padding: 8px 5px 8px 10px;
	position: relative;
	margin: 0;
	line-height: 1;
}
table.paylist td div { white-space:nowrap; }
table.edit-table th {
	font-weight: 600;
}
table.fuller th {
	font-weight: 600;
	text-align: center;
}

table.add-item th {
	text-align: left;
	padding: 0;
	font-weight: 600;
	color: #666666;
}
table.add-item td {
	padding-bottom: 10px;
}
td.add-link a {
	display:block;
	padding: 2px 0 2px 20px;
	background: transparent url('https://staticfe.bamboohr.com/resources/images/icon_add_16.gif') no-repeat 0 50%;
}
#one-col form table.add-item {
	margin: 0;
}
#one-col form #custom-list table th {
	text-align: left;
	padding:6px 10px 5px 0;
}
.one-col-wrap {
	margin: 5px 0 10px 10px;
}
.one-col-wrap p {
	margin: 0 0 10px;
}

#custom-table-form .deleteField {
	background: url('https://staticfe.bamboohr.com/resources/images/icon_delete_sprite.png') top left no-repeat;
	display:block;
	width:19px;
	height:19px;
	margin-bottom: -7px;
	margin-left: 5px;
}

#custom-table-form .deleteField:hover {
  background-position: bottom left;
}

#inside-content table h3 a {
	font-size: 14px;
	font-weight: normal
}

table.edit-table td a.context,
table.employee-list td a.context,
table.list td a.context {
	float: right;
}

table.recent tr td {
	margin: 0;
	font-size: 12px;
}
table.recent td.date {
	padding: 5px 2px 0px 0px;
	font-weight: 600;
	color: #555555;
}
table.recent td.date div {
	margin: 0px; padding: 0px 0px 4px 10px; width: 175px;
	border-bottom: 1px solid #dfdfdf;
}
table.recent td.first {
	padding: 0px;
}
table.recent td.first table.employeed td, table.employeed td.first {
	padding: 0;
	border-bottom: none;
}

table.recent tr td div {
	border-bottom: 1px solid #dfdfdf;
	margin: 0px 15px;
	padding: 6px 0px 6px 10px;
	position: relative;
}
table.recent tr td div span.details {
	position: absolute;
	right: 0px;
	color: #AAAAAA;
}
table tr td.widget-pagination {
	padding: 14px 0px 0px 18px;
	border-bottom: none !important;
}

/*-------------------------------------------------
 CORE TABLES AND TABLE FORMS
-------------------------------------------------*/

table {
	width: 100%;
}
table th {
	font-weight: normal;
}
th, td {
	padding:7px 10px 5px 0;
	font-size: 14px;
}
caption, th, td {
	text-align:left;
}
#table-top, #exports {
	width: 100%;
	float: left;
	padding: 5px 0 0;
}
#inside-content #table-top h3 {
	float: left;
	width: auto;
}
#table-top.ee-search {
	padding: 5px 0;
}
#table-top .numbers {
	width: auto;
	float: right;
}
#table-top .numbers p {
	margin: 2px 5px 0 0;
	color: #666666;
}
#table-top .numbers-left {
	width: auto;
	float: left;
}
#table-top .numbers-left p {
	margin: 2px 5px 0 0;
	color: #666666;
}
table tr td.actionLink {
	text-align: center;
}
table.edit-table tr td.actionLink {
	text-align: left;
	white-space: nowrap;
	padding:7px 5px 5px 5px;
	width: 20px;
}
table.edit-table tr td.actionLink a {
	padding: 0 2px;
}
table.employee-list tr.hover td,
table.edit-table tr.hover td,
table.list tr.hover td,
table.employee-list tr.context-active td,
table.edit-table tr.context-active td,
table.list tr.context-active td {
	background-color: #FFFFCC;
}
table.employee-list tr.hover td a.context,
table.edit-table tr.hover td a.context,
table.list tr.hover td a.context {
	background-position: 0px -11px;
}
#one-full table {
	margin: 0;
	width: 930px;
}
#one-full table.employee-list, #one-full table.list, table.list {
	text-align: left;
	width: 100%;
	margin-top: 5px;
	background: #ffffff;
}
table.employee-list th, table.employee-list td,
table.list th, table.list td {
	padding: 8px 5px 8px;
	text-align: left;
}
table.employee-list td, table.list td, table.edit-table td {
	font-size: 14px;
	padding: 8px 5px 8px;
	border-top: 1px solid #e5e5e5;
}
table.edit-table td {
	padding: 8px 5px 8px;
}
table.employee-list th, table.list th, table.edit-table th {
	background-color: #ececec;
	vertical-align: bottom;
	text-align: left;
	font-weight: 600;
}
table.employee-list th.sort-none,
table.employee-list th.sort-ascending,
table.employee-list th.sort-descending {
	padding: 0;
}
table.employee-list th.sort-none a,
table.employee-list th.sort-ascending a,
table.employee-list th.sort-descending a {
	padding: 12px 1px 12px 14px;
}
table th a {
	cursor: pointer;
}

table.employee-list th.group-header, table.employee-list th.group-header:hover  {
	font-size: 14px;
	border-bottom: 1px solid #dfdfdf;
	/*border-top: 1px solid #dfdfdf;*/
	padding: 9px 15px 7px 14px;
	background-image: -moz-linear-gradient(top, #EBEBEB, #F1F1F1);
	background-image: -ms-linear-gradient(top, #EBEBEB, #F1F1F1);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#EBEBEB), to(#F1F1F1));
	background-image: -webkit-linear-gradient(top, #EBEBEB, #F1F1F1);
	background-image: -o-linear-gradient(top, #EBEBEB, #F1F1F1);
	background-image: linear-gradient(top, #EBEBEB, #F1F1F1);
	background-repeat: repeat-x;
	background-position: 0 0px;
	color: #777;
}
table.employee-list tr:first-of-type.group-header-row th {
	border-top: 1px solid #AFAFAF;
}
table.employee-list thead + tbody tr:first-of-type.group-header-row th {
	border-top: none;
}
table.employee-list tbody:not(:last-child) tr:last-child td {
	border-bottom: none;
}
#sortedby {
	width:82%;
	float:left;
}
#sortedby a {
	display: inline-block;
	padding-right: 17px;
	background-color: #fff;
	margin-left: 15px;
	margin-bottom: 15px;
}
table.report-list {
	width: 100%;
}
.seventyfive form table td {
	width: 75%;
}
form table td.formBottom {
	border-top: 1px solid #E8E8E1;
	padding-top: 12px;
}
#bp-lastChanged {
	margin-left: 15px;
	font-size: 11px;
	color: #666;
}
table td input.input-highlight {
	background-color: #FFFFCC;
	border: 1px solid #dfdfdf;
}

table.announcements td {
	/** depricated
	font-family: Arial, Helvetica, sans-serif;*/
	padding: 10px 5px 6px;
	font-size: 12px;
}
table.announcements a {
	font-size: 14px;
}

.table-mod {
	width: auto;
	position: relative;
	padding: 0 10px;
	overflow-y: scroll;
	height: 138px;
	border-bottom: 1px solid #dfdfdf;
}
.table-mod-noscroll {
	overflow-y: hidden;
	height: auto;
	border-bottom: none;
}
.table-mod table {
	width: 253px;
	background: #E2E2E2;
}
.table-mod.full-width table {
	width: 590px;
}
.table-mod-noscroll table {
	width: 270px;
}
.table-mod table tr {
	background-color: #ffffff;
}
table tr {
	background-color: #ffffff;
}

/* Remove this with other non Jade code */
body:not([data-feature-toggles~="jade"]) table tr.alt,
body:not([data-feature-toggles~="jade"]) li.alt,
body:not([data-feature-toggles~="jade"]) table tr.odd {
	background-color: #FBFBFB;
}
body:not([data-feature-toggles~="jade"]) table tr.even{
	background-color: #ffffff;
}
.table-mod table td, .table-mod table th {
	border-bottom: 1px solid #DFDFDF;
	vertical-align: middle;
	font-size: 12px;
	padding: 5px 5px 3px;
}
.table-mod table td.celdates {
	width: 45px;
	padding: 5px 0 3px 5px;
}
.table-mod table td.username {
	width: 145px;
}
.table-mod table td.date {
	width: 50px;
}
table.sumlognote th, table.sumlognote td {
	padding: 5px 7px 3px;
}
table.sumlognote th.s-date {
	width: 80px;
}
table.sumlognote th.e-date {
	width: 90px;
}
table.sumlognote th.checked {
	width: 110px;
}
table.sumlognote th.w-date {
	width: 65px;
}
#notes table.sumlognote td.date {
	text-align: right;
}
table.sumlognote th.event {
	width: 100px;
}
.table-mod table td.icon-img {
	padding:6px 0 2px 5px;
	width: 16px;
}
.table-mod table th {
	font-weight: 600;
	padding: 7px 5px 3px 3px;
	color: #555;
}

/*
DROPDOWN ON TABLE ROW
-----------------------------*/
#one-col form ul#benefits-context-menu, ul#benefits-context-menu, #context-menu {
	margin: 0;
	background-clip: padding-box;
	background-color: #FFFFFF;
	border-color: rgba(0, 0, 0, 0.2);
	border-style: solid;
	border-width: 1px;
	border-radius: 5px 5px 5px 5px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	list-style: none outside none;
	min-width: 168px;
	padding: 4px 0 7px 0;
}
#context-menu {
	text-align: left;
	position: relative;
	z-index: 99;
}
form table.tablewrap td ul#benefits-context-menu li, #context-menu li {
	float: none;
	width: 168px;
	margin: 0;
	padding: 0px;
	line-height: normal;
	vertical-align: middle;
	border:none;
}
#benefits-context-menu li a, #context-menu li a{
	float: none;
	border: none;
	line-height: normal;
	margin: 0;
	padding: 4px 7px;
	background: #fff;
	text-align:left;
	width: 91%;
	display: inline-block;
	color: #444;
}
#benefits-context-menu li a:hover, #context-menu li a:hover {
	background: #EEEEEE;
	color: #222;
	text-decoration:none;
}
#one-col form table.benefits-table {
	margin: 0;
	border-bottom: 1px solid #dfdfdf;
}
#one-col form table.benefits-table td, #one-col table.benefits-table td {
	font-size: 12px;
	font-family: 'Source Sans Pro',Arial, Helvetica, sans-serif;
	padding: 7px 5px 5px 0;
}
.inlineEdit-hover {
	background-color: #FFFFD3;
}
.inlineEditInput {
	margin: 2px 2px 6px 2px;
}

/*---------------------
Common form layout stuff
---------------------*/

div#body-content form table { width: inherit; }
div#body-content form table th.field { vertical-align: top; padding-top: 12px; }
div#body-content form table #inner-form table { margin: 0; padding: 0; width: inherit; }

/** /tables and forms */

#inside-content table td.h3s {
	padding: 0;
	margin: 0;
}
#inside-content table td.h3s h3 {
	line-height: normal;
	font-family: 'Source Sans Pro',Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: 600;
	padding: 7px 0 4px;
	border-bottom:1px solid #E8E8E1;
	position: relative;
}
/* /styles.css */

/* global_styles.css */
/*************** TABLESORTER TABLES ****************/
.ts-list, .ts-list thead {
	border-collapse: separate;
}
.hasFixedHeader .ts-list tbody {
	border-top: none;
}
.ts-list thead th{
	border-top: solid 1px #afafaf;
}
.ts-list thead:not(:last-child) th, .ts-list:last-child thead:last-child th { border-bottom:solid #afafaf 1px; }

.ts-list th,
.ts-list th.noSort:hover {
	cursor:pointer;
	color: #555555;
	padding: 12px 9px 12px 15px;
	font-size: 14px;
	font-weight: 600;
	background: #ececec;
}

.ts-list:not(.noSort) th:not(.noSort):hover {
	background: #dddddd;
	box-shadow: inset -1px 0 0 0 #c8c8c8, inset 1px 0 0 0 #c8c8c8;
}
.ts-list th.noSort:hover, .ts-list.noSort th:hover {
	cursor:inherit;
}
.ts-list th a { color: #555555; }
.ts-list th a:hover { text-decoration:none; }
.ts-list .day {
	font-size: 13px;
	color: #777777;
}
.ts-list .date {
	font-size: 15px;
	font-weight: 600;
}
.tableContainer .oldTbl .ts-list tr:first-child td {
	border-top: none;
}
.tableContainer .newTbl table {
	border-bottom: 1px solid #AFAFAF;
}
.tableContainer .oldTbl .ts-list {
	border: none;
}
.ts-list td {
	border-bottom: solid 1px #d9d9d9;
	padding: 11px 10px 9px 15px;
	font-size: 15px;
	color: #222;
	vertical-align:top;
	line-height:17px;
}
.ts-list tr.empty td { color: #9b9b9b; }
.ts-list.employee-list td {
	border-top: 1px solid transparent;
}
.ts-list td.edit, .ts-list th.edit {
	text-align: right;
}
tr th.withIndent, .ts-list tr th.withIndent:hover, tr td.withIndent, .ts-list tr td.withIndent:hover {
	padding-left: 30px;
}
tr.category td {
	padding-top: 5px;
	padding-bottom: 4px;
	padding-left: 10px;
	background: #f6f6f6;
}
tr.category td label {
	font-weight: 600;
	color: #686868;
	font-size: 14px;
}
/** moved from buttons.css ***/
th.sort-none a, th.sort-ascending a, th.sort-descending a {
	display: block;
}

th.sort-ascending a:after, th.sort-descending a:after {
	content: '';
	display: inline-block;
	margin-left: 6px;
	margin-right: 0;
	height: 7px;
	width: 8px;
	vertical-align: middle;
}
th.sort-ascending a:after {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/sort-ascending.png") center 0 no-repeat;
}
th.sort-descending a:after {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/sort-descending.png") center 0 no-repeat;
}

.ts-list-row-icons tr td:last-child { text-align:right; min-width: 45px;}
.ts-list-row-icons tr td:last-child *[class*="bicon"],
.ts-list-row-icons tr td:last-child .dropdown-menu {
	visibility:hidden; /* instead of display none show row doesn't "bounce" on hover */
}
.ts-list-row-icons tr:hover td:last-child *[class*="bicon"],
.ts-list-row-icons tr:hover td:last-child .dropdown-menu {
	visibility:visible;
}

.ts-list tr.disabled td { color: #999999; }
.ts-list tr.disabled:hover { background-color: #ffffff }
.ts-list tr.disabled td:last-child *[class*="bicon"] { visibility: hidden }

/** scrollable table rows section **/
.bhrScrollTable .fixedHeader th {
	border-bottom: solid #b1b1b1 1px;
	color: #555555;
	font-size: 13px;
	font-weight: 600;

	box-shadow: inset 0px 4px 3px -22px rgba(0,0,0,.75);

	background-color:#ececec;
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ececec), to(#f8f8f8));
	background: -webkit-linear-gradient(top, #ececec, #f8f8f8 bottom);
	background: -moz-linear-gradient(top, #ececec 0%, #f8f8f8 100%);
	background: -ms-linear-gradient(top, #ececec, #f8f8f8 bottom);
	background: -o-linear-gradient(top, #ececec, #f8f8f8 bottom);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ececec', endColorstr='#f8f8f8');
	background-image: -ms-linear-gradient(top bottom, #ececec 0%, #f8f8f8 100%);
}
.bhrScrollTable .scrollContent { padding: 0px; }
.bhrScrollTable .scrollBody {
	display: block;
	width: 100%;
	height: 140px;
	padding: 0px;
	overflow: auto;
}
.bhrScrollTable .scrollBody table {
	width: 100%;
}
.bhrScrollTable th {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.bhrScrollTable th,
.scrollBody td {
	padding: 7px 15px 4px;
}
.bhrScrollTable tr:not(:last-of-type) > td { border-bottom: solid #d9d9d9 1px; }

/*
 * $tableList
 * styles for tables holding records (replaces ts-list)
*/

.tableList th, .tableList td { box-sizing: border-box; }
.tableList__cell--aRight { text-align: right; }

/* column widths */
.tableList__col1 { width: auto; }
.tableList__col2 { width: auto; }
.tableList__col3 { width: auto; }
.tableList__col4 { width: auto; }
.tableList__col5 { width: auto; }
.tableList__col6 { width: auto; }
.tableList__col7 { width: auto; }

/* tableList heading row*/
.tableList__heading {
	padding: 12px 10px 12px 14px;
	font-weight: 600;
	color: #555;
	white-space: nowrap;
	border-top: solid #afafaf 1px;
	border-bottom: solid #afafaf 1px;

	background: #ececec;
}

.tableList__heading:not(.tableList__heading--noSort):hover {
	background: #dddddd;
	box-shadow: inset -1px 0 0 0 #c8c8c8, inset 1px 0 0 0 #c8c8c8;
}
.tableList__heading:not(.tableList__heading--noSort):hover {
	cursor: pointer;
}


.tableList__heading--sub {
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 15px;
	border-right: none;
	border-top: none;
	border-bottom: solid #d9d9d9 1px;

	background-color:#ebebeb;
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ebebeb), to(#f1f1f1));
	background: -webkit-linear-gradient(top, #ebebeb, #f1f1f1 bottom);
	background: -moz-linear-gradient(top, #ebebeb 0%, #f1f1f1 100%);
	background: -ms-linear-gradient(top, #ebebeb, #f1f1f1 bottom);
	background: -o-linear-gradient(top, #ebebeb, #f1f1f1 bottom);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebebeb', endColorstr='#f1f1f1');
	background-image: -ms-linear-gradient(top bottom, #ebebeb 0%, #f1f1f1 100%);
}
.tableList__heading--sub .branded-icon, .tableList__heading--sub .inactiveColor { margin-right: 8px; }
.tableList__heading--sub span {
	line-height: 16px;
	font-size: 14px;
	font-weight: 600;
}

/* tableList record rows */
.tableList__record {
	border-bottom: solid #d9d9d9 1px;
}
.tableList__record > td {
	padding: 8px 10px 9px 15px;
	height: 30px;
	line-height: 1.2em;
}

.tableList__record > td:first-child > .bicon-exclaim {
	margin-top: 1px;
	margin-left: 2px;
}
.tableList__record > td:last-child > .inline-bicon-edit {
	margin-top: -1px;
	margin-left: 3px;
}
.tableList__record--old > td { padding-bottom: 6px; }

/* tableList footer rows */
.tableList__recordFooter td {
	padding-top: 6px;
	padding-bottom: 7px;
	text-align: right;
	background: #fcfcfc;
	vertical-align: middle;
	border-bottom: solid #d9d9d9 1px;
}

.tableList__recordTotal { min-height: 32px; }
.tableList__recordTotal strong {
	display: block;
	margin-top: 2px;
}
.tableList__recordTotal strong:only-child {
	margin-top: 0;
	padding-top: 10px;
}
.tableList__recordTotal strong + strong { margin-top: 10px; }

.tableList__recordTotals {
	padding: 5px 32px 4px 0;
	border-right: solid #b0b0b0 1px;
}
.tableList__recordTotals--days { border: none; }

.tableList__recordTotals li {
	display: inline-block;
	padding: 1px 16px 0;
}
.tableList__recordTotals li:last-child { padding-right: 0; }
.tableList__recordTotals li:not(:last-child) { border-right: solid #e6e6e6 1px; }

/* styles for new tablesorter version */
.tableList__heading > .tablesorter-header-inner {
	display: inline-block;
	vertical-align: baseline;
}
.tableList__heading:not(.tableList__heading--noSort).tablesorter-headerAsc:after {
	content: '';
	display: inline-block;
	border: solid transparent 4px;
	border-bottom-color: #6b6b6b;
	vertical-align: middle;
	margin-left: 7px;
	position: relative;
	top: -3px;
}
.tableList__heading:not(.tableList__heading--noSort).tablesorter-headerDesc:after {
	content: '';
	display: inline-block;
	border: solid transparent 4px;
	border-top-color: #6b6b6b;
	vertical-align: bottom;
	margin-left: 7px;
	position: relative;
	top: -1px;
}
