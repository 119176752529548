.Label {
	display: inline-block;
	padding: 5px 7px;
	font-size: 13px;
	font-weight: 700;
	color: #af;
	box-sizing: border-box;

	+legacy() {
		background: #df;
		border-radius: 3px;
		text-transform: uppercase;
	}

	+jade() {
		fab-color: gray8;
		fab-font-size: small;
		fab-font-weight: heavy;
		fab-line-height: teenie;
		border-bottom: 1px solid fab-color(gray3);
		padding: 0 0 4px;
		text-transform: capitalize;
		width: 100%;
	}

	&--primary {
		color: #fff;

		+jade() {
			fab-color: theme;
		}
	}

}
