/* Svg Icons */

.BhrSvg {
	position: relative;
	display: inline-block;

	// Icons
	&--email {
		background-image: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTYgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAsMS44NDYxNTM4NSBMMCwxMS4wNzY5MjMxIEMwLDExLjQ2MjUwNCAwLjU3NTM2OTg3MywxMiAwLjk0MTE3NjQ3MSwxMiBMMTUuMDU4ODIzNSwxMiBDMTUuNDI0NjMwMSwxMiAxNiwxMS40NjI1MDQgMTYsMTEuMDc2OTIzMSBMMTYsMS44NDYxNTM4NSBMOC40NzA1ODgyNCw4LjMwNzY5MjMxIEwwLDEuODQ2MTUzODUgTDAsMS44NDYxNTM4NSBaIiBmaWxsPSIjODU4NTg1Ij48L3BhdGg+CjxwYXRoIGQ9Ik0wLjk0MTE3NjQ3MSwwIEMwLjU3NTM2OTg3MywwIDAsMC41MzQzMTk5MDQgMCwwLjkyMzA3NjkyMyBMOC40NzA1ODgyNCw2LjQ2MTUzODQ2IEwxNiwwLjkyMzA3NjkyMyBDMTYsMC41MzQzMTk5MDQgMTUuNDI0NjMwMSwwIDE1LjA1ODgyMzUsMCBMMC45NDExNzY0NzEsMCBMMC45NDExNzY0NzEsMCBaIiBmaWxsPSIjODU4NTg1Ij48L3BhdGg+Cjwvc3ZnPg==");
		width: 16px;
		height: 12px;
	}

	&--warning {

		&--small {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxM3B4IiB2aWV3Qm94PSIwIDAgMTUgMTMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxwb2x5Z29uIGlkPSJQb2x5Z29uLTEiIHN0cm9rZT0iI0RGQTAwQiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBmaWxsPSIjRUJBQjE1IiBwb2ludHM9IjcuNDg4NzgzOTcgMSAxMy44Mzg3ODM5IDEyIDEuMTM4Nzg0MDcgMTIgIj48L3BvbHlnb24+ICAgIDxwYXRoIGQ9Ik02LjcwMDAxMjIxLDExIEw2LjcwMDAxMjIxLDEwIEw4LjI5OTk4Nzc5LDEwIEw4LjI5OTk4Nzc5LDExIEw2LjcwMDAxMjIxLDExIEw2LjcwMDAxMjIxLDExIFogTTcsOSBMNi42MDAwMDYxLDUuMjE4NzUgTDYuNjAwMDA2MSw0IEw4LjM5OTk5MzksNCBMOC4zOTk5OTM5LDUuMjE4NzUgTDgsOSBMNyw5IEw3LDkgWiIgZmlsbD0iI0ZGRkZGRiI+PC9wYXRoPjwvc3ZnPg==");
			width: 15px;
			height: 13px;
		}
	}
}

// Dropdowns
.dropdown-menu {

	.BhrSvg {
		margin-right: 9px;

		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-top: 3px solid #90;
			top: 5px;
			right: -9px;
		}

		&--email {
			margin-top: 6px;
			margin-left: 3px;
		}
	}

	&.disabled {

		.BhrSvg {

			&:after {
				border-top-color: #c3;
			}

			&--email {
				background-image: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTYgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0wLDEuODQ2MTUzODUgTDAsMTEuMDc2OTIzMSBDMCwxMS40NjI1MDQgMC41NzUzNjk4NzMsMTIgMC45NDExNzY0NzEsMTIgTDE1LjA1ODgyMzUsMTIgQzE1LjQyNDYzMDEsMTIgMTYsMTEuNDYyNTA0IDE2LDExLjA3NjkyMzEgTDE2LDEuODQ2MTUzODUgTDguNDcwNTg4MjQsOC4zMDc2OTIzMSBMMCwxLjg0NjE1Mzg1IEwwLDEuODQ2MTUzODUgWiIgZmlsbD0iI2QwZDBkMCI+PC9wYXRoPg0KPHBhdGggZD0iTTAuOTQxMTc2NDcxLDAgQzAuNTc1MzY5ODczLDAgMCwwLjUzNDMxOTkwNCAwLDAuOTIzMDc2OTIzIEw4LjQ3MDU4ODI0LDYuNDYxNTM4NDYgTDE2LDAuOTIzMDc2OTIzIEMxNiwwLjUzNDMxOTkwNCAxNS40MjQ2MzAxLDAgMTUuMDU4ODIzNSwwIEwwLjk0MTE3NjQ3MSwwIEwwLjk0MTE3NjQ3MSwwIFoiIGZpbGw9IiNkMGQwZDAiPjwvcGF0aD4NCjwvc3ZnPg==");
			}
		}

	}
}