@require '~perfect-scrollbar/dist/css/perfect-scrollbar.css';

$flexItem = @block {
	display: flex;
	padding: 0 0 0 12px !important;
}

#helpLinks {
	width: 300px;
}

{featureToggleOn("jade")} {
	.HelpCenter {
		// .HelpCenterListItem
		&ListItem {
			//.HelpCenterListItem--hide
			&--hide {
				display: none;
			}
		}
		// .HelpCenter__resultList
		&__resultList {
			box-sizing: border-box;
			list-style-position: inside;
			margin: 0;
			height: 250px;
			padding: 6px 0;
			position: relative;
		}
	}
}

{featureToggleOff("jade")} {
	.HelpCenter {
		// .HelpCenterListItem
		&ListItem {
			align-items: center;
			padding: 0 !important;
			min-height: 26px !important;
			// .HelpCenterListItem:hover
			&:hover {
				background-color: $bhrColors.gray4;
			}
			// .HelpCenterListItem__header
			&__header {
				border-bottom: 1px solid $bhrColors.gray6;
				padding: 12px 10px 10px;
			}
			// .HelpCenterListItem__icon
			&__icon {
				margin-right: 8px;
				position: relative;
			}
			// .HelpCenterListItem__link
			&__link {
				line-height: 16px !important;
				padding: 0 !important;
				max-width: 235px !important;
			}
			// .HelpCenterListItem--bottom
			&--bottom {
				{$flexItem};
				// .HelpCenterListItem--bottom .HelpCenterListItem__icon
				.HelpCenterListItem__icon {
					fill: $bhrColors.gray9;
				}
				// .HelpCenterListItem--bottom:hover .HelpCenterListItem__icon
				&:hover .HelpCenterListItem__icon {
					fill: $bhrColors.gray10;
				}
			}
			//.HelpCenterListItem--hide
			&--hide {
				display: none;
			}
			// .HelpCenterListItem--clean:hover
			&--clean:hover {
				cursor: default !important;
				background-color: #f !important;
			}
			// .HelpCenterListItem--empty
			&--empty {
				color: $bhrColors.gray6;
				display: flex;
				fill: $bhrColors.gray5;
				flex-direction: column;
				height: 100%;
				justify-content: center;
			}
			// .HelpCenterListItem--pendo
			&--pendo {
				{$flexItem};
				// .HelpCenterListItem--pendo .HelpCenterListItem__icon
				.HelpCenterListItem__icon {
					fill: #00B6DC;
					top: 1px;
					// .HelpCenterListItem--pendo .HelpCenterListItem__icon--norm
					&--norm {
						display: block;
					}
					// .HelpCenterListItem--pendo .HelpCenterListItem__icon--play
					&--play {
						display: none;
					}
				}
				// .HelpCenterListItem--pendo .HelpCenterListItem__link
				.HelpCenterListItem__link {
					color: $bhrColors.gray10 !important;
					// .HelpCenterListItem--pendo .HelpCenterListItem__link:focus
					&:focus {
						color: $bhrColors.gray12 !important;
					}
				}
				// .HelpCenterListItem--pendo:hover .HelpCenterListItem__icon
				&:hover {
					.HelpCenterListItem__icon {
						fill: #00B6DC;
						// .HelpCenterListItem--pendo:hover .HelpCenterListItem__icon--norm
						&--norm {
							display: none;
						}
						// .HelpCenterListItem--pendo:hover .HelpCenterListItem__icon--play
						&--play {
							display: block;
						}
					}
					// .HelpCenterListItem--pendo:hover .HelpCenterListItem__link
					.HelpCenterListItem__link {
						color: $bhrColors.gray12 !important;
					}
				}
			}
			// .HelpCenterListItem--zendesk
			&--zendesk {
				{$flexItem};
				align-items: flex-start;
				border-top: 1px solid $bhrColors.gray6 !important;
				// .HelpCenterListItem--zendesk .HelpCenterListItem__description
				.HelpCenterListItem__description {
					color: $bhrColors.gray10;
					font-size: 11px;
					line-height: 16px;
					margin-bottom: 5px;
					// .HelpCenterListItem--zendesk .HelpCenterListItem__description::first-letter
					&::first-letter {
						text-transform: capitalize;
					}

				}
				// .HelpCenterListItem--zendesk .HelpCenterListItem__icon
				.HelpCenterListItem__icon {
					fill: $bhrColors.gray7;
					top: 12px;
				}
				// .HelpCenterListItem--zendesk .HelpCenterListItem__link
				.HelpCenterListItem__link {
					display: flex;
					flex-direction: column;
					white-space: normal;
					// .HelpCenterListItem--zendesk .HelpCenterListItem__linkText
					&Text {
						font-size: 14px;
						font-weight: 600;
						margin: 9px 0 4px;
					}
					&:focus .HelpCenterListItem__linkText {
						color: #0E77C6;
					}
				}
				// .HelpCenterListItem--zendesk:hover
				&:hover {
					font-weight: normal !important;
					// .HelpCenterListItem--zendesk:hover .HelpCenterListItem__link
					.HelpCenterListItem__link {
						font-weight: normal !important;
						// .HelpCenterListItem--zendesk:hover .HelpCenterListItem__linkText
						&Text {
							color: #0E77C6;
						}
					}
				}
			}
		}
		// .HelpCenter__prompt
		&__prompt {
			font-size: 16px;
			font-weight: 600;
			line-height: 19px;
		}
		// .HelpCenter__searchBox
		&__searchBox {
			margin-top: 5px;
			width: 100%;
			&::placeholder {
				color: $bhrColors.gray7;
			}
		}
		// .HelpCenter__resultList
		&__resultList {
			border-bottom: 1px solid $bhrColors.gray6;
			list-style-position: inside;
			margin: 0;
			height: 234px;
			padding: 0;
			position: relative;
		}
	}
}
