.ControlledCurrency__Menu--wrapper {
    display: flex;
    // 5px less than the padding on input so parent wrapper maintains proper width
    margin-right: -40px;
    transform: translateX(-100%);

    & div {
        display: flex !important;
    }

    &:after {
        content: attr(data-currency-code);
        padding: 0 7px;
        fab-font-size(teenie);
        border-right: 1px solid transparent;
        box-sizing: border-box;
        font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    }

    .CurrencyPicker__button--disabled {
        border-color: transparent !important; // restore fab border color over BHR
        border-left-color: fab-color(gray3) !important; // restore fab border color over BHR
    }
}
