/*------------------------------------*\
$Manage Nav Module

styles used for Global Manage Nav
\*------------------------------------*/

#manage-nav {
	 bottom: -6px;
	 line-height: 1em;
	 float: none;
	 font-size: 11px;
	 position: absolute;
	 margin: 0;
	 right: 0;
	 text-align: left;
	 width: auto;

	 ul {
		&.down-list {
			&,
			/.greenhouse .headerUserInfo .down-list {
				background-color: #fff;
				border: solid #808080 2px;
				border-radius: 3px;
				box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
				left: auto;
				margin: 0;
				min-width: 185px;
				padding: 2px 0;
				position: absolute;
				left: -13px;
				text-align: left;
				top: 37px;
				z-index: 1000;

				&.edge, &.anchor-edge {
					left: auto;
					right: -12px;

					&:before {
						left: auto;
						right: 11px;
					}
				}
			}

			&:before {
				background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2220px%22%20height%3D%229px%22%20viewBox%3D%220%200%2020%209%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%3Cpolygon%20fill%3D%22%23FFFFFF%22%20points%3D%2210.0202942%202.69586182%2017.0467529%209%202.99383545%209%20%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M10.0003662%2C2.71038818%20L17.0108545%2C8.99932861%20L20.0007324%2C8.99932861%20L10.0003662%2C0%20L0%2C8.99932861%20L2.98987796%2C8.99932861%20L10.0003662%2C2.71038818%20Z%22%20fill%3D%22%23808080%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
				content: '';
				display: inline-block;
				height: 9px;
				left: 12px;
				position: absolute;
				top: -9px;
				width: 20px;
				right: auto;
			}

			&#helpLinks:before {
				background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2220px%22%20height%3D%229px%22%20viewBox%3D%220%200%2020%209%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%3Cpolygon%20fill%3D%22%23EBEBEB%22%20points%3D%2210.0202942%202.69586182%2017.0467529%209%202.99383545%209%20%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M10.0003662%2C2.71038818%20L17.0108545%2C8.99932861%20L20.0007324%2C8.99932861%20L10.0003662%2C0%20L0%2C8.99932861%20L2.98987796%2C8.99932861%20L10.0003662%2C2.71038818%20Z%22%20fill%3D%22%23808080%22%3E%3C%2Fpath%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A');
			}

			& > li {
				padding: 0;

				&.down-list {
						&__section {
							.down-list__section {
								&Icon {
									fill: #5B5B5B;
									float: left;
									margin-left: 9px;
								}

								&Link {
									display: block;
									overflow: hidden;
									width: auto;
								}
							}

							a {
								color: #5B5B5B;
								font-size: 15px;
								padding: 0 0 0 8px;
								vertical-align: middle;
							}
						}

						&__subSection {
							&-name {
								max-width: 75%;
							}

							span {
								padding: 0;
							}

							a {
								box-sizing: border-box;
								padding: 0 15px 0 28px;
							}
						}
					}

					a {
						margin: 0;
						padding-left: 18px;
					}
				}

				li {
					margin-left: 0;
					min-width: 100%;

					&.section-header {
						color: #777;
						margin-left: 8px;
						margin-bottom: 4px;
						margin-top: 4px;
					}

					&:not(.section-end) {
						line-height: 30px;
						min-height: 30px;
					 }

					&:hover {
						background: #E6E6E6;

						& > a,
						& a > span:first-of-type,
						& > span {
							font-weight: 600;
						}
					}

					> a,
					> span
					{
						color: #222;
						display: block;
						line-height: 30px;
					}

					> a {
						padding: 0 15px 0 15px;
						&:hover {
							text-decoration: none;
						}

						> span {
							display: inline;
							font-weight: 400;
							line-height: 30px;
							margin-left: 0;
						}
					}
				}
		  }
	 }

	 > li {
		  float: left;
		  line-height: 1.5em;
		  margin-left: 20px;

		  a {
			  .utilNavIcons {
				  fill: #909090;
			  }

			  &:hover .utilNavIcons,
			  &.active .utilNavIcons {
				  fill: #656565;
			  }
		  }
	 }

	 #util {

		  &Requests .count-container {
			  position: absolute;
			  right: 2px;
			  top: 4px;

			  .count {
				  position: absolute;
				  display: block;
				  top: 50%;
				  left: 50%;
				  border: solid #ebebeb 1px;
				  border-radius: 50px;
				  text-indent: 0;
				  font-weight: 600;
				  font-size: 12px;
				  padding: 4px 3px 5px;
				  line-height: 7px;
				  min-width: 10px;
				  text-align: center;
				  color: #fff;
				  transform: translate(-50%, -50%);
			  }
		  }

		  &Account {
				width: 22px;
				line-height: 29px;

				img {
					margin-bottom: 5px;
					vertical-align: bottom;
				}
		  }
	 }
}

#todoLinks {
	li {
		.inbox-icon {
			margin-top: 6px;
		}
		.inbox-sent-icon {
			margin-top: 8px;
			margin-left: -2px;
		}

		span.count {
			color: #888888;
		}

		&#infoUpdates {
			padding-bottom: 0;
			margin-bottom: 2px;
			border-bottom: 1px solid #e5e5e5;
		}
	}
}


#help-form {
	 div.subHeader {
		  color: #555;
		  font-size: 15px;
		  font-weight: bold;
		  margin-bottom: 3px;
	 }

	 textarea {
		  overflow: hidden;
		  word-wrap: break-word;
		  height: 100px;
		  width: 375px;
	 }
}

{featureToggleOff("jade")} {
	.top-nav-dd {
		position: relative;

		ul.down-list {
			top: 14px;
		}
	}
}

{featureToggleOn("jade")} {
	// Temporary (Remove when new dropdowns are done)
	.top-nav-dd {
		position: relative;

		ul.down-list {
			left: auto;
			right: 0;
			top: 31px;
		}
	}

	// For Counts
	.count-container {
		position: absolute;
		right: 2px;
		top: -10px;

		.count {
			align-items: center;
			border-radius: 50%;
			display: flex;
			fab-color(background-color, theme-base);
			border: 2px solid transparent;
			fab-color(border-color, gray2);
			fab-color: 'white';
			font-family: 'Lato';
			position: absolute;
			justify-content: center;
			text-indent: 0;
			fab-font-weight: heavy;
			font-size: 11px; //This is a requested one off
			height: 20px;
			fab-line-height: small;
			line-height: 1.1;
			width: 20px;
			right: -14px;
    		top: 3px;
		}
	}

	.InboxDropdown--has-count .InboxDropdown__counts-container {
    	display: block;
    	-ms-filter: none;
    	filter: none;
    	margin-top: 0;
	}

	.InboxDropdown__counts-container {
		display: none;
	}
}
