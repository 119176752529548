/*****
Please do not delete style.css
FE: working on depricating this file
******/
body div.lock {
	opacity: .5;
	filter: alpha(opacity=50);
}
hr {
	border: none 0;
	border-top: 1px solid #dfdfdf;
	width: 100%;
	height: 1px;
	margin: 10px 0;
}

/* Style a datepicker input to look like a link */
.datepicker-link {
	text-decoration: underline;
	border: none;
	color: #0072C6;
	cursor: pointer;
}
.center {
	text-align: center;
}
.notransition {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	-ms-transition: none !important;
	transition: none !important;
}
.double-high {
	padding-top: 20px;
}
.required {
	color: #FF003A;
}

/*-------------------------------------------------
 LOGIN
-------------------------------------------------*/
ul.loggedin li {
	line-height: 1.75em;
}
input.rightCheck {
	width: 20px;
	margin: 0;
	padding: 0;
}
.checked {
	margin-top: 5px;
}

/*.checked label { ** bhr jn - removed to avoid jump on check in ie *
		margin-right: 10px;
}*/
.highlight {
	background: #FFC;
	width: 100%;
	border: 1px solid #e3e0c3;
	margin: 5px 0 10px;
	padding: 0 3px;
	border-radius: 2px;
}
.highlight p {
	padding: 5px 7px 0 0;
	margin: 3px 0;
}
span.highlighter {
	background: #FFFFCC;
}
.notFound {
	width: auto;
	padding: 0 25px;
}
.password-requirements p {
	font-weight: 600;
	margin: 0;
}
.password-requirements ul#requirements_list {
	padding: 0;
	margin: 4px 0 0;
}
.password-requirements ul#requirements_list li {
	font-size: 12px;
	line-height: 24px;
	padding: 0px 0 0 25px;
	margin: 0;
	background: url('https://staticfe.bamboohr.com/resources/images/design/alert_16x15.png') 5px 4px no-repeat;
}
.password-requirements ul#requirements_list li.ok {
	background-image: url('https://staticfe.bamboohr.com/resources/images/design/check_16x15.png');
}

/*-------------------------------------------------
HEADER
-------------------------------------------------*/
#header-alert {
	background: #FDF9D4;
	border-bottom: 1px solid #F1D44A;
	height: 45px;
	line-height: 46px;
	text-align: center;
	font-weight: 600;
	font-size: 18px;
}
#header-alert img {
	vertical-align: middle;
	margin: -4px 10px 0 0;
}
#header-alert a {
	font-size: 12px;
}
#header {
	padding: 10px 0 0;
}
#inside-header {
	margin: 0 auto;
	width: 960px;
}
#header h1 {
	font-size: 20px;
	font-weight: normal;
	margin: 0 0 0 20px;
	line-height: 1.5em;
	text-align: left;
}

/* Feedback modal */
#feedback-modal label {
	display: block;
}
#feedback-modal p {
	margin: 0 0 5px;
}
#feedback-modal #feedbackNote {
	width: 300px;
	height: 80px;
}
.customFieldBuilderSupport {
	height: 95px;
	padding: 10px 24px 18px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	column-gap: 13px;
	background-color: #FFFFFF;
	border-radius: 1px;
	border: 1px solid #0772b3;
	width: 448px;
	margin-top: 15px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}
.customFieldBuilderSupport-message--description {
	font-weight: 400;
	font-size: 14px;
	color: #555555;
	padding-top: 3px;
}
.customFieldBuilderSupport-message--text {
	display: flex;
	flex-direction: column;
	line-height: 18px;
}
.customFieldBuilderSupport-message--title {
	font-weight: 600;
	font-size: 16px;
	color: #0772B3;
}
.customFieldBuilderSupport-message--icon {
	fill: #0772b3;
	width: 24px;
	align-self: baseline;
	padding-top: 10px;
	min-inline-size: min-content;
}

/*-------------------------------------------------
BODY BLOCKS SECTIONS STUFF
-------------------------------------------------*/

/*MAIN TOP TILE ADD LINKS
------------------------------------*/
div.breadcrumb {
	font-size: 12px;
	margin-bottom: 15px;
	height: 12px;
	width: 500px;
}
#filter-description {
	padding: 0 5px 5px 20px;
	margin-left: 5px;
}
#filter-description div {
	margin: 4px 0;
}
#filter-description div a i {
	margin-right: 3px;
}
#filter-description div,
ul.add-remove li {
	padding: 5px 0 0;
	font-size: 15px;
}
#filter-description div:last-child {
	margin-bottom: 0;
}
ul.add-remove {
	margin: 5px 0 0 0;
	padding-top: 7px;
}
ul.add-remove li {
	padding: 5px 0 4px;
}
#plan-list li span {
	font-size: 15px;
	color: #000;
}
#plan-list li span a {
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
}
#plan-list li:not(:first-child) {
	border-top: 1px solid #a8a8a8;
}
#plan-list .planName a {
	font-weight: 600;
	font-size: 15px;
}
#filter-description div a,
ul.add-remove li a {
	font-weight: 600;
	font-size: 11px;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
a.remove-x {
	font-weight: 600;
	font-size: 11px;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
.drop-filter {
	color: #0072C6;
	display: inline-block;
	padding: 1px 0 2px 20px;
	background: url('https://staticfe.bamboohr.com/resources/images/icon_arrow_down12.gif') no-repeat 0 5px;
}
.df-up {
	background: url('https://staticfe.bamboohr.com/resources/images/icon_arrow_right12.gif') no-repeat 0 5px;
}
.drop-edit {
	color: #0072C6;
	cursor: pointer;
}
.filter-update {
	padding: 10px 0 20px 15px;
	width: auto;
	display: block;
}
a.button-link {
	display: block;
	margin: 5px 7px 10px 0;
	background-color: #E1EDF7;
	border: 1px solid #999999;
	border-top: 1px solid #cecece;
	border-left: 1px solid #cecece;
	line-height: 1.3em;
	cursor: pointer;
	padding: 5px 0 6px 7px;
	width: 60px;
}
#addLinks,
.buttonbar.right {
	float: right;
}
#addLinks,
.buttonbar {
	margin: 0 1px 0 0;
}
.buttonbar.right {
	width: auto;
}
#addLinks.left {
	float: left;
	margin: 0;
}
#addLinks > li,
.buttonbar li {
	float: left;
	color: #cccccc;
	padding-left: 10px;
}
#ie9 #addLinks li.dashboardAddFile {
	margin-right: -16px;
}
#addLinks li.link a,
.buttonbar li.link a {
	background-color: #fff;
	border: 1px solid #fff;
	border-top: 1px solid #fff;
	border-left: 1px solid #fff;
}
#addLinks button img,
#addLinks a img,
a.button-link img,
.buttonbar button img,
.buttonbar a img,
.btn img {
	margin: 0 3px -3px 0 !important;
	padding: 0;
	border: none;
	width: 16px;
	height: 16px;
}
#looping .btnGroup .btn img {
	margin-bottom: 0 !important;
}

/* NEGATIVE */
.buttonbar li a:hover,
.buttonbar li a.selected,
a.button-link:hover {
	background-color: #E0F9C8;
	border: 1px solid #C6D880;
	color: #529214;
	text-decoration: none;
}
.buttonbar li.red a:hover,
.buttonbar li.red a.selected {
	background-color: #F8EAE7;
	border: 1px solid #cecece;
	color: #CF564D;
}
#addLinks li.link a:hover,
.buttonbar li.link a:hover {
	background-color: #fff;
	border: 1px solid #fff;
	color: #0072C6;
	text-decoration: none;
}
.buttonbar li.med a {
	width: 95px;
}
.buttonbar.connected li {
	padding-right: 0;
	padding-left: 0;
}
.buttonbar.connected li a {
	margin-right: 0;
}
.buttonbar li.icon-right {
	text-align: right;
}
#add-links-content {
	width: 100%;
	padding: 0 0 5px;
}
div#overlay {
	background: url('https://staticfe.bamboohr.com/resources/images/overlay_bg.png');
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 50;
}
div#demo_welcome {
	display: none;
	width: 644px;
	height: 312px;
	margin: 100px auto 0;
	padding: 0px;
	background: url('https://staticfe.bamboohr.com/resources/images/demo_welcome_bg.png') top left no-repeat;
}
div#demo_welcome div.welcome_inner {
	position: relative;
	padding: 32px 56px 20px 58px;
	text-align: left;
}
div#demo_welcome div.welcome_inner a.close {
	position: absolute;
	text-align: right;
	top: 30px;
	right: 40px;
	font-size: 11px;
}
div#demo_welcome h2 {
	font-style: normal;
	font-weight: 600;
	width: 100%;
	padding: 0;
	text-indent: 0;
	margin: 0 0 18px 0;
	line-height: 1.25em;
	color: #8bba00;
	border-bottom: 1px solid #d6d6d6;
}
div#demo_welcome div.demo_block {
	margin: 10px 5px 4px 0;
	width: 260px;
	height: 84px;
	padding-top: 16px;
	float: left;
}
div#demo_welcome div.demo_block a {
	font-weight: 600;
	margin: 0 0 0 75px;
}
div#demo_welcome div.demo_block p {
	margin: 4px 0 0 75px;
}
div#demo_welcome div.demo_block.work {
	background: url('https://staticfe.bamboohr.com/resources/images/demo_welcome_work_btn.gif') top left no-repeat;
}
div#demo_welcome div.demo_block.play {
	background: url('https://staticfe.bamboohr.com/resources/images/demo_welcome_play_btn.gif') top left no-repeat;
}

/*Colorpicker
------------------------*/
.color-picker {
	position: relative;
	width: 36px;
	height: 36px;
	background: url('https://staticfe.bamboohr.com/resources/images/colorpicker/select2.png');
}
.color-picker div {
	position: absolute;
	top: 4px;
	left: 4px;
	width: 28px;
	height: 28px;
	background: url('https://staticfe.bamboohr.com/resources/images/colorpicker/select2.png') center;
}

/*FEATURES DL
------------------------*/
.half-box {
	width: 290px;
	float: left;
	margin-right: 10px;
}
.half-box dl {
	margin: 10px 20px;
	float: left;
	width: 300px;
	display: inline;
}
.half-box dt {
	font-size: 18px;
	width: 250px;
	float: right;
	color: #222;
	font-weight: normal;
	line-height: 1.25em;
}
.half-box dd {
	font-size: 14px;
	line-height: 1.25em;
	margin: 0 0 0 50px;
}
.half-box dl dd.img-icon {
	margin: 0;
}
.half-box dd.img-icon img {
	float: left;
	padding: 2px;
	border: 2px solid #ffffff;
	background: #ffffff;
}

/*-------------------------------------------------
 index content
-------------------------------------------------*/
#body-content {
	background: #ffffff;
}
#inside-content table.basic-font td,
#inside-content table.basic-font t {
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
#inside-content-flex {
	margin: 0 auto;
	min-width: 920px;
	width: 100%;
	background: #ffffff;
}
#content-nav p.emp-status {
	padding: 0;
	margin: 0;
	font-size: 11px;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
#content-nav ul.subsection-list {
	float: left;
	width: 100%;
	margin: 15px 0 10px 0;
	border-bottom: 1px solid #e5e5e5;
}
#content-nav ul.subsection-list li {
	float: left;
	margin: 2px 0 0 0;
	padding: 0 3px;
}
#content-nav ul.subsection-list li.on {
	border-bottom: none;
}
#content-nav ul.subsection-list a {
	float: left;
	display: block;
	margin: 3px 0 0 0;
	padding: 8px 11px 6px;
	text-decoration: none;
	-webkit-border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	border-radius: 3px 3px 0 0;
	color: #999;
	background: #f5f5f5;
	border: 1px solid #e5e5e5;
	border-bottom: none;
}
#content-nav ul.subsection-list a:hover {
	text-decoration: underline;
}
#content-nav ul.subsection-list li.on a {
	background: #ffffff;
	padding-bottom: 6px;
	border: 1px solid #e5e5e5;
	border-bottom-color: #fff;
	font-weight: 600;
	color: #444;
	margin-bottom: -1px;
}
ul.toolbar {
	height: 31px;
	line-height: 2.4em;
	background: #f5f5f5;
	border: 1px solid #e5e5e5;
	border-left: none;
	border-right: none;
	position: relative;
}
ul.toolbar li {
	float: left;
	margin: 0 15px 0 10px;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
ul.toolbar a {
	float: left;
	margin: 0;
	text-decoration: none;
}
ul.toolbar a:hover {
	text-decoration: underline;
}
ul.toolbar li.on a {
	background: #ffffff;
	padding-bottom: 7px;
	border: 1px solid #cccccc;
	border-bottom: none;
	font-weight: 600;
}

/*BELOW TAB NAV AND TITLE
--------------------------------*/
#below-nav {
	border-bottom: 1px solid #dfdfdf;
	padding: 10px 0 5px;
	position: relative;
}
#below-nav h3.below-title {
	font-family: BhrHeaderFont, 'Trebuchet MS';
	font-size: 20px;
	display: inline;
	width: auto;
	float: left;
}
#below-nav p.below-link {
	display: inline;
	float: right;
	width: auto;
	line-height: normal;
	margin: 3px 0 0;
}
.twocol table {
	width: 720px;
}
#left-col {
	width: 210px;
	float: left;
}
#right-form-col {
	width: 720px;
	float: left;
}
#photo-display {
	margin-left: 10px;
}
#photo-display img {
	border: 1px dashed #dfdfdf;
	padding: 5px;
}
#photo-display p {
	font-size: 11px;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
a.sub-link {
	background: #C6FFC6;
}
#col-wrap {
	width: 930px;
	margin-left: 20px;
	float: left;
	background: #ffffff;
}
#col-wrap.mods {
	padding-top: 10px;
}
#one-large {
	margin: 10px 0 15px;
	float: left;
	width: 840px;
}
#one-col {
	width: 695px;
	float: left;
	background: #ffffff;
	margin: 10px 0 15px 0;
}
#one-col.right {
	float: right;
}
#side-col {
	width: 219px;
	float: right;
	margin: 20px 0;
}
#side-col.left {
	float: left;
}
#notes {
	margin: 10px 0 20px;
	float: left;
	width: auto;
}
table.table-notes {
	width: 100%;
}
#notes table th {
	border-bottom: 1px solid #dfdfdf;
	font-weight: 600;
}
#notes table td {
	border-bottom: 1px solid #DFDFDF;
	font-size: 11px;
}
#one-col form table {
	background: #ffffff;
	width: 75%;
	float: left;
	margin: 10px 0 0;
}
form table.tablewrap th.header {
	text-align: left;
	padding: 14px 0 4px 5px;
	background-color: #ffffff;
	font-size: 14px;
	font-weight: 600;
	border-bottom: 1px solid #dfdfdf;
}
#one-col form table th,
#one-col form table td,
#one-col form table.benefits-table th,
#one-col table.benefits-table th {
	padding: 8px 8px 5px 0;
}
#side-col ul.manage-list li {
	line-height: 1.5em;
	text-align: left;
}
#side-col ul.manage-list li a {
	display: block;
	padding: 6px 10px 5px 5px;
	border-bottom: 1px solid #DFDFDF;
}
#side-col ul.manage-list li.category {
	background: #F7F7F7;
	padding: 6px 10px 5px 5px;
	border-bottom: 1px solid #DFDFDF;
}
#side-col ul.manage-list li.subcategory a {
	padding: 4px 10px 3px 20px;
}
#side-col ul.basic-list {
	margin: 0 10px;
}
#side-col ul.basic-list li {
	list-style: disc;
	line-height: 1.5em;
	margin-left: 20px;
}
#body-content ul.basic-list {
	margin: 0 10px 20px 0;
}
#body-content ul.basic-list li {
	list-style: disc;
	line-height: 1.4em;
	margin-left: 40px;
}
ul li h4 {
	margin: 10px 0 0 10px;
}
ul.alert-list li a {
	line-height: 1.5em;
	margin-left: 17px;
}
#side-col ul.manage-list li.on a {
	background: #E1EDF7;
	font-weight: 600;
	color: #222;
	text-decoration: none;
}
#side-col ul.dash-list li {
	line-height: 1.5em;
	text-align: left;
}
#side-col ul.dash-list li a {
	display: block;
	padding: 4px 10px 3px 10px;
	font-size: 12px;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}
div.company-links ul.lined-list {
	margin-top: 10px;
}
div.company-links li.cat,
div.company-files h3.file-title,
div.my-files h3.file-title {
	font-weight: 600;
	font-size: 14px;
}
div.company-links li.cat li {
	font-weight: normal;
	padding-left: 10px;
}
div.company-files ul.file-list li,
div.my-files ul.file-list li,
div.my-files ul.file-list span {
	font-size: 11px;
}
#viewEditNav li.on {
	display: none;
}
#viewEditNav a {
	font-weight: 600;
	display: block;
	margin: 0;
	padding: 4px 8px 4px 10px;
}
#viewEditNav li.on a {
}
#viewEditNav li.on a:hover {
	padding-bottom: 4px;
}
#viewEditNav li a:hover {
	color: #222;
}
#summaryDetail {
	float: left;
	width: 50%;
	border: 1px dashed #c00;
}
#contentLogin {
	width: 600px;
	margin: 50px auto;
	background: #ffffff;
}
#contentLogin table {
	width: 50%;
	text-align: left;
}
fieldset.add-contact {
	margin-left: 180px;
}

/*-------------------------------------------------
ALERT WARNINGS GENERAL POPUP NOTICE STUFF
-------------------------------------------------*/
#message-wrap {
	width: 100%;
	margin: -10px 0px 0px;
	border-bottom: 1px solid #f1d44a;
	background: #fdf9d4;
}
#tip-message {
	padding: 10px;
	text-align: left;
	margin: 0 auto;
	height: 50px;
	background: url('https://staticfe.bamboohr.com/resources/images/annoucement_icon.gif') 50px 20px no-repeat #fdf9d4;
	font-size: 11px;
}
#tip-message p {
	margin: 0 0 0 100px;
	padding: 20px 0 0;
}
#tip-message div.tip-close {
	float: right;
	margin-right: 11px;
	padding-top: 2px;
}
#welcome-list {
	float: left;
	width: 100%;
	padding-bottom: 10px;
}
#welcome-list li,
ul.bulletlisted li {
	/* Is this being used? */
	line-height: 1.5em;
	padding: 4px 0 4px 18px;
	background: transparent url('https://staticfe.bamboohr.com/resources/images/list_bullet.gif') no-repeat 0 5px;
}
#welcome-list li a {
	font-weight: 600;
}
.close {
	float: right;
}
.close a {
	font-size: 11px;
	display: block;
	padding: 0 0 10px 16px;
	background: transparent url('https://staticfe.bamboohr.com/resources/images/icon_close_12.gif') no-repeat 0 0;
	text-decoration: none;
}
.heads-up {
	padding: 20px 30px;
	font-size: 15px;
	line-height: 20px;
	color: #666;
	margin: 20px auto;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	background: #fafafa;
	background: -moz-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #f5f5f5));
	background: -webkit-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
	background: -o-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
	background: -ms-linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
	background: linear-gradient(top, #ffffff 0%, #f5f5f5 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f5f5f5', GradientType=0);
	border: 1px solid #ccc;
	width: 80%;
	text-align: left;
	display: block;
}
.heads-up img {
	display: inline-block;
}
.heads-up p span {
	display: inline-block;
	width: 50%;
	margin-left: 15px;
	margin-top: -5px;
}
.heads-up p .button {
	display: inline-block;
	margin-top: 2px;
	vertical-align: top;
	float: right;
}
.heads-up .small {
	padding: 15px;
}
.heads-up .small span {
	width: 255px;
	margin-left: 0;
	font-size: 12px;
	text-align: center;
}
.heads-up .small a {
	font-weight: 600;
}

.heads-up-button-fix {
	margin-top: -25px;
	margin-left: 20px;
}
.heads-up #addHireDate,
.heads-up .heads-up-button-fix {
	margin-top: -1px;
}

/*********** End In App Tutorials *********/
.message li.info,
.message  li.error,
.message  li.success {
	color: #222;
	background-color: #E1EDF7;
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_info_32.gif');
	background-position: 8px 6px;
	background-repeat: no-repeat;
	padding: 10px 20px 17px 48px;
	display: block;
	line-height: 1.25em;
	border: 1px solid #DFDFDF;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	margin: 5px 0;
}
.message  li.error {
	color: #ffffff;
	background-color: #CC0001;
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_alert_32.gif');
}
.message  li.error a,
.message  li.error a:visited {
	color: #ffffff;
	text-decoration: underline;
}
.message  li.success {
	background-color: #E0F9C8;
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_success_32.gif');
}
#manage-list {
	line-height: 1.5em;
}
div.file-list {
	padding-bottom: 6px;
}
[class^="icon-"],
[class*=" icon-"] {
	background-repeat: no-repeat;
}
.icon-jpg,
.icon-gif,
.icon-png {
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_img_16.gif');
}
.icon-pdf {
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_pdf_16.gif');
}
.icon-doc,
.icon-docx {
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_doc_16.gif');
}
.icon-xls,
.icon-xlsx {
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_xls_16.gif');
}
.icon-ppt,
.icon-pptx {
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_ppt_16.gif');
}
.icon-rtf {
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_rtf_16.png');
}
.files ul li {
	border-bottom: 1px solid #E8E8E1;
	padding: 5px 0 5px 24px;
	width: auto;
	line-height: 1.5em;
}
span.realFilename,
span.metadata {
	font-size: 12px;
	color: #666666;
}
#report-wizard {
	float: left;
	padding-bottom: 20px;
	margin-left: 20px;
}
#report-wizard h3 {
	margin-top: 10px;
}
#show-box table td h3 {
	margin-top: 5px;
	text-align: center;
}
#benefit-history h3,
#dependentTable h3 {
	line-height: normal;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
	font-size: 16px;
	font-weight: 600;
	padding: 7px 0 4px;
}
#inside-content h3 {
	position: relative;
}
#inside-content h3 p.add {
	font-size: 14px;
	position: absolute;
	top: 4px;
	right: 0px;
}
#inside-content h3 p.add .chzn-container {
	position: relative;
}
p.options-link {
	font-size: 14px;
	position: absolute;
}
#below-nav p.options-link {
	right: 0;
}
#inside-content h3 p.options-link {
	top: 0;
	right: 0;
}
p.options-link a {
	padding: 0 0 0 19px;
	background-position: 0 0;
	background-repeat: no-repeat;
}
p.options-link.calculator a {
	background-image: url('https://staticfe.bamboohr.com/resources/images/icon_calc_16.gif');
	line-height: 16px;
	height: 16px;
	display: inline-block;
}

/*#one-full*/
table.tablewrap {
	margin-top: 10px;
}
.tablewrap h3 {
	font-size: 14px;
	font-weight: normal;
}
#TB_ajaxContent.TB_modal ul.note {
	margin: 5px 0 0;
}
#report-wizard table th {
	text-align: left;
	font-weight: 600;
}
#report-wizard table td {
	vertical-align: top;
	padding: 6px 4px 3px 5px;
	white-space: nowrap;
}
#report-wizard table td b {
	font-weight: normal;
}
#field-order {
	margin: 10px 0 20px 10px;
	float: left;
}
#reports {
	margin-top: 15px;
	float: left;
	width: 100%;
}
#report-list {
	padding: 0 0 10px 10px;
}
#report-list h3 {
	padding: 10px 0 0;
	border-bottom: 1px solid #cccccc;
	line-height: 1.5em;
}
#report-list ul li span {
	display: block;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
	font-size: 18px;
	width: 24px;
	float: left;
	margin: 0 10px 0 0;
	color: #222;
	text-align: right;
}
#report-list ul li span.age {
	display: inline;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
	font-size: 11px;
	float: none;
	font-weight: 600;
	margin: 0;
	color: #555555;
}
#report-list ul li {
	line-height: 1.5em;
	padding: 4px 0 3px 10px;
}

/* REPORT STYLES */
.google-chart {
	margin: 0 0 30px 0;
	border: 1px solid #e5e5e5;
	-moz-border-radius: 0 0 5px 5px;
	-webkit-border-radius: 0 0 5px 5px;
	border-radius: 0 0 5px 5px;
	padding: 30px;
}
.chart-title {
	border: 1px solid #e5e5e5;
	-moz-border-radius: 5px 5px 0 0;
	-webkit-border-radius: 5px 5px 0 0;
	border-radius: 5px 5px 0 0;
	padding: 10px 15px;
	background: #f5f5f5;
	border-bottom: none;
	display: block;
	font-size: 14px;
	color: #666;
}
.boxed p {
	padding: 10px 10px 7px 5px;
}
ul.norm {
	padding-bottom: 10px;
}
ul.norm li {
	border-bottom: 1px solid #E8E8E1;
	line-height: 1.5em;
	width: auto;
	font-size: 14px;
}
ul.norm li span {
	font-weight: 600;
}
ul.clean li {
	border-bottom: none;
}

div.file-list.closed {
	display: none;
}
.status {
	border-bottom: 1px solid #dfdfdf;
}
#side-col .status li {
	line-height: 1.25em;
	padding: 5px 0 4px;
	border-bottom: 1px solid #DFDFDF;
	width: 100%;
}
#side-col .status li span {
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
	font-size: 13px;
	color: #000000;
	font-weight: 600;
	width: 35px;
	float: left;
	text-align: center;
	padding: 0 2px 0 5px;
}
#side-col .status li div {
	width: 165px;
	float: right;
}
.save-box {
	padding: 10px 0;
}
.float-box {
	float: left;
	width: 100%;
}
.padtable {
	padding: 10px 0;
}
#below-nav.padtable {
	padding: 10px 0 5px;
}
info-box {
	background-color: #F1F1ED;
	padding: 7px 10px;
	margin-bottom: 10px;
	border-top: 1px solid #cccccc;
}
.info-box,
.info-box p {
	font-size: 11px;
	line-height: 1.25em;
}
.info-box p.question {
	font-weight: 600;
}
p.single-pspace {
	padding: 7px 0 0;
}
.nav-box {
	border: 1px dashed #c00;
	width: 100%;
}
.nav-box h3 {
	background: #003;
	color: #fff;
	line-height: 1.5em;
}
/* Drop down link */
a.dropdown {
	margin: 0;
	padding: 0;
	cursor: pointer;
	display: inline-block;
	width: 13px;
	height: 11px;
	background: url('https://staticfe.bamboohr.com/resources/images/design/arrow_down.png') 0 0 no-repeat;
}
a.dropdown.over,
a.dropdown:hover {
	background-position: 0 -22px;
	text-decoration: none;
}

/*MAIN CONTENT BOX
----------------------*/
.main-box {
	/* Widgets Right, Reports */
	border: 1px solid #dfdfdf;
	margin: 10px 0 20px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	padding-bottom: 12px;
}
.main-box p.adds {
	padding: 5px 0 0 10px;
	margin: 3px 0 5px;
}
.box-mod .content {
	margin: 15px 15px 5px 15px;
}
.box-mod .content img.photo {
	width: 90px;
	height: 90px;
	margin-bottom: 6px;
}
.box-mod ul.employee-details .name {
	color: #222222;
	font-weight: 600;
	font-size: 16px;
	margin: 6px 0 2px;
}

.box-mod ul.employee-details { font-size: 13px; height: auto; margin-bottom: 14px; }
.box-mod ul.employee-details li {
	color: #777777;
	line-height: 17px
}
.box-mod ul.pto-list {
	font-size: 12px;
	padding-bottom: 8px;
	border-top: 1px solid #E8E8E1;
	margin: 0px 0px 10px 0px;
	padding-top: 10px;
}
.box-mod ul.pto-list .time-off {
	color: #222222;
	font-weight: 600;
	font-size: 14px;
	margin: 3px 0 6px;
}
.box-mod ul.pto-list li {
	color: #777777;
	display: block;
	font-size: 13px;
	list-style: none;
	margin-bottom: 2px;
	line-height: 18px;
}
.box-mod ul.pto-list li .hours {
	color: #3882CC;
	display: inline-block;
	margin-right: 4px;
	width: 43px;
	text-align: right;
	font-size: 15px;
	font-weight: 600;
	float: left;
	clear:both;
	font-size: 16px;
}

.box-mod.files h3.file-title { border: none; margin-left: 15px; cursor: pointer;}
.box-mod.files ul { margin-left: 44px; background-position-y: 6px;}
.box-mod.files ul li { border: none; }
.box-mod .ptoRequestDiv a{
	float: right;
	font-size: 13px;
	margin-top: 5px;
}
.box-mod .ptoRequestDiv{
  margin-bottom: 10px;
}

.box-mod .pto-list li div.text-wrap-align {
	float:left;
	margin: 1px 0;
	padding-top: 1px;
	width: calc(100% - 59px);
}

.box-mod .ptoRequestDiv .requestTimeOff {
	margin-right: 6px;
}

.box-mod .ptoRequestDiv .calc-balance-dashboard {
	margin: 0;
	padding: 0 2px 0 3px;
}

tr.linked-row {
	cursor: pointer;
}
span.reset-link {
	font-size: 11px;
	font-weight: normal !important;
	margin-left: 4px;
}
span.reset-link a {
	display: inline !important;
	padding: 0 !important;
}

.date span {
	background: #FEF796;
}

.right {
	width: 210px;
	float: right;
}
#employeeList {
	width: 695px;
	float: left;
}
#reports {
	margin-top: 15px;
	float: left;
	width: 100%;
}
#reports h3 {
	float: left;
	margin: 0;
	padding: 0 0 3px;
	line-height: 1.5em;
}
p.arrowLink {
	margin-top: 12px;
	padding-left: 18px;
	background: transparent url('https://staticfe.bamboohr.com/resources/images/icon_arrow_right_16.gif') no-repeat 0 0;
}
ul.no-line li,
h3.no-line {
	border-bottom: none !important;
}
div.widget {
	margin: 10px 0 20px;
	padding: 0;
	position: relative;
}
div.widget.main-box,
div.widget div.box-mod {
	margin: 0;
	padding: 0 0 12px;
}
div.dashboard div#one-col .ui-sortable-placeholder {
	margin: 10px 0;
	padding: 0;
}
div.dashboard div#side-col .ui-sortable-placeholder {
	margin: 10px 0;
	padding: 0;
}
div.dashboard div#side-col #forMessages .message {
	margin-top: 10px;
}
div.box-mod .employee-stats-type {
	width: 100%;
	text-align: center;
	padding: 15px 0 0 0;
	font-weight: 600;
	margin-bottom: -15px;
	position: relative;
	z-index: 5;
}
#exports {
	padding: 5px 0 7px;
}
#exports ul#addLinks li a {
	padding: 3px 10px 4px 7px;
}
#exports ul#addLinks {
	margin-right: 0;
}

/*-------------------------------------------------
ACCOUNT UPGRADE DOWNGRADE STYLES
-------------------------------------------------*/
.account-packages h3 {
	margin: 0 0 7px;
}
.account-packages table {
	border: 1px solid #cccccc;
}
.account-packages table th {
	background-color: #F1F1ED;
	vertical-align: middle;
}
.account-packages table th,
.account-packages table td {
	padding: 8px 10px 6px;
	text-align: center;
	border-bottom: 1px solid #cccccc;
}
.account-packages table td.package {
	text-align: right;
	font-weight: 600;
	font-size: 14px;
}
.account-packages table tr.yours td {
	background: #FFFFCC;
	font-weight: 600;
}
.account-packages table th.updown {
	text-align: left;
	font-size: 12px;
}
.account-packages table td.free {
	text-align: left;
	line-height: 1.25em;
	color: #666666;
	font-size: 12px;
}
fieldset.card {
	background-color: #F1F1ED;
	width: auto;
	padding: 10px 0 0;
}
form fieldset.card ul li {
	line-height: 1.25em;
	padding: 0;
}

/*form.fooedform.fooedform.fooedform.fooedform.fooedform.fooed  background checks
-----------------------------------------------------------------*/
form.fooed {
	width: 100%
}
form.fooed ul {
	width: 100%;
	float: left;
	margin-top: 10px;
}
form.fooed li {
	clear: both;
	display: block;
	margin: 0;
	padding: 4px 5px 4px 0;
	float: left;
	width: 97% !important;
}
form.fooed label.desc {
	color: #555555;
	display: block;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.5em;
	padding: 0 0 1px;
	text-align: left;
	width: 120px;
}
form.fooed li span {
	float: left;
}
form.fooed li div {
	color: #666666;
	margin: 0 8px 0 0;
	padding: 0 0 8px;
}
form.fooed li div label,
form.fooed li span label {
	clear: both;
	color: #999999;
	display: block;
	font-size: 11px;
	line-height: 9px;
	padding-top: 6px;
	font-weight: normal;
}
.full {
	width: 100% !important;
}
form.fooed li div label,
form.fooed li span label {
	clear: both;
	color: #222333;
	display: block;
	font-size: 11px;
	line-height: 9px;
	padding-top: 5px;
}
form.fooed li div span.left,
form.fooed li div span.right {
	margin: 0;
	width: 47%;
}
form.fooed li div span.left {
	float: left;
}
form.fooed li div span.right {
	float: right;
}

/*FOOED*/
form li div span.full input,
form li div span.full select,
form li div span.left input,
form li div span.right input,
form li div span.left select,
form li div span.right select {
	width: 100%;
}
form li div span.full input.short-field,
form li div span.left input.short-field,
form li div span.right input.short-field {
	width: 85px;
}
span.toggleSwitch {
	background: url('https://staticfe.bamboohr.com/resources/images/setup/switch-on.gif') no-repeat 0 0;
	width: 52px;
	height: 24px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
	margin-right: 15px;
}
span.toggleSwitch.off {
	background: url('https://staticfe.bamboohr.com/resources/images/setup/switch-off.gif') no-repeat 0 0;
}

.step-counter p {
	padding: 2px 0 5px 35px;
	color: #666666;
	background: url('https://staticfe.bamboohr.com/resources/images/arrow-step.gif') no-repeat 8px 50%;
}
.step-counter p span {
	font: 20px Georgia, 'Times New Roman', Times, serif;
}
form ul.wizard-list {
	margin: 0 0 12px 5px;
}
form ul.wizard-list li {
	padding: 2px 0 0;
}
#more-list {
	padding: 0;
	margin: 0;
	width: 350px;
	border-bottom: 1px solid #dfdfdf;
}
form ul#more-list li {
	padding: 0 0 0 5px;
	margin: 0;
	line-height: 1.5em;
	width: 100%;
	border-top: 1px solid #dfdfdf;
}
form ul#more-list li a {
	padding-left: 5px;
}
form ul.add-entry-visible {
	padding-top: 5px;
	margin: 12px 0 0 0;
	padding: 5px 0 10px 10px;
	background: #F5F5F3;
	border: 1px solid #dfdfdf;
	width: 350px;
}

/*-------------------------------------------------
FORM BLOCKS FOR EVERYONE
-------------------------------------------------*/
.block-form {
	border: 1px solid #dfdfdf;
	width: 520px;
	padding: 10px 20px;
	margin: 0 0 10px;
	background: #F5F5F3;
}
.block-form li {
	line-height: 1.25em;
}
.block-form li.empty {
	display: none;
}

/*RIGHTCLICKDROPDOWN
-------------------------------------------------*/
div.hovered {
	width: 100%;
}
.option {
	visibility: hidden;
}
.hovers span.option {
	visibility: visible;
}

/*------------------------------------
TOOL BOX BACKGROUND CHECK
------------------------------------*/
.tool-box {
	border: 1px solid #dfdfdf;
	width: 100%;
	float: left;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	background: #fff url('https://staticfe.bamboohr.com/resources/images/bg_tool_bar.gif') repeat-x top left;
	padding-bottom: 15px;
}
#inside-content h3.tooled {
	border-bottom: 1px solid #dfdfdf;
	font-size: 12px;	/** depricated
	font-family:Tahoma, Arial, sans-serif;*/
	color: #666;
	line-height: 1.5em;
	float: left;
	display: block;
}
.tool-bar {
	float: left;
	width: 100%;
}
.tool-bar ul {
	margin: 0;
	padding: 5px;
	float: left;
	width: auto;
}
.tool-bar ul li {
	float: left;
	border-right: 1px solid #A9CEEB;
	padding: 2px 5px 0;
}
.tool-bar ul li.last,
.tool-bar ul#bar-view li {
	border-right: none;
}
.tool-bar ul#bar-view {
	padding: 9px 10px 0 0;
	float: right;
}
table td.detail p {
	padding: 0;
	margin: 0 0 3px 10px;
}
table.alerts th.topbox {
	vertical-align: top;
	padding-top: 12px;
}
#TB_title {
	display: none;
}
.ui-datepicker-trigger {
	cursor: pointer;
	position: absolute;
	margin: 4px;
}
td.tdinput span.age,
td.tdinput span.los {
	margin-left: 30px;
}

/*ICONS
-----------------*/
.deleteIcon {
	background: url('https://staticfe.bamboohr.com/resources/images/icon_delete.png') top left no-repeat;
	display: block;
	width: 13px;
	height: 13px;
	float: right;
	margin: 0 0 0 10px;
	text-indent: 200px;
	overflow: hidden;
}

/*TOOLS
---------------*/
table.alerts {
	width: 650px;
	margin-left: 10px;
}
table th.title {
	font-weight: 600;
	width: 80%;
}

/*Table
------------*/

/*#one-full */table.tool-list {
	width: 100%;
	float: left;
	background: #fff;
}

/*#one-full */
table.tool-list th,
table.sumlognote th {

	/*background: #EBEBE7;*/
	border-right: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	color: #555555;
	padding: 5px 5px 4px 10px;
	text-align: left;
}

/*#one-full */
table.tool-list th.no-border,
table.sumlognote th.no-border {
	border-right: none;
}

/*#one-full */
table.tool-list td,
#notes table.sumlognote td {
	padding: 6px 5px 6px 10px;
	text-align: left;
	border-bottom: 1px solid #dfdfdf;
	vertical-align: middle;
}
#notes table.sumlognote th {
	font-weight: normal;
}

table.tool-list td,
table.tool-list th,
table.sumlognote th,
table.sumlognote td {
	font-size: 12px;
	font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}

table.tool-list td.check-box,
table.tool-list th.check-box {
	width: 15px;
	padding: 0 0 0 10px;
}

table.tool-list td.check-box input {
	margin: 0;
	padding: 0;
	width: 15px;
	height: 15px;
}

table.tool-list td.tool-title,
#notes table.sumlognote td.tool-title {
	font-weight: 600;
	padding: 6px 0 4px 10px;
	color: #666666;
}
#notes table.sumlognote td.tool-title {
	padding: 7px 0 4px 10px;
}
td.tool-title,
tr.tool-titled {
	background: #F0F6FB;
}
#background_groups tr {
	cursor: default;
}
table tr.selected {
	background: #FEFFE4;
}
table tr.toolhead {
	background: #fff url('https://staticfe.bamboohr.com/resources/images/bg_tool_th.gif') repeat-x top left;
}
form div.employee-contact {
	margin-bottom: 20px;
}
a.x {
	float: left;
	display: block;
	width: 12px;
	height: 12px;
	background: url('https://staticfe.bamboohr.com/resources/images/buttons/xit.gif') no-repeat 0px 0px;
	text-indent: 100px;
	overflow: hidden;
}
a.x:hover {
	background-position: -12px 0px;
}
#training-status-table .expired {
	color: #D64646;
}
p.small-grey {
	font-size: 11px;
	color: #999;
}
ol {
	width: auto;
	margin: 0 0 5px 15px;
}
ol li {
	line-height: 1.5em;
}
div.boxed div.box-content ul {
	padding: 0px 10px 7px 5px;
}

/* Richtext */
td.mceToolbar {
	padding: 0px !important;
}
td.mceToolbar table {
	margin: 0px !important;
	padding: 0px !important;
}
td.mceToolbar td {
	padding: 0px !important;
}
td.mceToolbar td a {
	margin: 0px 2px !important;
	padding: 0px !important;
}
td.mceToolbar td a.mceText {
	margin-right: 0px !important;
	text-indent: 4px;
}
td.mceToolbar td a.mceOpen {
	margin: 0px !important;
}
td.mceIframeContainer {
	padding: 0px !important;
}

/* Styles for making the styles in the richtext look ok on the site */
.richtext-display {
	margin: 10px 0px 20px;
}
.richtext-display ul {
	margin: 0px 0px 10px 45px;
	list-style: disc outside none;
}
.richtext-display ol {
	margin: 0px 0px 10px 45px;
	list-style: decimal outside none;
}
a.enabled {
	color: #57A400;
}
a.disabled {
	color: #999999;
}

/* Global overrides */
.clear {
	clear: both !important;
}
.displaynone,
.displayNone {
	display: none !important;
}
.noline {
	border: none !important;
}
ul.noline li {
	border: none !important;
}
.strong {
	font-weight: 600 !important;
}
.nopadding {
	padding: 0px !important;
}
.nomargin {
	margin: 0px !important;
}
.noborder {
	border: none !important;
}
.alignleft {
	text-align: left !important;
}
.alignright {
	text-align: right !important;
}
.aligncenter {
	text-align: center !important;
}
.floatright {
	float: right !important;
}
.dim {
	color: #aaa !important;
}
.sortme:hover {
	cursor: move;
}
#deleteEmployeeModal p {
	margin-bottom: 10px;
}
#deleteEmployeeModal p.alert {
	color: red;
	background: #FEFFBF;
	width: 180px;
	text-align: center;
}
span.invalidValue {
	color: #CC0001;
	text-decoration: underline;
}
.tooltip {
	display: none;
	background: #fff url('https://staticfe.bamboohr.com/resources/images/menu_background_grad.gif') top right repeat-y;
	font-size: 11px;
	color: #222;
	position: absolute;
	border: 1px solid #cecece;
	margin: 0;
	padding: 5px;
	width: 128px;
	text-align: center;
	height: auto;
	-moz-box-shadow: 2px 2px 3px #CCC;
	-webkit-box-shadow: 2px 2px 3px #CCC;
	box-shadow: 2px 2px 3px #CCC;
	z-index: 99;
}

/*EMPLOYEE LOOPING PAGINATION
-----------------------*/
#looping .btn {
	float: none;
	padding-top: 4px;
}
.btn.next,
.btn.prev {
	min-width: 40px;
}
#looping .btnGroup {
	margin-left: 5px;
}
#looping {
	font-size: 12px;
	text-align: right;
	margin-bottom: 15px;
	width: 40%;
	float: right;
}
#emp-header-photo {
	width: 100px;
	float: left;
	font-size: 12px;
	text-align: center;
	margin: 0 20px 0 0;
	color: #999;
}
#emp-header-photo img {
	padding: 5px;
	border: solid 1px #E0E0E0;
	margin-left: 0;
}
#emp-header-photo a {
	line-height: normal;
	margin-top: 0;
}
#emp-header-info {
	width: 560px;
	float: left;
}
#emp-header-info h2 {
	clear: both;
	float: none;
	padding: 0;
	margin: 0;
	text-indent: 0;
}
#emp-header-info h3 {
	font-weight: normal;
}
#emp-header-info p {
	color: #999;
	line-height: 18px;
	margin-top: 3px;
	font-size: 12px;
}

span.text-label {
	padding: 1px 4px 2px;
	font-size: 11.049999999999999px;
	text-transform: uppercase;
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #999999;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
span.text-label:hover {
	color: #ffffff;
	text-decoration: none;
}
span.text-label-green {
	background-color: #86B93E;
}
span.text-label-green:hover {
	background-color: #7DAA3A;
}
span.text-label-red {
	background-color: #b94a48;
}
span.text-label-red:hover {
	background-color: #953b39;
}
span.text-label-orange {
	background-color: #f89406;
}
span.text-label-orange:hover {
	background-color: #c67605;
}
#main-top-inside #forMessages .message {
	margin: -15px 9px 10px 20px;
}
.middles .btn.add-selected {
	margin-bottom: 6px;
}
#celebrations-settings #celebrations-days {
	min-width: 45px;
}
#celebrations-settings #celebrations-limitBy {
	min-width: 75px;
}

/*directory*/
.aWarning {
	margin-bottom: 10px;
	text-align: left;
	font-size: 12px;
	padding: 14px;
	width: 500px;
	color: #d0af7b;
	background: #fdf9ea;
	border: 1px solid #d0af7b;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	line-height: 1.4em !important;
}
.aWarningNote {
	font-weight: 600;
}
.smallLink,
.smallText {
	font-size: 11px;
}
.widgetHeaderLink {
	float: right;
	padding-right: 7px;
	font-size: 12px;
	font-weight: normal;
}
.calIcon {
	background-color: transparent;
	background-image: url("https://staticfe.bamboohr.com/resources/images/btn_calendar_16.gif");
	background-position: left top;
	background-repeat: no-repeat;
	display: inline-block;
	height: 16px;
	text-decoration: none;
	text-indent: 20px;
	line-height: 20px;
}
.widget .calIcon {
	margin-top: 8px;
	margin-left: 80%;
}
.numberCol {
	text-align: right;
}
