.BackupCodesContent {
	// .BackupCodesContent__copy
	&__copy {
		// @startCleanup encore
		margin-bottom: 28px;
		// @endCleanup encore

		+encore() {
			margin-bottom: spacing(3);
		}

		// .BackupCodesContent__copy p
		p {
			// @startCleanup encore
			color: fab-color(gray9);
			// @endCleanup encore

			+encore() {
				color: var(--gray9);
			}
		}
	}

	// .BackupCodesContent__buttonWrapper
	&__buttonWrapper {
		display: flex;
		justify-content: center;
	}

	// .BackupCodesContent__printHeader
	&__printHeader {
		display: none;
	}
}

@media print {
	.BackupCodesContent {
		// .BackupCodesContent__printHeader
		&__printHeader {
			display: block;
			margin-bottom: 24px;
			border-bottom: 1px solid fab-color(gray3);
		}

		&__buttonWrapper {
			display: none;
		}
	}
}
