.Callout {
	box-sizing: border-box;
	margin-bottom: 9px;
	position: relative;
	z-index: 1;

	&--light {
		border-top: 1px solid #be;
		border-bottom: 1px solid #be;

		.Callout__caret {
			background-color: #f;
			border: 1px solid #be;
		}

		.Callout__inner {
			background-color: #f;
			color: #5;
		}
	}

	&__inner {
		align-items: center;
		color: #f;
		display: flex;
		line-height: 36px;
		padding-left: 8px;
		padding-right: 15px;
		position: relative;
		z-index: 3;

		&--flushLeft {
			margin-left: 0;
			padding-left: 0;
		}
	}

	&__title {
		font-size: 14px;
		flex-grow: 1;
		font-weight: 600;
	}

	&__actions {
		align-items: center;
		display: flex;
	}

	&__link {
		color: #f;
		font-size: 14px;

		&:hover {
			color: #f;
			text-decoration: underline;
		}
	}

	&__caret {
		position: absolute;
		top: calc(100% - 7px);
		height: 12px;
		width: 12px;
		transform: rotate(45deg);
		z-index: 2;

		&:not(.Callout__caret--customPosition) {
			left: calc(50% - 6px);
		}
	}
}
