__ = BEM('AccessLevelSelector');

/input[ba-access-level-selector] {
	visibility: hidden;
}

{__('^')} {
	&&--input {
		/.fieldBox > label + .fieldDiv > & {
			margin-top: 4px;
		}
	}

	&&--li,
	/li[ba-access-level-selector] {
		min-height: 43px;
		visibility: visible !important;
	}

	&&--li &__menu {
		width: 100%;
	}

	&&--li &__down-list {
		visibility: hidden;
		left: auto !important; // to override the .dropdown-menu inline styles
		display: block !important; // same here
		top: 0;
		right: calc(100% + 5px);

		/li[ba-access-level-selector]:hover > & {
			visibility: visible;
		}
	}

	&&--li &__btn {
		border: none;
		background: none;
		width: 100%;
		box-shadow: none;
		border-radius: none;
		padding: 0 !important;
		font-weight: normal;
		line-height: 14px;
		font-size: 14px;
		color: #000;
		height: auto;

		&:after {
			display: none;
		}

		/li[ba-access-level-selector]:hover > & {
			> a {
				&,
				> i {
					color: #fff;
				}
			}
		}
	}

	&&--li &__label {
		font-style: normal;
		color: #777;
		font-size: 12px;
	}

	& ul&__down-list {
		min-width: 230px;
		z-index: 1010;
		text-align: left;

		.section-end {
			&:last-of-type {
				display: none;
			}
		}
	}

	& &__menu {
		margin: 0 !important;
	}

	& &__item {
		position: relative;

		&,
		&.section-header {
			font-size: 14px;
		}

		&.section-header,
		& i {
			color: #68;
		}


		&:not(.section-header) {
			padding: 6px 26px;
			color: #000;

			&.checked {
				font-weight: 600;

				&:before {
					content: "";
					display: block;
					absolute: top 50% left 9px;
					border-width: 3px;
					border-style: solid;
					border-top: none;
					border-left: none;
					size: 4px 8px;
					transform: translateY(-7px) rotateZ(45deg);
				}
			}

			&:hover {
				color: #fff;
				&:before {
					border-color: #fff !important;
				}
			}
		}
	}

	&__item {
		padding-left: 22px;
		position: relative;
		margin: 0;
	}

	& &__btn {
		margin: 0 !important;
		padding: 0 14px 0 6px !important;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			right: 4px;
			top: 50%;
			transform: translateY(-50%);
			border: 4px solid transparent;
			border-top-color: $bhrColors.gray7;
			border-bottom: none;
		}

		> i {
			background-color: #aaa;
		}
	}

	&__ico {
		&--preview,
		&--checked {
			position: absolute;
			left: 0;
			
		}

		&--preview {
			fab-fill: gray7;
		}

		&--checked {
			transform: translateY(1px);
		}

		display: flex;
		align-items: center;
		height: 100%;
	}

	&__emailModal {
		.SimpleModal__body {
			width: 456px;
		}

		&Msg {
			margin-bottom: 17px;
		}
	}
}

--ba-option(elem, mods = '') {
	__(elem, join(' ', 'ba-option', mods));
}

{--ba-option('ico', 'checked')} {
	absolute: top 50% left 2px;
	transform: translateY(-50%);
	fab-fill: gray9;

	> svg {
		transform: translateY(1px);
	}

	/.fab-MenuOption--active & {
		fab-fill: gray9;
		fab-color: gray9;
		&:hover{
			fab-color: gray9;
			fab-fill: gray9;
		}
	}
}

{--ba-option('item')} {
	&:not(.section-header) {
		padding-left: 26px;
		position: relative;
	}

	&.checked {
		margin: 0;
		fab-color: gray9;
		fab-fill: gray9;
		fab-font-weight: semibold;
		&:hover{
				fab-color: gray9;
				fab-fill: gray9;
			}
	}

	/.fab-MenuOption--active & {
		fab-color: white;
			fab-color: gray9;
			&:hover{
			fab-color: gray9;
			fab-fill: gray9;
		}
	}
}
