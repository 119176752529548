// buttons buttons I got the buttons

@css {

	.btn,
	.btn:visited,
	.btn:hover {
		-moz-border-bottom-colors: none;
		-moz-border-image: none;
		-webkit-border-image: none;
		-o-border-image: none;
		-moz-border-left-colors: none;
		-moz-border-right-colors: none;
		-moz-border-top-colors: none;
	}

}

// default
//
@css {
	.btn,
	.btn:visited,
	.btn:hover {
		-moz-border-bottom-colors: none;
		-moz-border-image: none;
		-webkit-border-image: none;
		-o-border-image: none;
		-moz-border-left-colors: none;
		-moz-border-right-colors: none;
		-moz-border-top-colors: none;
	}
}
.btn {
	// .btn,
	// .btn:visited,
	// .btn:hover
	&,
	&:visited,
	&:hover {
		background-color: #f;
		border: 1px solid #ba;
		border-radius: 2px;
		color: #4;
		cursor: pointer;
		display: inline-block;
		font-size: 15px;
		font-family: "Source Sans Pro";
		font-weight: 600;
		line-height: 26px;
		margin-bottom: 0;
		margin-left: 0;
		padding: 0 15px 1px;
		min-width: 86px;
		text-align: center;
		vertical-align: middle;
		text-decoration: none;
		-webkit-font-smoothing: antialiased;
		height: 28px;
	}
	// .btn.ba-btn
	&.ba-btn {
		display: inline-flex;
		align-items: center;
		flex-direction: row;
	}
	// .btn.hover,
	// .btn:hover,
	// .btn.focus,
	// .btn:focus
	&.hover,
	&:hover,
	&.focus,
	&:focus {
		background-color: #fff;
		text-decoration: none;
		border-color: #989898;
		background-image: linear-gradient(0deg, #F7, #FF);
		box-shadow: 0 1px 0 0 $bhrColors.gray4;
		outline: 0;
	}

	&.active,
	&:active {
		color: #444;
		background-color: #F6F6F6;
		background-image: none;
		border-color: #989898;
		text-decoration: none;
		box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.10);
	}

	&.disabled,
	&:disabled {
		&,
		&.hover,
		&:hover,
		&.active,
		&:active {
			background-color: #fff;
			background-image: none;
			border-color: #d1;
			box-shadow: none;
			cursor: default;
			color: #b0;
		}

		> [class|="glyphicon"],
		> [class|="bicon"],
		> .ba-btn-icon {
			opacity: 0.5;
		}
	}

	&.processing {
		/.inputBtnProcessing {
			../,
			& {
				position: relative;
			}

			../,
			../:hover,
			& {
				color: #444;
				background: #F6;
				background-image: none;
				border-color: #c3;
				box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.08);
			}

			../:before,
			> img {
				content: url("https://staticfe.bamboohr.com/resources/images/buttons/button-processing.gif");
				display: block;
				position: absolute;
				left: 5px;
				top: 3px;
				z-index: 5;
			}
		}
	}

	i {
		margin-top: 4px;
		display: inline-block;
	}

	[class*="bicon"] {
		margin-right: 0;
	}

	button&,
	input& {
		&::-moz-focus-inner {
			border: 0;
		}
		&:focus:not(:active, .active) {
			box-shadow: none;
		}
	}

	div&,
	a&,
	span& {
		box-sizing: border-box;
		line-height: 26px;
	}

	#addLinks li > a& {
		margin-left: 10px;
	}

	& + & {
		margin-left: 7px;
	}

	&.full-width {
		margin: 0 0 7px 0;
	}

	&.btnAction {
		color: #fff;
		background-repeat: repeat-x;
		border-color: #222;
		font-weight: 600;
		text-shadow: 0 1px 0 rgba(0, 0, 0, 0.12);

		&.hover,
		&:hover {
			text-decoration: none;
			border-color: #1a;
			background-image: linear-gradient(top, #50, #333);
			box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.10);
		}

		&.active,
		&:active {
			background-color: #4f;
			text-decoration: none;
			border-color: #1a;
			background-image: linear-gradient(top, #4f, #34);
			box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
		}

		&.disabled,
		&:disabled {
			&,
			&.hover,
			&:hover,
			&.active,
			&:active {
				/.btnGroup.disabled .btn {
					../,
					&,
					&:hover,
					&Action:hover {
						background-color: #C9C9C9;
						background-image: none;
						border-color: #B9;
						color: #FFFFFF;
						cursor: default;
						text-shadow: none;
					}
				}
			}
		}

		&.disabled {
			&,
			&.hover,
			&:hover,
			&.active,
			&:active {
				/.btnGroup.disabled .btn {
					../,
					&,
					&:hover,
					&Action:hover {
						pointer-events: none;
					}
				}
			}
		}

		&.processing {
			color: #EAEAEA;
			cursor: default;
			&,
			&:hover,
			&:focus {
				background-color: #C9C9C9;
				background-image: none;
				border-color: #B9B9B9;
				box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.03);
			}
		}
	}

	&.btnTransparent {
		color: #fff !important;
		border-color: #90;
		background-color: transparent;

		&:hover {
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.2) 100%);
			box-shadow: 0 1px 0 0 #292929;
			font-weight: 600;
			text-shadow: none;
		}

		&:focus, &:active, &.active {
			background-image: none;
		}
	}

	&.noCaps {
		text-transform: none;
	}

	&.short {
		min-width: 0;
	}

	&.shorter {
		padding: 0 1px 4px;
		min-width: 0;
		height: 23px;
	}

	&.btnSmall {
		font-size: 14px;
		height: 26px;
		padding: 0 9px 0 9px;
		min-width: inherit;
		min-width: inherit;

		&,
		a&,
		div&,
		span& {
			line-height: 24px;
		}

		&--extraPadding {
			padding: 0 11px 0 11px;
		}

		&.common,
		^[0].common {
			padding-right: 10px;
			padding-left: 9px;
			min-width: 0;
		}

		&.btnSmallIcon {
			padding: 3px 5px;
		}

		&.btnSmallCalendar {
			padding: 0 10px 0 2px;
			min-width: 0;
			font-size: 16px;
			font-weight: 600;
			cursor: default;
			color: #222;
			line-height: 21px;
		}
	}

	&.btnLarge {
		font-size: 15px;
		height: 33px;
		padding: 0 15px 0;
		line-height: 31px;
	}

	&.btnXLarge {
		font-size: 18px;
		height: 36px;
		padding: 0 15px 0;
		line-height: 34px;
	}

	&.btn2XLarge {
		font-size: 16px;
		height: 47px;
		padding: 0 10px 0;
		line-height: 45px;
	}

	&.btn3XLarge {
		font-size: 16px;
		height: 57px;
		padding: 0 6px 0;
		line-height: 55px;
	}

	&.btnSquare {
		font-size: 12px;
		height: 57px;
		min-width: 67px;
		padding: 0 0;
		width: 67px;
	}

	&.btnLeftPoint {
		padding: 0 15px 1px 10px;
		position: relative;
	}

	.btnLeftPointer {
		display: block;
		position: absolute;
		overflow: hidden;
		width: 17px;
		height: 28px;
		top: 0;
		left: -17px;

		&:before {
			content: "";
			border-style: solid;
			border-width: 1px;
			display: block;
			width: 16px;
			height: 17px;
			position: absolute;
			transform: rotate(53deg) skewX(20deg);
			border-radius: 2px;
			right: -10px;
			top: 3px;
		}
	}

	&.btnRightPoint {

		&.btn2XLarge {
			padding: 0 0 0 10px;
			position: relative;
		}
	}

	.btnRightPointer {
		display: block;
		position: absolute;
		overflow: hidden;
		width: 13px;
		height: 45px;
		top: 0;
		right: -13px;

		&:before {
			content: "";
			display: block;
			width: 40px;
			height: 40px;
			background-color: #f;
			border: 1px solid #ba;
			position: absolute;
			transform: rotate(61deg) skewX(30deg);
			border-radius: 3px;
			right: 1px;
			top: 5px;
		}

		/.btn2XLarge {
			&:hover,
			&:focus,
			&:active {
				.btnRightPointer:before {
					text-decoration: none;
					border-color: #98;
				}
			}

			&:hover {
				.btnRightPointer:before {
					background: linear-gradient(135deg,  #fff 0, #f6 100%);
				}
			}

			&:active {
				.btnRightPointer:before {
					background-image: none;
					background-color: #f8;
				}
			}
		}
	}

	&Clear {
		background: none;
		border: 1px solid rgba(255, 255, 255, 0.7);

		&.light {
			color: #fff;

			&:hover,
			/.btnClear.active {
				color: #444;

				.caret {
					border-top: 4px solid #90;
				}
			}

			.caret {
				border-top: 4px solid #fff;
			}
		}
	}

	&Group {
		> .text {
			+ ^[0] {
				min-width: 0;
				width: auto;
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		> .btn {
			&.disabled {
				&:last-child {
					border-right: 1px solid #BA;
				}

				&.btnAction {
					&:last-child {
						border-right: 1px solid #B9;
					}
				}
			}
		}
	}

	&Link {
		color: #999;

		&.linkColor {
			color: #006ec2;
			font-weight: normal !important;
		}

		&:hover {
			color: #006ec2;
			text-decoration: underline;
		}

		&,
		&:hover,
		&:active,
		&:focus,
		&:visited {
			background: none;
			background-color: none;
			background-image: none;
			border: none;
			box-shadow: none;
			font-size: 13px;
			padding: 0 2px 0 2px;
		}
	}

	&Left {
		&,
		&:hover {
			text-align: left;
		}
	}

	&.btnInline {
		&.hover,
		&:hover {
			border-color: #91;
			background-image: linear-gradient(top, #fff, #f6);
			background-repeat: repeat-x;
			box-shadow: 0 1px 0 rgba(0, 0, 0, 0.07);
		}

		&.active,
		&:active {
			border-color: #91;
			background-image: linear-gradient(top, #f0, #fa);
			box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.07);
		}

		&.disabled,
		&:disabled {
			&,
			&.hover,
			&:hover,
			&.active,
			&:active {
				background-color: #fff;
				color: #b0;
				border-color: #d1;
				background-image: none;
				box-shadow: none;
				cursor: default;
			}
		}

		&Icon {
			&,
			&.btnInline {
				padding: 2px 5px 2px;
			}

			ba-icon.ba-btn-icon {
				color: $bhrColors.gray9;
				fill: $bhrColors.gray9;

				.btnAction& {
					color: #f;
					fill: #f;
				}
			}
		}

		[class*="bicon"] {
			margin-right: 0;
		}

		.ba-btn-icon {
			// Why the weird size? The height of Inlinebtn is 21px, so we need an odd number height
			height: 15px;
			width:15px;
			use {
				fill: #85;
			}
		}
	}

	&.btnInline,
	&.btnMini {
		font-size: 13px;
		height: 21px;
		padding: 0 9px;
		min-width: inherit;
		border-color: #afafaf;
		background-color: #fff;
		background-image: none;
		font-weight: 600;
		margin-right: 1px;
		line-height: normal;

		div&,
		a&,
		span& {
			line-height: 19px;
		}

		input&,
		button& {
			padding-bottom: 0;
		}
	}

	&Brand:hover {
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
	}
}

.no-cssfilters { // IE10 Specific Rules

	.btn {
		&.btnRightPoint,
		&.btnLeftPoint {
			overflow: visible;
		}

		.btnLeftPointer {

			&:before {
				right: -10px;
			}
		}
	}

}

.btnGroup {
	.btn {
		&,
		&.btnInline {
			// .btnGroup .btn,
			// .btnGroup .btn.btnInline
			margin-left: 0;
			margin-right: 0;
			border-radius: 0;
			float: left;
			border-right: none;
		}

		&.btnInlineSave {
			margin-top: 4px;
		}

		&:first-child,
		../ > label.input > .btn {
			// .btnGroup .btn:first-child,
			// .btnGroup > label.input > .btn
			border-radius: 2px 0 0 3px;
		}

		&:hover + {this()},
		&.active + {this()} {
			// .btnGroup .btn:hover + btnGroup .btn:hover
			// .btnGroup .btn:active + btnGroup .btn:active
			border-left-color: #98;
		}

		../ > ^[-1..-1]:last-child {
			// .btnGroup > .btn:last-child,
			border-radius: 0 2px 2px 0;
			border-right-style: solid;
			border-right-width: 1px;

			&:not(.btnAction) {
				// .btnGroup > .btn:last-child:not(.btnAction)
				border-right-color: #ba;
			}
		}

		../ > input.text + ^[-1..-1]:last-child {
			// .btnGroup > input.text + .btn:last-child
			padding-left: 10px;
			padding-right: 10px;
			min-width: 0;
		}

		&:last-child {
			&:after {
				// .btnGroup .btn:last-child:after
				content: "";
				display: block;
				clear: both;
			}
		}
	}

	> label.input {
		// .btnGroup > label.input
		display: inline;
	}

	input.text {
		//.btnGroup input.text
		text-align: left;
		text-transform: none;
		float: left;
		border-right: none;
		margin-left: 0;
		margin-right: -1px;
		height: 28px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.time-off {
	&-active,
	&-inactive {
		background-image: url("https://staticfe.bamboohr.com/resources/images/icons/active-inactive.png");
		width: 38px;
		height: 19px;
		display: inline-block;
		vertical-align: top;
	}

	&-active {
		background-position: top;
	}

	&-inactive {
		background-position: bottom;
	}
}

.dropdown-menu {
	position: relative;
	display: inline-block;
}

.down-list,
.sub-list {
	li {
		a {
			color: #000;
		}
	}
}

// DROPDOWNS
// -----------------------
div.menu-first {
	&.btn {
		margin-left: 5px;
		padding-left: 10px;
		padding-right: 8px;
		min-width: 0;

		&.btnIcon {
			margin-left: 10px;
			padding-left: 1px;
			padding-right: 3px;
			min-width: 0;

			&^[-1..-1]--extraPadding {
				margin-left: 8px;
				padding-left: 3px;
				padding-right: 5px;
			}
		}

		img {
			float: left;
		}

		.downer {
			margin: 5px 0 0 5px;
		}
	}
}

.down-list,
.sub-list {
	position: absolute;
	top: 27px;
	left: 5px;
	z-index: 99;
	background-clip: padding-box;
	background-color: #fff;
	border: solid 1px #91;
	border-radius: 3px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	list-style: none outside none;
	margin: 0;
	min-width: 156px;
	padding: 2px 0;

	> li {
		&:not(.disabled) {
			&:hover {
				> a i,
				> i {
					color: #fff;
				}
			}
		}
	}

	strong {
		font-weight: 600;
	}
}

.down-list {
	.btnSmall + & {
		top: 27px;
	}

	li {
		float: none;
		margin: 0;
		line-height: normal;
		vertical-align: middle;
		border: none;
		font-size: 14px;
		color: #68;
		padding: 0 7px;
		box-sizing: border-box;

		&:not(.section-header) {
			&:not(.section-end) {
				min-height: 27px;
				&:not(.selected) {
					&:hover,
					> a:hover {
						cursor: pointer;
					}
				}
			}
		}

		&.grouped {
			&,
			#addLinks & {
				// text-indent: 14px;
				padding-left: 14px;
			}
		}

		&.section-end {
			border-bottom: solid 1px #dc;
			padding-top: 0;
			padding-bottom: 0;
			margin: 2px 0;
		}

		&.disabled {
			a {
				color: #777;
			}
		}

		a {
			font-size: 14px;
		}

		a,
		span:not(.caret) {
			float: none;
			border: none;
			line-height: 14px;
			margin: 0;
			text-align: left;
			width: 100%;
			text-decoration: none;
			display: inline-block;
			text-indent: 0;
			padding: 6px 0 3px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 200px;
		}

		i {
			font-style: normal;
			color: #777;
			font-size: 12px;
		}

		#addLinks & {
			color: #68;
			white-space: nowrap;
		}

		.disabled-header {
			color: #777;
		}
	}

	.section-header {
		font-size: 12px;
		padding: 3px 7px 2px;
	}

	.hasSublist {
		position: relative;
	}
}

.sub-list {
	display: none;
	left: -158px;
	top: 0;
	margin-top: -3px;

	&.hover {
		display: block;
		cursor: default;
	}

	li {
		&.selected {
			font-weight: bold;
			position: relative;

			&:before {
				content: "";
				display: inline-block;
				width: 12px;
				height: 11px;
				position: absolute;
				top: 7px;
				left: 5px;
				background: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/checkmark.png") no-repeat;
			}

			&:not(.disabled) {
				&:hover {
					&:before {
						background-color: transparent !important;
						background-position: -12px 0;
					}

					a {
						color: white;
					}
				}
			}

			&.disabled {
				&:before {
					background-color: #999 !important;
				}
			}

			> a {
				color: #999;
				&:hover {
					cursor: default;
				}
			}
		}
	}
}

.down-list li.disabled:hover,
.down-list li.disabled:hover > a,
.down-list li.disabled > a:hover,
.sub-list li.section-end,
.sub-list li.section-end > a,
.sub-list li.selected.disabled:hover {
	cursor: default !important;
	background-color: transparent;
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	text-indent: -99999px;
	vertical-align: top;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 4px solid #90;
	content: "\2193";
	margin-top: 12px;
	margin-left: 5px;

	&.caretRight {
		border-left: 4px solid  #90;
		border-right: 0 solid transparent !important;
		border-top: 4px solid transparent !important;
		border-bottom: 4px solid transparent;
		margin-top: 0;
	}

	&.caretLarge {
		border-width: 5px;
		border-top-width: 5px !important;
	}

	.btnAction & {
		border-top-color: #fff;
	}

	#manage-nav & {
		margin-top: 4px;
	}

	.btnSmall [class*="bicon-"] + &,
	.btnSmall & {
		margin-top: 11px;
		margin-left: 3px;
	}

	[class*="bicon-"] + & {
		margin-left: 0;
	}
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

#userGroup {
	&-Multiple {
		> a {
			cursor: pointer;
		}
	}
}

.accessOptions {
	li {
		text-indent: 15px;
	}
}

ul.down-list:not(.top-nav-dd-list) > li:not(.section-header):not(.section-end):not(.disabled):hover > a,
ul.down-list:not(.top-nav-dd-list) > li:not(.section-header):not(.section-end):not(.disabled) > a:hover,
ul.sub-list > li:not(.section-header):not(.section-end):not(.selected):not(.disabled):hover > a,
ul.sub-list > li.stillHover:not(.disabled):hover > a,
ul.sub-list > li:not(.section-header):not(.section-end):not(.selected):not(.disabled) > a:hover
{
	color: #fff !important;
	cursor: pointer;
}
