.Comments {
	background: #f2f2f2;
	border: solid #dfdfdf 1px;
	border-radius: 3px;
	padding: 20px 34px;
	box-sizing: border-box;
	vertical-align: middle;
	max-width: 550px;
	margin: 0 auto;

	&__img {
		width: 32px;
		height: @width;
		display: inline-block;
		margin-bottom: -(@height);
	}

	&__comment-container,
	&__reply-wrapper,
	&__form-container {
		padding-left: 45px;
	}

	&__comment-container,
	&__form {
		padding-bottom: 15px;
	}

	&__msg {
		padding-bottom: 2px;
	}

	&__comment {
		&-wrapper {
			+legacy() {
				border-top: dotted #d4d4d4 1px;

				/.Comments__reply-wrapper &:last-child {
					border-bottom: dotted #d4d4d4 1px;
					margin-bottom: 15px;
				}
			}
		}

		&-container {
		}

		&-header {
			padding-bottom: 4px;
		}

		&-actions {
			visibility: hidden;
			float: right;
			width: 45px;
		}

		&-author {
			font-weight: bold;
			padding-right: 15px;
		}

		&-date {
			color: #afafaf;
		}

		&-container:hover &-actions {
			visibility: visible;
		}
	}

	&__reply {
		&-wrapper {
		}

		&-link {
			display: block;
			padding-bottom: 15px;
			color: #afafaf;
			font-weight: 600;
			font-size: 12px;
		}
	}

	&__form {

		&-container {
			position: relative;
		}
	}

	&__buttons {
		padding: 10px;
		padding-bottom: 0;
	}

	&__input {
		position: relative;
		padding: 6px 30px 6px 10px;
		background: #fff;
		border: solid #afafaf 1px;
		border-radius: 3px;
		line-height: 20px;
		font-size: 15px;
		color: #222;
		box-sizing: border-box;
		margin-left: 10px;
		white-space: pre-wrap;
		cursor: text;
		overflow: visible;
		width: calc(100% - 10px);
		box-shadow: none;
		border-top-left-radius: 0;

		&.placeholder {
			color: #adadad;
		}

		$arrow-size = 13px;

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 0;
			height: @width;
			border-style: solid;
			border-color: transparent;
			transform: rotate(360deg);
		}

		&:before {
			$size = $arrow-size + 2;
			border-width: 0 $size $size 0;
			border-right-color: #afafaf;
			left: -($size);
			top: -1px;
		}

		&:after {
			border-width: 0 $arrow-size $arrow-size 0;
			border-right-color: #fff;
			left: -($arrow-size);
			top: 0;
		}

		&:focus {
			border-color: #787878;

			&:before {
				border-right-color: #787878;
			}
		}
	}

	&__attach {
		display: inline-block;
		position: absolute;
		top: 5px;
		right: 7px;
		width: 23px;
		height: @width;
		opacity: 0.7;
		background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/attach-sprite.png");


		&:hover {
			opacity: 1;
		}
	}

	&__files {

	}

	&__file {
		&-wrapper {
			& {
				position: relative;
				padding-left: 15px;
				padding-top: 5px;

				&:before {
					content: "";
					display: inline-block;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 10px;
					height: 12px;
					background: url("https://staticfe.bamboohr.com/resources/images/comments/file-uploaded.png");
				}
			}
		}

		&-name {
			display: inline-block;
			position: relative;
			font-size: 12px;
			color: #222;
			line-height: @height;
			height: 15px;
			vertical-align: middle;
			box-sizing: border-box;
			padding-right: 20px;
		}

		&-delete {
			position: absolute;
			top: 50%;
			right: 2px;
			transform: translateY(-50%);
		}

		&-progress {
			&-wrapper {
				display: none;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				width: 200px;
				height: 10px;
				border: 1px solid #000;
				border-radius: 4px;
				box-sizing: border-box;
			}

			&-bar {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 0;
			}
		}

		&-wrapper--uploading &-name {
			max-width: calc(100% - 210px);
		}

		&-wrapper--uploading &-progress-wrapper {
			display: inline-block;
		}
	}
}
