.Forms {
	&__confirmationMessage {
		text-align: center;
	}
}

.historyModalHeader {
	display: flex;

	&__avatar {
		// @startCleanup encore
		border-radius: 50%;
		// @endCleanup encore
		height: 42px;
		width: 42px;
		+encore() {
			border-radius: 8px;
		}
	}
	&__info {
		margin-left: 12px;

		&Name {
			fab-font-size: large;
			fab-line-height: small;
			fab-font-weight: semibold;
			margin: 2px 0 0;
		}

		&Title {
			fab-color: gray7;
			fab-font-size: small;
			fab-line-height: teenie;
		}
	}
}

.regrettableInfo {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	gap: 12px;
	line-height: 19px;
	padding: 18px 24px 24px;
	max-width: 274px;
}
