/*------------------------------------------
	This file generates primary and secondary color class names for SVG icons.
	The colors are defined in the svgColorPalette (found in colors.var.styl).

	Usage:
		<svg class="bhr-svgPri--error">...</svg>
		<svg class="bhr-svgSec--gray-1">...</svg>

-------------------------------------------*/

for name, hex in $svgColorPalette {
	.bhr-svgPri--{name} {
		fill: hex;
		color: hex;
	}
}

for name, hex in $svgColorPalette {
	.bhr-svgSec--{name} {
		color: hex;
	}
}

.bhr-SvgSprite {
	height: 0;
	position: absolute;
	width: 0;
}
