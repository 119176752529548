.BaseInputField {

  // .BaseInputField__tooltipIconWrapper
  &__tooltipIconWrapper {
    margin-left: 5px;
    display: flex;
    align-items: center;
    fill: fab-color(gray7);

    &:hover {
      fill: fab-color(theme);
    }
  }
}
