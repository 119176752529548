.InfoChanges {
	font-size: 15px;
	margin-top: 6px;
	{$textWrap};

	+jade() {
		fab-color: gray9;
		fab-font-size: small;
	}

	&--old {
		color: #af;

		+jade() {
			fab-color: gray7;

			.InfoChanges__change {
				text-decoration: line-through;
			}
		}
	}

	&__label {
		display: inline-block;
		margin-bottom: 2px;
		font-size: 13px;
		font-weight: 600;

		+jade() {
			fab-font-size: small;
			fab-line-height: teenie;
		}
	}

	&__change {
		+jade() {
			fab-font-size: small;
			fab-line-height: teenie;
		}
	}

	&__change:not(:last-child) {
		margin-bottom: 13px;

		+jade() {
			margin-bottom: 12px;
		}
	}

}
