/*------------------------------------*\
$LIST MODULE

styles used for list elements
\*------------------------------------*/

.List__actions {
	position: absolute;
	top: 10px;
	right: 12px;
	display: none;
}

.List__category {
	position: relative;
	top: -1px;
	left: -6px;
	float: left;
	display: inline-block;
	padding: 5px 11px 4px 13px;
	font-size: 12px;
	color: #4e4e4e;
	background: #f3f3f3;
	border: solid #cdcdcd 1px;
}

.List__commentIcon {
	position: relative;
	display: inline-block;
}
.List__commentIcon:before {
	content: '';
	position: relative;
	top: -2px;
	display: inline-block;
	margin-left: 7px;
	width: 14px;
	height: 14px;
	vertical-align: middle;
	background: url("https://staticfe.bamboohr.com/resources/images/sprite-icons.png") no-repeat 0 -210px;
	-webkit-transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
	-ms-filter: "FlipH";
	filter: FlipH;
}

.List__fileIcon {
	position: relative;
	display: inline-block;
}
.List__fileIcon:before {
	content: '';
	position: relative;
	top: -2px;
	display: inline-block;
	margin-left: 5px;
	width: 14px;
	height: 14px;
	vertical-align: middle;
	background: url("https://staticfe.bamboohr.com/resources/images/sprite-icons.png") no-repeat 0 -228px;
	-webkit-transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
	-ms-filter: "FlipH";
	filter: FlipH;
}
.List__FileIcon,
.List__FilesEmployee {
	display: inline-block;
	fill: #858585;
	margin: 0 5px;
	position: relative;
}
.List__FileTypeIcon,
.List__FilesEmployeeIcon {
	vertical-align: top;
}
.List__FileIcon + .List__item-note,
.List__FilesEmployee + .List__commentIcon:before {
	margin-left: 0;
}

.List__heading {
	padding: 10px 17px 11px;
	color: #727272;
	background: #d7d7d7;
}

.List__item {
	padding: 10px 17px 14px;
}

.List__item-checkbox {
	display: inline-block;
	transform: translateY(1px);
}

.List__item-checkbox--tooltip {
	margin-left: -3px;
}
#taskList .List__item-checkbox--tooltip:before {
	margin-left: 3px;
}
.List__item:not(.List__item--edit):hover { background: #fafafa; }
.List__item:hover .List__actions {
	display: block;
}

.List__category + .List__item { padding-top: 27px; }

.List__item--divider:not(:last-child) { border-bottom: solid #e1e1e1 1px; }

.List__item-desc {
	margin: 0 0 4px;
	color: #777;
	font-size: 13px;
	font-weight: 400;
}

.List__item-details { width: 90%; }

/* list item files styles */
.List__item-files { margin: 5px 0 0 1px; }
.List__item-title + .List__item-files { margin-top: 2px; }
.List__item-file {
	display: inline-block;
	margin-right: 15px;
	margin-bottom: 1px;
}

.List__item-file-icon {
	margin-right: 5px;
}

.List__item-file-icon--status {
	position: relative;
}

.List__item-file-info {
	position: relative;
	top: -3px;
	color: #989898;
	font-size: 13px;
	vertical-align: bottom;
}

.List__item-file-message--required {
	background-color: #fff9b5;
	border: 1px solid #fcec80;
	border-right: 0;
	color: #222;
	font-size: 12px;
	height: 15px;
	line-height: 15px;
	margin-right: 14px;
	padding: 1px 2px 2px 6px;
	position: relative;
	display: inline-block;
	vertical-align: bottom;
}

.List__item-file-message--required:after {
	border-bottom: 9px solid transparent;
	border-left: 9px solid #fff9b5;
	border-top: 9px solid transparent;
	content: '';
	height: 0;
	right: -8px;
	position: absolute;
	top: 0;
	width: 0;
}

.List__item-file-message--required:before {
	content: '';
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 11px solid #fcec80;
	position: absolute;
	right: -10px;
	top: -1px;
}

.List__item-file-name {
	max-width: 230px;
	height: 14px;
	font-size: 13px;
	line-height: 1em;
	font-weight: 600;
	padding-left: 3px;
}
.List__item-file-name.truncate { display: inline-block; }
.List__item-file-name--noTruncate {
	max-width: none;
}

/* list files stacked */
list__item-files--stacked { margin-bottom: 27px; }
.List__item-files--stacked .List__item-file {
	display: block;
	font-size: 15px;
}
.List__item-files--stacked .List__item-file:not(:last-child) { margin-bottom: 7px; }
.List__item-files--stacked .List__item-file-icon {
	top: 2px;
	margin-right: 6px;
	vertical-align: bottom;
}

.List__item-files--stacked .List__item-file-name {
	top: 0;
	height: 16px;
	font-size: 15px;
	vertical-align: middle;
}
.List__item-files--stacked .List__item-file-info {
	top: -1px;
}

.List__item-files--nhp.List__item-files--stacked .List__item-file {
	display: table;
}
.List__item-files--nhp.List__item-files--stacked .List__item-file-name {
	display: table-cell;
	vertical-align: middle;
}

.List__item-files--nhp.List__item-files--stacked .List__item-file-info {
	left: 3px;
	top: -5px;
}

/* /list item files styles */

.List__item-note {
	font-size: 13px;
	font-weight: 400;
	color: #808080;
}

.List__item-title + .List__item-note {
	display: inline-block;
	margin-left: 6px;
}

.List__item-title {
	margin-left: 7px;
	font-size: 15px;
	font-weight: 600;
}


/*
* List--ordered specific styles
*/

.List--ordered {
	counter-reset: foo;
	margin: 0;
	width: 100%;
	list-style: none;
}

.List--ordered .List__item {
	counter-increment: foo;
	padding: 0;
}

.List__item:hover .List__item-content:after,
.List__item.ui-sortable-helper .List__item-content:after {
	content: "";
	position: absolute;
	top: 16px;
	left: 7px;
	display: block;
	width: 5px;
	height: 7px;
	border-top: solid #b4b4b4 3px;
	border-bottom: solid #b4b4b4 3px;
	box-shadow: inset 0 2px 0 #fff, inset 0 -2px 0 #fff;
	background: #b4b4b4;
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: grab;
}

.List--ordered .List__item--divider-dotted:not(:last-child) {
	border-bottom: dotted #c8c8c8 1px;
}

.List--ordered .List__item-content:before {
	content: counter(foo) ".";
	display: inline-block;
	font-size: 19px;
	padding: 4px 4px 0 19px;
	min-width: 26px; /* here for ui-sortable on list */
}

.List--ordered .List__item-content {
	position: relative;
	float: left;
	display: block;
	padding: 8px 0 9px;
	width: 100%;
}

.List__item-details {
	display: inline-block;
	vertical-align: top;
}

.List--ordered .List__item-title {
	margin-left: 0;
	margin-bottom: 0;
	font-size: 17px;
	font-weight: 600;
	padding-top: 1px;
}

/* edit state styles */
.List__item--edit {
	box-shadow: inset 0 3px 3px -3px rgba(0,0,0,.15);
	background: #e8e8e8;
}
.List__item--edit:not(:first-child) {
	border-top: solid #969696 1px;
}
.List__item--edit:not(:last-child) {
	border-bottom: solid #969696 1px;
}

/* file variant for detailed */
.List__item-files--detailed .List__item-file {
	font-size: 13px;
	line-height: 1em;
	display: table;
	margin-bottom: 8px;
}

/*.List__item-file--detailed:first-child {
	margin-top: 8px;
}

.List__item-file--detailed:last-child {
	margin-bottom: 0;
}*/

.List__item-files--detailed:first-of-type {
	margin-top: 18px;
}

.List__item-files--detailed .List__item-file-name {
	color: #222;
	font-weight: 400; /* Should it be? */
	font-size: 13px;
	display: table-cell;
	max-width: 100%;
}

.List__item-files--detailed .List__item-file-size,
.List__item-files--detailed .List__item-file-remove {
	color: #909090;
	display: table-cell;
	vertical-align: middle;
	padding-left: 3px;
}

.List__item-files--detailed .List__item-file-remove {
	color: #979797;
	font-size: 20px;
	font-weight: 700;
}

.List__item-files--detailed .List__item-file-remove:hover {
	cursor: pointer;
}
