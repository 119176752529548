.StepIndicator {

		&__step {
			display: inline-block;
			position: relative;
			size: 9px;
			margin-left: 12px;
			border-radius: 50%;
			fab-background-color: theme-light;

			& ~ &:before {
				content: "";
				display: block;
				width: 6px;
				height: 1px;
				position: absolute;
				left: -9px;
				top: 50%;
				fab-background-color: gray4;
				transform: translateY(-50%);
			}

			&&--active {
				size: 11px;
				fab-background-color: theme;

				&:before {
					width: 5px;
					left: -8px;
				}
			}

			&&--active ~ & {
				fab-background-color: gray4;
			}
		}

		&__wrapper {
			display: flex;
			align-items: center;
			float: right;
			margin-top: 5px;

			&--hasSeparator {
				padding-right: 24px;
				border-right: 2px solid fab-color(gray3);
			}
		}

		&__message {
			fab-text: teenie;
			fab-font-weight: regular;
			fab-color: gray7;
		}

		&__stepWrapper {
			display: flex;
			align-items: center;
			margin-top: 1px;
		}
}
