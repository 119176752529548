/*
Styles use wherever files are accessible inthe app:
ATS, Home, Employee Tab, Onboarding, Inbox, Settings
 */
.noAccess__body {
	text-align: center;
	padding: 0 10px;
}
.noAccess__body .noAccess__body-filename {
	text-align: center;
	font-weight: 600;
	color: #777;
	font-size: 15px;
}
.noAccess__body .noAccess__body-noAccess {
	font-size: 20px;
	font-weight: 400;
	font-family: "BhrHeaderFont", "Trebuchet MS";
}
.noAccess__body .noAccess__body-questions {
	text-align: center;
	margin-top: 10px;
	color: #777;
	font-size: 14px;
}