.EnableTimeTracking {
	&__forWho {
		text-align: center;
	}

	&__date-select {
		display: flex;
		justify-content: center;
		margin-top: 18px;
	}
}
