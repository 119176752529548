.ScrollLoader {
	&--loaded .ScrollLoader__loader {
		display: none;
	}

	&__loader {
		align-items: center;
		display: flex;
		padding: 10px 0;
		.circleDotLoader {
			height: 32px;
			width: 32px;
		}
	}
}
