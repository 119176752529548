
/*---------------------------------*\
This is the old method of icons

We are keeping it until we can convert
all icons to the new module.
** Any new icons should be added to
** BhrIcons.mod.css
/*---------------------------------*/

/* all styles for .bicons */
[class^="inline-bicon-"], [class*=" inline-bicon-"] {
	display: inline-block;
	border: 1px solid transparent;
	border-radius: 3px;
	padding: 3px 3px;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: transparent;
	vertical-align: top;
}
.btnInlineIcon > [class^="inline-bicon-"] {
	margin-top: 2px;
	border-radius: 0;
}
.hidden {
	visibility: hidden;
}
.hover[class^="inline-bicon-"], .hover[class*=" inline-bicon-"], [class^="inline-bicon-"]:hover, [class*=" inline-bicon-"]:hover {
	border-color: #afafaf;
	cursor: pointer;
}
.ts-list th [class^="inline-bicon-"]:hover, .ts-list th [class*=" inline-bicon-"]:hover {
	background-color: #ffffff;
}
.ts-list td [class^="inline-bicon-"] {
	position: relative;
	top: -1px;
}
.active[class^="inline-bicon-"], .active[class*=" inline-bicon-"], [class^="inline-bicon-"]:active, [class*=" inline-bicon-"]:active {
	border-color: #afafaf;
	background-color: $bhrColors.gray2;
}
.btnInlineIcon > [class^="inline-bicon-"], .btnInlineIcon > [class*=" inline-bicon-"], .btnInlineIcon > [class^="inline-bicon-"]:hover, .btnInlineIcon > [class*=" inline-bicon-"]:hover, .btnInlineIcon > [class^="inline-bicon-"]:active, .btnInlineIcon > [class*=" inline-bicon-"]:active {
	padding: 0;
	border: 0;
	background-color: transparent;
}
.inline-bicon-config-sm {
	width: 25px;
	height: 15px;
	background-position: 2px -501px;
}
.inline-bicon-delete {
	background-position: -2px -2px;
	width: 10px;
	height: 12px;
}
.btnInlineIcon > .inline-bicon-delete {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/delete-icon.png");
	background-position: 0 0;
	width: 9px;
	height: 11px;
}

.bicon-duplicate,
.bicon-preview,
.bicon-preview:hover,
.inline-bicon-alert--light,
.inline-bicon-delete,
.inline-bicon-delete-light,
.inline-bicon-edit,
.inline-bicon-edit-light,
.inline-bicon-move,
.inline-bicon-remove,
.inline-bicon-remove--light,
.inline-bicon-save,
.inline-bicon-preview,
.inline-bicon-employee-info,
.inline-bicon-duplicate {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/inline-icons.png");
}

.bicon-branded-plus,
.bicon-calc,
.bicon-clock-pencil,
.bicon-config-large,
.bicon-download,
.bicon-download:hover,
.bicon-import-link,
.btn .bicon-add-category,
.btn .bicon-add-category:hover,
.btn .bicon-add-category:active,
.bicon-add-clipboard,
.bicon-add-time-off-type,
.btn:hover .bicon-add-category,
.btn:active .bicon-add-category,
.inline-bicon-add-category,
.inline-bicon-config-sm,
.inline-bicon-download,
.inline-bicon-import {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/button-icons.png");
}

.bicon-edit,
.btnInlineIcon .inline-bicon-edit {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/edit-sprite.png");
}

.bicon-x,
.inline-bicon-x {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/remove-file.png");
}

.bicon-attach,
.inline-bicon-attach {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/attach-sprite.png");
}

.bicon-people,
.bicon-people:hover,
.inline-bicon-people {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/include-people-sprite.png");
}

.inline-bicon-alert--light {
	background-position: -2px -266px;
	width: 10px;
	height: 12px;
}

.inline-bicon-alert--light.disabled,
.inline-bicon-alert--light.disabled:hover {
	opacity: 0.5;
	border: none;
}

.inline-bicon-delete-light {
	background-position: -2px -156px;
	width: 10px;
	height: 12px;
}
.inline-bicon-delete-light:hover {
	background-position: -2px -200px;
	border-color: #bababa;
}
.inline-bicon-download {
	width: 15px;
	height: 15px;
	background-position: 2px -481px;
}
.inline-bicon-edit {
	width: 12px;
	height: 12px;
	background-position: -1px -46px;
}
.btnInlineIcon .inline-bicon-edit {
	background-position: -3px 0;
	width: 13px;
	height: 16px;
	margin-top: -2px;
}
.inline-bicon-move {
	width: 12px;
	height: 12px;
	background-position: -2px -112px;
}
.inline-bicon-edit-light {
	width: 12px;
	height: 12px;
	background-position: -1px -134px;
}
.inline-bicon-edit-light:hover {
	background-position: -1px -178px;
	border-color: #bababa;
}
.inline-bicon-remove {
	background-position: -1px -222px;
	width: 12px;
	height: 12px;
}
.inline-bicon-remove--light {
	background-position: -1px -244px;
	width: 12px;
	height: 12px;
}

.inline-bicon-save {
	background-position: -1px -288px;
	width: 12px;
	height: 12px;
}

.inline-bicon-x {
	width: 8px;
	height: 8px;
	background-position: 0 0;
}
.inline-bicon-x2 {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/remove.png");
	width: 10px;
	height: 10px;
	background-position: center center;
	padding: 3px;
}
.inline-bicon-attach {
	width: 14px;
	height: 15px;
	background-position: -2px -1px;
}
.inline-bicon-people {
	width: 17px;
	height: 12px;
	background-position: 0 -3px;
}
.inline-bicon-preview {
	height: 12px;
	width: 12px;
	background-position: -2px -90px;
}
.btnInlineIcon .inline-bicon-preview {
	height: 17px;
	width: 12px;
	margin-top: -2px;
	background-position: -5px -89px;
}
.inline-bicon-add-category {
	width: 19px;
	height: 14px;
	background-position: center 0;
}
.inline-bicon-employee-info {
	height: 12px;
	width: 12px;
	background-position: -2px -68px;
}
.inline-bicon-add-category:hover {
	background-position: center -42px;
}
.inline-bicon-duplicate {
	height: 12px;
	width: 10px;
	background-position: center -24px;
}
.inline-bicon-import {
	width: 15px;
	height: 15px;
	background-position: -2px -378px;
	margin-top: 1px !important;
}
.inline-bicon-google-import,
.bicon-google-import {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/import-google-icon.png");
	width: 22px;
	height: 17px;
	background-position: 0 3px;
}

/** The new redesigned icons css goes here too **/
[class^="bicon-"], [class*=" bicon-"] {
	width: 19px;
	height: 19px;
	display: inline-block;
	padding: 0;
	background-position: left top;
	background-repeat: no-repeat;
	vertical-align: top;
}
[class^="bicon-"]:hover, [class*=" bicon-"]:hover {
	background-position: bottom;
}
a[class^="bicon-"]:hover, a[class*=" bicon-"]:hover {
	cursor: pointer;
}
.dropdown-menu .btnSmall [class^="bicon-"] {
	margin-top: 6px;
}
.dropdown-menu .btnSmall.btnIcon [class^="bicon-"] {
	margin-top: 4px;
}
.dropdown-menu [class^="bicon-"] {
	margin-top: 7px;
}
.bicon-move {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/move.png");
	background-position: bottom;
}
.bicon-arrow-up-line {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/arrow-up-line.png");
	width: 7px;
	height: 11px;
	margin-right: 4px !important;
}
.btnSmall .bicon-arrow-up-line {
	margin-top: 7px;
}
.bicon-calc {
	background-position: 0 -526px;
	width: 14px;
	height: 13px;
}
.btn:hover > .bicon-calc {
	background-position: 0 -547px;
}
.btnSmall > .bicon-calc {
	margin-top: 6px;
	margin-right: 5px;
}
.bicon-clock-pencil {
	background-position: 0 -567px;
	width: 22px;
	height: 14px;
}
.btn:hover > .bicon-clock-pencil {
	background-position: 0 -588px;
}
.btnSmall > .bicon-clock-pencil {
	margin-top: 5px;
	margin-right: 4px;
}
.bicon-config {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/gear-small.png");
	width: 12px;
	height: 12px;
}
.bicon-delete {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/delete-sprite.png");
}
.bicon-preview, .bicon-preview:hover {
	height: 15px;
	width: 16px;
	background-position: -2px -90px;
}
.bicon-x {
	width: 14px;
	height: 14px;
}
.bicon-attach {
	width: 23px;
	height: 23px;
}
.bicon-people {
	width: 23px;
	height: 23px;
}
.bicon-reorder-categories {
	width: 14px;
	height: 17px;
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/reorder-categories-icon.png");
}
.btn.btnInlineIcon.configCategories {
	float: right;
	height: 23px;
}
.configCategories.btn i {
	margin-top: 0;
	margin-left: 0;
}
.bicon-filter, .bicon-filter:hover {
	cursor: default;
	width: 18px;
	height: 14px;
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/employee-filter-icon.png");
	background-position: top center;
	vertical-align: baseline;
	margin-right: 2px;
}
.bicon-people.nohover:hover, .btn .bicon-people:hover {
	background-position: top center;
	cursor: default;
}
.bicon-plus {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/plus.png");
	width: 9px;
	height: 9px;
}
.bicon-white-plus {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/add-button-icon.png");
	width: 8px;
	height: 8px;
}
.btn .bicon-white-plus {
	margin-top: 9px;
	margin-right: 4px;
}
.btn.btnSmall .bicon-white-plus {
	margin-top: 8px;
}
.bicon-white-plus-large {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/bicon-white-plus-lrg.png");
	width: 19px;
	height: 19px;
}
.btn.btnLarge .bicon-white-plus-large {
	margin-top: 11px;
}
.bicon-gray-plus {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/add-button-gray-icon.png");
	width: 8px;
	height: 8px;
}
.btn .bicon-gray-plus {
	margin-top: 9px;
	margin-right: 4px;
}
.btn.btnSmall .bicon-gray-plus {
	margin-top: 8px;
}
.bicon-chat {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/chat-live-icon.png");
	width: 15px;
	height: 15px;
}
.bicon-copy-link {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/copy-link-icon.png");
	width: 16px;
	height: 20px;
}
.bicon-lock {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/lock.png");
	width: 12px;
	height: 16px;
}
.bicon-duplicate {
	width: 20px;
	height: 20px;
	background-position: left -22px;
}
.bicon-duplicate:hover {
	background-position: left -22px;
}
.bicon-import-link {
	width: 25px;
	height: 15px;
	background-position: left -381px;
}
.bicon-import-link:hover {
	background-position: left -381px;
}
.btn .bicon-add-category, .btn .bicon-add-category:hover, .btn .bicon-add-category:active, .btn:hover .bicon-add-category, .btn:active .bicon-add-category {
	background-position: left -443px !important;
	height: 17px;
	width: 25px;
}
.bicon-branded-plus {
	background-position: -4px -609px;
	height: 10px;
	width: 14px;
	margin-top: 7px !important;
}
.btn .bicon-branded-plus {
	margin-top: 6px;
}
.btn.btnSmall .bicon-branded-plus {
	margin-top: 5px;
}
.bicon-branded-plus:hover, .btn:hover .bicon-branded-plus, .btn:hover .bicon-branded-plus:hover {
	background-position: -4px -630px !important;
}
.btn[disabled] > i.bicon-branded-plus.branded-icon {
background-color: #ccc;
}
.bicon-download, .bicon-download:hover {
	width: 21px;
	height: 17px;
	background-position: 2px -464px !important;
}
.bicon-add-clipboard {
	width: 17px;
	height: 17px;
	background-position: -3px -65px;
}
.bicon-add-clipboard:hover, .btn:hover .bicon-add-clipboard, .btn:hover .bicon-add-clipboard:hover {
	background-position: -3px -84px;
}
.bicon-add-clipboard:active, .btn:active .bicon-add-clipboard, .btn:active .bicon-add-clipboard:active {
	background-position: -3px -107px;
}
.bicon-checkmark {
	background: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/required-check.png");
	width: 16px;
	height: 14px;
}
.bicon-unchecked {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/unchecked-icon.png");
	width: 12px;
	height: 12px;
	background-position: 0 0;
}
.bicon-checked {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/checked-icon.png");
	width: 12px;
	height: 12px;
	background-position: 0 0;
}
.bicon-reset {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/reset-icon.png");
	width: 15px;
	height: 15px;
	background-position: 0 0;
	position: relative;
	top: -2px;
}
.bicon-alarm-clock {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/alarm-clock-icon.png");
	width: 13px;
	height: 12px;
	background-position: 0 0;
	margin-top: 1px;
}
.bicon-add-time-off-type {
	width: 17px;
	height: 17px;
	background-position: -3px -317px;
}
.bicon-add-time-off-type:hover, .btn:hover .bicon-add-time-off-type {
	background-position: -3px -338px;
}
.bicon-add-time-off-type:active, .btn:active .bicon-add-time-off-type {
	background-position: -3px -359px;
}
.bicon-config-large {
	width: 20px;
	height: 20px;
	background-position: left -399px;
}
.bicon-config-large:hover, .btn:hover .bicon-config-large, .btn:hover .bicon-config-large:hover {
	background-position: left -399px !important;
}
.bicon-time-off-type {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/time-off-type-icon.png");
	width: 13px;
	height: 13px;
}
.bicon-file-upload {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/file-upload-sprite.png");
	width: 15px;
	height: 18px;
	cursor: default;
}
.bicon-file-upload:hover {
	background-position: top;
}
.bicon-remove-accrual-level {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/remove-accrual-level.png");
	width: 14px;
	height: 14px;
}
.bicon-remove {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/remove-icon.png");
	width: 12px;
	height: 12px;
}
.bicon-13-remove {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/remove-13-icon.png");
	width: 13px;
	height: 13px;
}
.bicon-calendar {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/holiday-cal.png");
	height: 15px;
	width: 15px;
}
.bicon-people, .bicon-people:hover {
	width: 15px;
	height: 15px;
	background-position: center -5px;
}
.bicon-privacy, .bicon-privacy:hover {
	width: 8px;
	height: 11px;
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/privacy-icon.png");
	background-position: top 0 center;
}
.btnCalendar .bicon-calendar {
	margin-left: 3px;
	margin-top: 5px;
	margin-right: 6px;
	cursor: default;
}
.bicon-arrow-left {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/holiday-arrows.png") top;
}
.bicon-arrow-right, .bicon-arrow-right:hover {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/holiday-arrows.png") bottom;
}
.btn i.bicon-arrow-left, .btn i.bicon-arrow-right {
	margin: 2px 7px 0 8px;
	width: 9px;
	height: 21px;
}
.bicon-exclaim {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/exclaim-icon.png") left top;
	width: 15px;
	height: 15px;
}
.bicon-warning {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/warning-icon.png") left top;
	width: 13px;
	height: 12px;
}
.bicon-warning-small {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/warning-icon-small.png") left top;
	width: 10px;
	height: 9px;
}
.bicon-warning-large {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/warning-icon-lrg.png") left top;
	width: 28px;
	height: 26px;
}
.bicon-info {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/info-blue-icon.png") left top;
	width: 13px;
	height: 13px;
}
.bicon-question {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/question-icon.png") left top;
	width: 13px;
	height: 13px;
}
.bicon-arrow-show-hide {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/arrow-show-hide.png");
	background-position: left top;
	width: 8px;
	height: 7px;
}
.bicon-arrow-show-hide:hover {
	background-position: left top;
}
.bicon-arrow-show-hide.hide {
	background-position: left bottom;
	height: 5px;
	margin-top: 1px;
}
[class^="bicon-settings-"] {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/button-icons.png");
	height: 19px;
	width: 25px;
	background-color: #b5b5b5;
}
.bicon-category {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/category-icon.png");
	width: 14px;
	height: 17px;
	margin-right: 7px;
}
.btn .bicon-list {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/list.png") no-repeat;
	width: 13px;
	height: 11px;
	margin: 7px 7px 5px 8px;
}
.btn .bicon-list-branded {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/list-branded.png") no-repeat;
	width: 13px;
	height: 11px;
	margin: 7px 7px 5px 8px;
}
.btn .bicon-orgchart {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/org-chart.png") no-repeat;
	width: 17px;
	height: 15px;
	margin: 5px 5px 5px 7px;
}
.btn .bicon-orgchart-branded {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/org-chart-branded.png") no-repeat;
	width: 14px;
	height: 15px;
	margin: 5px 7px 5px 7px;
}
.btn .bicon-plus-gray {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/plus-gray.png") no-repeat;
	width: 12px;
	height: 10px;
	margin: 7px 7px 5px 8px;
}
.btn .bicon-minus-gray {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/minus-gray.png") no-repeat;
	width: 12px;
	height: 10px;
	margin: 7px 7px 5px 8px;
}
.btn .bicon-print {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/print.png") no-repeat;
	width: 17px;
	height: 14px;
	margin: 5px 6px 5px 6px;
}
.btn .bicon-share {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/share.png") no-repeat;
	width: 17px;
	height: 14px;
	margin: 5px 6px 5px 6px;
}
.btn .bicon-startattop {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/start-at-top.png") no-repeat;
	width: 17px;
	height: 15px;
	margin: 3px 5px 5px 7px;
}
.bicon-check-circle {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/check-icon.png") no-repeat;
	width: 14px;
	height: 14px;
}
.btn .bicon-directory {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/directory.png") no-repeat;
	width: 17px;
	height: 15px;
	margin: 5px 6px;
}
.btn .bicon-directory-branded {
	background: url("https://staticfe.bamboohr.com/resources/images/bicons/directory-branded.png") no-repeat;
	width: 17px;
	height: 15px;
	margin: 5px 6px;
}
.bicon-headshot-sml {
	width: 12px;
	height: 15px;
	background: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/headshot-sml.png") left top no-repeat;
	background-color: #bebebe;
}
.bicon-headshot-sml.branded-icon, .bicon-headshot-sml:hover {
	background-position: left top;
}
.bicon-permissions-lock {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/permissions-lock.png");
	width: 20px;
	height: 20px;
	background-position: 0 0;
}
.bicon-permissions-lock-white {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/permissions-lock-white.png");
	width: 16px;
	height: 16px;
	background-position: 0 0;
}
.bicon-terminate {
	background-image: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/terminate.png");
	width: 27px;
	height: 21px;
	background-position: 0 bottom;
}
.bicon-headshot-sml-grey {
	width: 12px;
	height: 15px;
	background: url("https://staticfe.bamboohr.com/resources/images/color-branded-icons/headshot-sml.png") left top no-repeat;
	background-position: 0 -30px;
}
.btn .bicon-plus {
	margin-top: 6px;
	margin-right: 3px;
}
.bicon-holiday {
	background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/gift-box.png");
	width: 16px;
	height: 14px;
}
.btn:hover [class^="bicon-"]:hover, .btn:hover [class^="bicon-"], .btn [class^="bicon-"]:hover {
	background-position: left top;
}
.btn .bicon-arrow-right:hover, .btn:hover .bicon-arrow-right, .btn:hover .bicon-arrow-right:hover {
	background-position: bottom;
}
.btn .bicon-delete {
	margin-top: 2px;
}
.btnIcon, .btnSmall.btnIcon, .btnGroup .btnIcon, .btn.btnIcon {
	min-width: 0;
	padding: 0;
}
.dropdown-menu [class^="bicon-settings-"] {
	margin-top: 0;
}
.bicon-settings-add-lock-menu, .bicon-settings-add-lock-menu:hover, .bicon-settings-add-lock-menu:active {
	background-position: left -2px;
}
.bicon-settings-add-lock, .bicon-settings-add-lock:hover, .bicon-settings-add-lock:active {
	background-position: left -191px;
}
.bicon-settings-configure-lock, .bicon-settings-configure-lock:hover, .bicon-settings-configure-lock:active {
	background-position: left -254px;
}
.bicon-settings-add-person, .bicon-settings-add-person:hover, .bicon-settings-add-person:active {
	background-position: left -128px;
	height: 17px;
}
.bicon-settings-gear-menu, .bicon-settings-gear-menu:hover, .bicon-settings-gear-menu:active, .btn:active .bicon-settings-gear-menu, .btn .bicon-settings-gear-menu:active, .btn:active .bicon-settings-gear-menu:active, .btn.active .bicon-settings-gear-menu, .btn:hover .bicon-settings-gear-menu, .btn:hover .bicon-settings-gear-menu:hover {
	background-position: left -422px;
	background-color: transparent;
}
.bicon-settings-gear-menu-white,
.bicon-settings-gear-menu-always-white,
.btn:hover .bicon-settings-gear-menu-always-white,
.btn:hover .bicon-settings-gear-menu-always-white:hover {
	background-position: left -653px;
	background-color: transparent;
}
.btn .bicon-settings-gear-menu-white,
.btn .bicon-settings-gear-menu-always-white {
	margin: 0 3px;
}
.bicon-settings-add-custom-approval {
	background-position: 0 -672px;
}
.btnSmall .bicon-settings-add-custom-approval {
	margin-right: 3px;
}
.btn:hover .bicon-settings-add-custom-approval, .btn:hover .bicon-settings-add-custom-approval:hover {
	background-position: 0 -672px;
}
.btn:active .bicon-settings-add-custom-approval, .btn .bicon-settings-add-custom-approval:active, .btn:active .bicon-settings-add-custom-approval:active, .btn.active .bicon-settings-add-custom-approval, .btn.active .bicon-settings-add-custom-approval:hover {
	background-position: 0 -693px;
}
.bicon-settings-bulk-edit {
	background-position: 0 -715px;
}
.btnSmall .bicon-settings-bulk-edit {
	border-radius: 3px;
}
.btn:hover .bicon-settings-bulk-edit,
.btn .bicon-settings-bulk-edit:hover,
.btn:hover .bicon-settings-bulk-edit:hover
.btn:active .bicon-settings-bulk-edit,
.btn .bicon-settings-bulk-edit:active,
.btn:active .bicon-settings-bulk-edit:active,
.btn.active .bicon-settings-bulk-edit,
.btn.active .bicon-settings-bulk-edit:hover {
	background-position: 0 -715px;
}
.btn .bicon-settings-gear-menu-white:hover, .btn.active .bicon-settings-gear-menu-white, .btn:hover .bicon-settings-gear-menu-white, .btn:hover .bicon-settings-gear-menu-white:hover {
	background-position: left -504px;
}
.btn:hover .bicon-settings-add-lock-menu, .btn:hover .bicon-settings-add-lock-menu:hover {
	background-position: left -23px;
}
.btn:active .bicon-settings-add-lock-menu, .btn .bicon-settings-add-lock-menu:active, .btn:active .bicon-settings-add-lock-menu:active, .btn.active .bicon-settings-add-lock-menu, .btn.active .bicon-settings-add-lock-menu:hover {
	background-position: left -44px;
}
.btn:hover .bicon-settings-add-lock, .btn:hover .bicon-settings-add-lock:hover {
	background-position: left -212px;
}
.btn:active .bicon-settings-add-lock, .btn .bicon-settings-add-lock:active, .btn:active .bicon-settings-add-lock:active {
	background-position: left -233px;
}
.btn:hover .bicon-settings-configure-lock, .btn:hover .bicon-settings-configure-lock:hover {
	background-position: left -275px;
}
.btn:active .bicon-settings-configure-lock, .btn .bicon-settings-configure-lock:active, .btn:active .bicon-settings-configure-lock:active {
	background-position: left -296px;
}
.btn:hover .bicon-settings-add-person, .btn:hover .bicon-settings-add-person:hover {
	background-position: left -149px;
}
.btn:active .bicon-settings-add-person, .btn .bicon-settings-add-person:active, .btn:active .bicon-settings-add-person:active, .btn.active .bicon-settings-add-person {
	background-position: left -170px;
}

/* btnGroup small */
.btnGroup.small .btn, .btn.small {
	height: 26px;
}
.btnGroup.small .bicon-list {
	margin-top: 5px;
}
.btnGroup.small .bicon-list-branded {
	margin-top: 5px;
}
.btnGroup.small .bicon-directory {
	margin-top: 3px;
}
.btnGroup.small .bicon-directory-branded {
	margin-top: 3px;
}
.btnGroup.small .bicon-orgchart {
	margin-top: 3px;
}
.btnGroup.small .bicon-orgchart-branded {
	margin-top: 3px;
}
.btnSmall .bicon-startattop {
	margin-top: 4px;
}
.btn.small .bicon-print {
	margin-top: 5px;
}

/* btnGroup lrg, buttons lrg */
.btnGroup.lrg .btn, .btn.lrg {
	padding: 5px 7px 0 8px;
	height: 26px;
}
.btnGroup.lrg .btn > i {
	margin-top: 2px;
}
.btn.lrg {
	padding-top: 2px;
}

/* Pure CSS Icons */
.css_checkMark {
	display: inline-block;
	width: 21px;
	height: 21px;
	-webkit-transform: rotate(40deg);
	-moz-transform: rotate(40deg);
	-ms-transform: rotate(40deg);
	-o-transform: rotate(40deg);
	transform: rotate(40deg);
}
.css_checkMark:before {
	content: "";
	position: absolute;
	width: 4px;
	height: 16px;
	background-color: #cccccc;
	left: 11px;
	top: 1px;
}
.css_checkMark:after {
	content: "";
	position: absolute;
	width: 7px;
	height: 4px;
	background-color: #cccccc;
	left: 5px;
	top: 13px;
}

// Single Person avatar
.person_avatar {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;

	&:before {
		content: "";
		position: absolute;
		width: 8px;
		height: 9px;
		display: block;
		left: 6px;
		top: 6px;
		border-radius: 50%;
	}

	&:after {
		content: "";
		position: absolute;
		width: 14px;
		height: 5px;
		left: 3px;
		display: block;
		bottom: -1px;
		background-color: black;
		border-radius: 90px 90px 0 0;
	}

	&_small {
		position: relative;
		display: inline-block;
		width: 14px;
		height: 12px;

		&:before,
		&:after {
			background-color: #0;
			content: "";
			position: absolute;
			display: block;
		}

		&:before {
			border-radius: 50%;
			width: 6px;
			height: 6px;
			left: 2px;
			top: 0;
		}

		&:after {
			width: 10px;
			height: 5px;
			left: 0;
			bottom: 0;
			border-radius: 90px 90px 0 0;
		}
	}
}

.people_avatar_small span:first-child:before,
.people_avatar_small span:first-child:after,
.people_avatar_small span:last-child:before,
.people_avatar_small span:last-child:after {
	background-color: black;
	content: "";
	position: absolute;
	display: block;
}

.people_avatar_small span:first-child:before,
.people_avatar_small span:last-child:before {
	border-radius: 50%;
}

.people_avatar_small {
	position: relative;
	display: inline-block;
	width: 14px;
	height: 16px;
}
.people_avatar_small span:first-child:before {
	width: 4px;
	height: 4px;
	left: 2px;
	top: 4px;
}
.people_avatar_small span:first-child:after {
	width: 6px;
	height: 3px;
	left: 1px;
	top: 9px;
	border-radius: 70px 70px 0 0;
}
.people_avatar_small span:last-child:before {
	width: 4px;
	height: 4px;
	left: 8px;
	top: 8px;
}
.people_avatar_small span:last-child:after {
	width: 6px;
	height: 3px;
	left: 7px;
	top: 13px;
	-webkit-border-radius: 90px 90px 0 0;
	border-radius: 90px 90px 0 0;
}

/* Caution Cone with Company Color */
.cautionCone {
	position: relative;
	width: 50px;
	height: 50px;
}
.cautionCone__bottom {
	height: 7px;
	width: 100%;
	-webkit-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
	margin: 0 auto;
	bottom: 0;
}
.cautionCone__bottom, .cautionCone__top, .cautionCone__bottom:before, .cautionCone__bottom:after {
	position: absolute;
}
.cautionCone__top, .cautionCone__bottom:before, .cautionCone__bottom:after {
	left: 50%;
	border-bottom-style: solid;
	border-bottom-width: 10px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
}
.cautionCone__bottom:before, .cautionCone__bottom:after {
	content: "";
	display: block;
	height: 0;
}
.cautionCone__bottom:after {
	width: 16px;
	bottom: 25px;
	margin-left: -12px;
}
.cautionCone__bottom:before {
	width: 26px;
	bottom: 11px;
	margin-left: -17px;
}
.cautionCone__top {
	width: 6px;
	top: 1px;
	margin-left: -7px;
}

/* Curved Arrow */
.CurvedArrow {
	display: inline-block;
	position: relative;
	width: 24px;
	height: 24px;
	top: 6px;
	margin-right: 5px;
	-moz-transform: rotate(75deg);
	-webkit-transform: rotate(75deg);
	-o-transform: rotate(75deg);
	-ms-transform: rotate(75deg);
	transform: rotate(75deg);
}
.CurvedArrow:before {
	content: "";
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	bottom: 0;
	right: 0;
	border-top: 13px solid transparent;
	border-right-width: 13px;
	border-right-style: solid;
}
.CurvedArrow:after {
	content: "";
	position: absolute;
	border-top-width: 6px;
	border-top-style: solid;
	border-radius: 0 20px 0 0;
	top: 0;
	left: 8px;
	width: 16px;
	height: 12px;
	-moz-transform: rotate(220deg);
	-webkit-transform: rotate(220deg);
	-o-transform: rotate(220deg);
	-ms-transform: rotate(220deg);
	transform: rotate(220deg);
}
.CurvedArrow.up {
	top: 5px;
	-moz-transform: rotate(200deg);
	-webkit-transform: rotate(200deg);
	-o-transform: rotate(200deg);
	-ms-transform: rotate(200deg);
	transform: rotate(200deg);
}
.CurvedArrow.up:before {
	border-top: 14px solid transparent;
	border-right-width: 14px;
}
.CurvedArrow.up:after {
	border-top: none;
	border-left-width: 6px;
	border-left-style: solid;
	border-radius: 0 0 0 20px;
	top: 8px;
	left: 0;
	width: 12px;
	height: 16px;
	-moz-transform: rotate(140deg);
	-webkit-transform: rotate(140deg);
	-o-transform: rotate(140deg);
	-ms-transform: rotate(130deg);
	transform: rotate(140deg);
}

/* Warning */
.warning {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
}
.warning:before {
	position: absolute;
	display: block;
	text-align: center;
	vertical-align: text-bottom;
	width: 18px;
	height: 18px;
	content: "!";
	top: 6px;
	color: #fff;
	z-index: 2;
	font-size: 15px;
	font-weight: 600;
	font-style: normal;
	line-height: 1;
	-moz-osx-font-smoothing: grayscale;
}
.warning:after {
	content: "";
	position: absolute;
	width: 18px;
	height: 18px;
	display: block;
	bottom: -3px;
	background-color: black;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.icon-arrow-back {
	display: inline-block;
	width: 11px;
	height: 11px;
	background: url("https://staticfe.bamboohr.com/resources/images/sprite-icons.png") no-repeat -22px -209px;
}
