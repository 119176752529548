.AccessLevels {
	&SettingsModal {
		min-width: 524px;

		&__subHeader {
		}

		&__levelIco {
			size: 20px;
			color: #90;
		}

		&__levelName {
			font-family: BhrHeaderFontStack;
			font-size: 19px;
			color: #222;
		}

		&__body {
			padding: 22px;
		}

		&__msg {
			max-width: 440px;
			margin: 0;
			font-size: 14px;
			line-height: 1.3em;

			&&--note {
				font-size: 13px;
				color: #64;
				padding-top: 8px;
			}

			&&--warning {
			}

			&Wrapper {
				display: flex;
				padding: 0 22px 22px 0;
				box-sizing: border-box;
				max-width: 100%;
				border-bottom: 1px solid #c;
				margin-bottom: 22px;

				&&--warning {
				}
			}

			&Ico {
				size: 32px;
				margin-right: 12px;

				&&--warning {
					fill: #bd5800;
				}
			}
		}

		&__employeeDropdown {
		}

		&__advanced {
			&-toggle {
				position: relative;
				padding-left: 14px;

				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					border: 5px solid transparent;
					border-left-color: #006ec2;
					border-left-width: 6px;
					border-right-width: 0;
					transition: transform 0.3s;
					margin-top: -5px;
				}
			}

			&-container {
				display: none;

				&:not(:first-of-type) {
					padding-top: 8px;
				}
			}

			&-wrapper {
				padding-top: 8px;

				&[data-can-combine="false"] {
					display: none !important;
				}

				&--open {
					// & .AccessLevelsSettingsModal__advanced
					& ^[0..-3] {
						&-container {
							display: block;
						}

						&-toggle {
							&:before {
								transform: rotateZ(90deg);
							}
						}
					}
				}
			}

			&-options {
				strong {
					font-weight: 600;
				}
			}
		}

		.modalSubHeader {
			padding: 14px 20px 16px;
		}
	}
}